import { MCommonListStrategy } from '@/_api/_requests/MListServiceApi/strategies/MCommonListStrategy'
import { GRPCBuilderList } from '@/_api/_classes/GRPC/Receiver/GRPCBuilderList'
import { MListServiceApi } from '@/_api/_requests/MListServiceApi/MListServiceApi'
import { IMListServiceApiConfig } from '@/_declarations/IMListServiceConfigApi'
import { IgRPCReceiverConfig } from '@/_api/_classes/GRPC/interfaces/IgRPCReceiverConfig'

export class MgRPCStrategy<DataItem, FiltersMap>
  extends MCommonListStrategy<DataItem, FiltersMap> {
  private gRPC: GRPCBuilderList<DataItem, FiltersMap>

  constructor (
    context: MListServiceApi<DataItem, FiltersMap>,
    listServiceConfig: IMListServiceApiConfig<DataItem, FiltersMap>,
    gRPCStrategyConfig?: IgRPCReceiverConfig
  ) {
    super(context, listServiceConfig)
    this.gRPC = new GRPCBuilderList(this, {
      src: listServiceConfig.src,
      adapter: listServiceConfig.adapter,
      ...gRPCStrategyConfig,
    })
  }

  fetchAll (payload: Record<string, any>): Promise<void> {
    return this.gRPC.send(payload)
  }
}
