import { defineDateFormat, fetchExportFile } from './export.js'
import { ReportParamFactory } from './shared'
import ReportFiltersFactory from '@/specific/reports/ReportFiltersFactory'
import { createVueApp } from '@/vue_components/create_vue_app'
import OrdersReportApp from '@/vue_components/reports/OrdersReport/OrdersReportApp.vue'

$document.on('rez/reports/orders', function () {
  Report.init()

  const reportTable = createVueApp({
    el: '#report_right_container',
    name: 'OrdersReportAppRoot',
    render: (h) => h(OrdersReportApp),
    useChildren: true,
  })

  const headerRow = {
    orderId: '№',
    customer: t('customer'),
    clientFullName: t('client'),
    orderDate: t('common.date'),
    orderPaidStatus: t('status'),
    orderFinalSumOnlyWithNds: t('finalSumOnlyWithNds'),
    orderNdsSum: t('order_nds_sum'),
    orderFinalSum: t('to_pay'),
    orderPaymentsTotalsPaidSum: t('paided'),

    orderAmount: t('amount'), // todo: удОли меня
  }

  $('#report_submit').on('click', function () {
    reportTable.show()
    reportTable.updateFilters(getFilters())
  })

  $('#report_reset').on('click', () => {
    reportTable.hide()
  })

  $('.export').on('click', function () {
    const filters = getFilters()

    const data = {
      dateFormat: defineDateFormat(),
      csvComma: gon.application.csv_character,
      floatingComma: gon.localization.currency.decimal_character,
      headerRow,
      totalRow: {
        total: t('total'),
      },
      orderPaidStatusLocale: {
        fullyPaid: t('_order_statuses.fully_paid'),
        paidByCredit: t('_order_statuses.paid_by_credit'),
        notPaid: t('_order_statuses.not_paid'),
        unknownPaidStatus: t('_order_statuses.unknown_paid_status'),
        free: t('_order_statuses.free'),
      },
      offset: 0,
      limit: -(1 << 32 - 1),
      report: {
        period: filters.period,
      },
      ...ReportFiltersFactory.defineFor('orders', filters),
    }

    fetchExportFile(data, Routes.report_orders_path(), t('orders'))
  })
})

export const getFilters = () => {
  return {
    period: ReportParamFactory.getRawParam('.period'),
    clinic: ReportParamFactory.getSelectParam('#report_clinic_id'),
    orderPaidStatus: ReportParamFactory.getSelectParam('#report_paid_status'),
  }
}
