import { TEntryRawLikeNestedFieldSet } from '@/specific/orders_helpers/NDS/orderShowNDS/declarations'
import { getNDSForRowWithMemberCheck } from '@/forms/components/order/NDS/shared'

export const prepareMembers =
  (members: TEntryRawLikeNestedFieldSet[] = []) => {
    ;(members || []).forEach((member) => {
      member.get = (key) => member[key]
      member.isMember = () => true

      const { schemeTitle, schemeValueText } = getNDSForRowWithMemberCheck(member)

      member._ndsTitle = schemeTitle
      member._ndsValueText = schemeValueText
    })
  }
