import { MReportPresenterBase } from '@/_api/Reports/_base/MReportPresenterBase'
import { acquiringReportAdapter } from '@/vue_apps/ReportsModule/AcquiringReport/api/acquiringReportAdapter'
import { snakeToCamel } from '@/_api/_requests/helpers'

export class AcquiringReportPresenter extends MReportPresenterBase {
  constructor () {
    super(Routes.report_trade_acquiring_path)
  }

  list (data, config = {}) {
    return super.list(data, {
      toServerAdapter: acquiringReportAdapter.toServer,
      toClientAdapter: (data) => acquiringReportAdapter.toClient(snakeToCamel(data)),
      ...config,
    })
  }

  export (data: {} = {}, fileName = t('finance.acquiringOperations')) {
    super.export(data, fileName)
  }
}
