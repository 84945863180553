import { MODES } from '@/vue_apps/catalogs_root/_catalog_base/const/const'

export const analysisLaboratoriesTableSchema = {
  options: {},
  headers: {
    icon: '',
    title: t('title'),
  },
}

export const MODES_TITLES = {
  [MODES.EDIT]: t('editing_analysis_laboratory'),
  [MODES.CREATE]: t('creating_analysis_laboratory'),
}

export const getDefaultAuthLaboratory = (clinicId) => {
  return {
    id: null,
    clinicId,
    clinicIdentifier: null,
    username: null,
    password: null,
    clientId: null,
    clientSecret: null,
    apiToken: null,
    labellerId: null,
    labellerPassword: null,
    labellerIntegration: false,
    requestCostPrice: false,
    minLabelNumber: null,
    maxLabelNumber: null,
    workplaceId: null,
  }
}
