import { request } from '@/lib/transport/request'
import { analysisLaboratoriesGetAllAdapter, analysisLaboratoryAdapter } from './analysis_laboratories_adapter'
import { HTTP_METHODS } from '@/lib/transport/MRequest'
import { reportErrorText } from '@/_api/_requests/helpers'

export const analysisLaboratoriesEndpoint = {
  getAll (sortParams) {
    const options = {
      type: 'POST',
      url: Routes.catalogs_analysis_laboratories_list_path(),
      data: {
        sortParams,
      },
    }

    return request(options, analysisLaboratoriesGetAllAdapter.toClient).promise
  },

  fetchOne (id) {
    const options = {
      type: 'GET',
      url: Routes.api_internal_analysis_laboratory_path(id),
    }

    return request(options, analysisLaboratoryAdapter.toClient).promise
      .catch((error) => {
        Utils.reportError(
          'AnalysisLaboratories:fetchOne()',
          reportErrorText('fetch_message', 'analysis_laboratory')
        )(error)
      })
  },

  get (id) {
    const options = {
      type: 'GET',
      url: Routes.edit_analysis_laboratory_path(id),
    }

    return request(options, analysisLaboratoriesGetAllAdapter.toClient)
  },

  submit (data) {
    return data.id
      ? analysisLaboratoriesEndpoint.update(data)
      : analysisLaboratoriesEndpoint.create(data)
  },

  update (data) {
    const options = {
      type: 'PATCH',
      url: Routes.api_internal_analysis_laboratory_path(data.id),
      data: JSON.stringify(analysisLaboratoriesGetAllAdapter.toServer(data)),
      dataType: 'json',
      contentType: 'application/json',
    }

    return request(options).promise
  },

  create (data) {
    const options = {
      type: 'POST',
      url: Routes.api_internal_analysis_laboratories_path(),
      data: analysisLaboratoriesGetAllAdapter.toServer(data),
    }

    return request(options).promise
  },

  destroy (id) {
    const options = {
      type: 'DELETE',
      url: Routes.api_internal_analysis_laboratory_path(id),
    }

    return request(options).promise
  },

  fetchDefaultBarcode (id) {
    const options = {
      type: HTTP_METHODS.GET,
      url: Routes.default_barcode_template_analysis_laboratory_path(id),
    }

    return request(options).promise
  },
}
