<template>
  <report-base
    class="report-acquiring"
    :report="report"
    :schema="schema"
  >
    <!-- Клиники -->
    <clinic-filter
      :value="report.filtersData.clinic"
      :m-fixed-height="false"
      full-width
      @change="report.setFilterValue('clinic', $event)"
    />

    <!-- Рабочее место -->
    <m-select-lazy
      :value="report.filtersData.workplace"
      :label="t('workplace')"
      :fetch-method="(params) => filtersPresenter.fetchWorkplaces(params)"
      :placeholder="t$('allWorkplaces')"
      :m-fixed-height="false"
      full-width
      use-first-time-fetch
      @changeByLazy="report.setFilterValue('workplace', $event)"
    />

    <!--    Период   -->
    <div class="report-filters__filter_periodFilter">
      <period-filter
        :value="report.filtersData.period"
        :m-fixed-height="false"
        @change="report.setFilterValue('period', $event)"
      />
    </div>

    <!-- Способ оплаты -->
    <m-buttons-group
      class="report-acquiring__filters_payment-type"
      :value="report.filtersData.paymentType"
      :items="PAYMENT_TYPE_FILTER_ITEMS"
      :m-fixed-height="false"
      full-width
      radio
      unselect
      @change="report.setFilterValue('paymentType', $event)"
    />

    <!-- Тип операции -->
    <m-select
      :value="report.filtersData.operationType"
      :items="OPERATION_TYPE_FILTER_ITEMS"
      :label="t$('operationType')"
      :placeholder="t$('allOperationType')"
      :m-fixed-height="false"
      full-width
      @change="report.setFilterValue('operationType', $event)"
    />

    <!-- Статус -->
    <m-select
      :value="report.filtersData.status"
      :items="STATUS_FILTER_ITEMS"
      :label="t('status')"
      :placeholder="t$('allStatuses')"
      :m-fixed-height="false"
      full-width
      @change="report.setFilterValue('status', $event)"
    />

    <!-- Плательщик -->
    <payer-search-input
      :value="report.filtersData.payer"
      full-width
      :m-fixed-height="false"
      clearable
      @change="report.setFilterValue('payer', $event)"
    />

    <!-- Сотрудник -->
    <user-filter
      :value="report.filtersData.userFilter"
      :m-fixed-height="false"
      full-width
      @change="report.setFilterValue('userFilter', $event)"
    />
  </report-base>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ReportBase from '@/vue_apps/ReportsModule/_ReportBase/ReportBase.vue'
import { AcquiringReport } from '@/vue_apps/ReportsModule/AcquiringReport/classes/AcquiringReport'
import { getAcquiringReportSchema } from '@/vue_apps/ReportsModule/AcquiringReport/consts/getAcquiringReportSchema'
import PeriodFilter from '@/vue_apps/ReportsModule/_filters/components/PeriodFilter.vue'
import ClinicFilter from '@/vue_apps/ReportsModule/_filters/components/ClinicFilter.vue'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import MButtonsGroup from '@/vue_present/_base/buttons/MButtonsGroup/MButtonsGroup.vue'
import { PAYMENT_TYPE_FILTER_ITEMS } from '@/vue_apps/ReportsModule/AcquiringReport/consts/paymentTypeFilterItems'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { OPERATION_TYPE_FILTER_ITEMS } from '@/vue_apps/ReportsModule/AcquiringReport/consts/operationTypeFilterItems'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import { STATUS_FILTER_ITEMS } from '@/vue_apps/ReportsModule/AcquiringReport/consts/statusFilterItems'
import UserFilter from '@/vue_apps/ReportsModule/_filters/components/UserFilter.vue'
import PayerSearchInput from '@/vue_present/Reuse/Lists/PayerSearchInput/PayerSearchInput.vue'
import { FiltersPresenter } from '@/vue_apps/ReportsModule/_filters/api/FiltersPresenter'

export default defineComponent({
  name: 'AcquiringReportApp',

  components: {
    PayerSearchInput,
    UserFilter,
    MSelect,
    MButtonsGroup,
    MSelectLazy,
    ClinicFilter,
    PeriodFilter,
    ReportBase,
  },

  mixins: [i18nScopeMixin],

  data () {
    return {
      filtersPresenter: new FiltersPresenter(),
      report: new AcquiringReport(),
      schema: getAcquiringReportSchema(),
      PAYMENT_TYPE_FILTER_ITEMS,
      OPERATION_TYPE_FILTER_ITEMS,
      STATUS_FILTER_ITEMS,
    }
  },

  created () {
    this.setI18nScope('finance')
  },
})
</script>
