import { ReportParamFactory } from '@/specific/reports/shared'

export const getFilters = () => {
  return {
    clinic: ReportParamFactory.getSelectParam('#report_clinic_id'),
    period: ReportParamFactory.getRawParam('.period'),
    user: ReportParamFactory.getSelectParam('#report_user_id'),
    attractionSources: ReportParamFactory.getSelect2Params('#report_attraction_source_ids'),
    callType: ReportParamFactory.getButtonsParam('#report_call_type_id'),
    callStatus: ReportParamFactory.getButtonsParam('#report_call_status_id'),
    callResult: ReportParamFactory.getSelectParam('#report_call_result_id'),
    refuseReason: ReportParamFactory.getSelectParam('#report_refuse_result_id'),
  }
}
