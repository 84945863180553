<template>
  <si v-if="!isHidden">
    <div class="report-subtitle cut">
      {{ subtitle }}
    </div>
    <si-table
      ref="table"
      :spinner="list.loading"
      horizontal
      class="print-flex"
    >
      <template #header>
        <procedures-report-table-header
          :doctor-area-module-enabled="doctorAreaModuleEnabled"
          :workplaces-enabled="workplacesEnabled"
          :header="header"
          :sortable-column="sortableColumn"
          @onSortChange="onSortChange"
        />
      </template>

      <template
        v-if="list.data.length"
        #body
      >
        <procedures-report-table-row
          v-for="(row, rowIndex) in list.data"
          :key="rowIndex"
          :row="row"
          :doctor-area-module-enabled="doctorAreaModuleEnabled"
          :workplaces-enabled="workplacesEnabled"
          :money-symbol="moneySymbol"
        />
      </template>

      <template #footer>
        <procedures-report-table-footer
          v-if="list.totals"
          class="total"
          :footer="footer"
          :workplaces-enabled="workplacesEnabled"
        />
      </template>
    </si-table>

    <si-footer>
      <m-pagination
        :page-count="list.totalPages"
        :current-page="list.currentPage"
        @update:currentPage="list.setPage($event)"
      />
    </si-footer>
  </si>
</template>

<script>
import Si from '@/vue_components/sort_items/si.vue'
import SiFooter from '@/vue_components/sort_items/si_footer.vue'
import SiTable from '@/vue_components/sort_items/si_table/si_table.vue'
import SortableReport from '@/vue_components/mixins/sortableReport'
import ReportWithTooltips from '@/vue_components/mixins/withTooltips'
import { header } from '@/vue_components/reports/ProceduresReport/consts'
import ProceduresReportTableHeader
  from '@/vue_components/reports/ProceduresReport/components/ProceduresReportTableHeader.vue'
import ProceduresReportTableRow from '@/vue_components/reports/ProceduresReport/components/ProceduresReportTableRow.vue'
import ProceduresReportTableFooter
  from '@/vue_components/reports/ProceduresReport/components/ProceduresReportTableFooter.vue'
import { MListServiceApi } from '@/_api/_requests/MListServiceApi/MListServiceApi'
import { proceduresAdapter } from '@/vue_components/reports/ProceduresReport/api/proceduresAdapter'
import MPagination from '@/vue_present/_base/Tables/MPagination/MPagination.vue'
import { proceduresReportFiltersAccessors } from '@/specific/reports/procedures/filtersAccessors'
import ReportGRPC from '@/vue_components/mixins/reportGRPC'
import { MgRPCStrategy } from '@/_api/_requests/MListServiceApi/strategies/MgRPCStrategy'
import { GRPCBuilderCSV } from '@/_api/_classes/GRPC/Receiver/GRPCBuilderCSV'
import { EWSChannels } from '@/services/declarations/EWSChannels'

export default {
  name: 'ProceduresReport',

  components: {
    MPagination,
    ProceduresReportTableFooter,
    ProceduresReportTableRow,
    ProceduresReportTableHeader,
    Si,
    SiTable,
    SiFooter,
  },

  mixins: [ReportGRPC, SortableReport, ReportWithTooltips],

  data () {
    return {
      header,

      list: new MListServiceApi({
        src: Routes.api_internal_analytics_reports_procedures_path(),
        defaultFilters: proceduresReportFiltersAccessors.get(),
        DataItemEntity: Object,
        TotalsItemEntity: Object,
        adapter: proceduresAdapter,
        Strategy: MgRPCStrategy,
      }, {
        wsChannel: EWSChannels.REPORT,
        errorCallback: () => { this.showReportError() },
      }),

      csvBuilder: new GRPCBuilderCSV({
        wsChannel: EWSChannels.REPORT,
        fileName: t('reports.short_titles.procedures'),
        src: Routes.api_internal_analytics_reports_procedures_path(),
        adapter: proceduresAdapter,
        errorCallback: () => { this.showExportError() },
      }),

      doctorAreaModuleEnabled: gon.application.doctor_area_module_enabled,
      workplacesEnabled: gon.application.workplaces_module_enabled,
      moneySymbol: gon.localization.currency.symbol_character,
    }
  },

  computed: {
    subtitleParts () {
      return [
        this.list.filters.clinic.name,
        this.list.filters.period,
        this.list.filters.entryWithOrder.id && this.list.filters.entryWithOrder.name,
        this.list.filters.entryWithDiscount.id && this.list.filters.entryWithDiscount.name,
        this.list.filters.entryTypes.map((entryType) => entryType.name).join(', '),
        this.list.filters.client.name,
        this.list.filters.clientGroups.map((group) => group.name).join(', '),
        this.list.filters.clientStatus.name,
        this.list.filters.clientSex.name,
        this.list.filters.entryKind.name,
        this.list.filters.entryTypeCategories.map((category) => category.name).join(', '),
        this.list.filters.administrator.name,
        this.list.filters.doctor.name,
      ]
    },

    footer () {
      return {
        total: t('total'),
        ...this.list.totals,
      }
    },
  },

  created () {
    this.setErrorEntity('proceduresReport')
  },

  methods: {
    afterReportUpdated () {
      this.updateTooltips()
    },

    export () {
      this.csvBuilder.send(proceduresReportFiltersAccessors.get())
    },
  },
}
</script>
