import { TEntryRawLikeNestedFieldSet } from '@/specific/orders_helpers/NDS/orderShowNDS/declarations'
import { prepareMembers } from '@/specific/orders_helpers/NDS/orderShowNDS/prepareMembers'
import { prepareTopLevelEntries } from '@/specific/orders_helpers/NDS/orderShowNDS/prepareTopLevelEntries'
import { drawNDSForTopLevelEntries } from '@/specific/orders_helpers/NDS/orderShowNDS/drawNDSForTopLevelEntries'
import { drawNDSTotal } from '@/specific/orders_helpers/NDS/orderShowNDS/drawNDSTotal'

type IEntryGroups = {
  members: TEntryRawLikeNestedFieldSet[]
  topLevelEntries: TEntryRawLikeNestedFieldSet[]
}

export const orderShowNDS = (entriesList: TEntryRawLikeNestedFieldSet[]) => {
  const { members, topLevelEntries }: IEntryGroups =
    // @ts-ignore
    Object.groupBy(
      entriesList,
      (entry: TEntryRawLikeNestedFieldSet) => entry.complex_id
        ? 'members'
        : 'topLevelEntries'
    )

  prepareMembers(members)
  prepareTopLevelEntries(topLevelEntries, members)
  drawNDSForTopLevelEntries(topLevelEntries)
  drawNDSTotal(topLevelEntries)
}
