<template>
  <el-badge
    class="m-badge"
    :value="value"
    :type="type"
  >
    <slot />
  </el-badge>
</template>

<script>

export default {
  name: 'MBadge',

  props: {
    value: { type: [String, Number], default: null },
    type: { type: String, default: null },
  },
}
</script>
