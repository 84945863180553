import { MReportListService } from '@/_api/_requests/MReportListService'
import { getDefaultFilters } from '@/vue_apps/ReportsModule/AcquiringReport/consts/getDefaultFilters'
import { DEFAULT_LIMIT } from '@/vue_components/store/modules/filters_base'
import { AcquiringReportPresenter } from '@/vue_apps/ReportsModule/AcquiringReport/api/AcquiringReportPresenter'
import { acquiringReportAdapter } from '@/vue_apps/ReportsModule/AcquiringReport/api/acquiringReportAdapter'
import { IAcquiringReportFilters } from '@/vue_apps/ReportsModule/AcquiringReport/interfaces/IAcquiringReportFilters'

export class AcquiringReport extends MReportListService {
  private presenter = new AcquiringReportPresenter()

  constructor (sortingMap = {
    paymentTypeCell: 'paymentType',
    operationTypeCell: 'operationType',
    statusCell: 'status',
    createdAtCell: 'createdAt',
    amountCell: 'amount',
    paymentCell: 'paymentId',
    userCell: 'userSurname',
    completedByCell: 'createdBySurname',
    payerCell: 'payer',
    workplaceCell: 'workplaceTitle',
    receiptPrintedCell: 'receiptPrinted',
  }) {
    super(
      getDefaultFilters(),
      new AcquiringReportPresenter(),
      { sortingMap, limit: DEFAULT_LIMIT }
    )
  }

  async export () {
    return this.presenter.export(acquiringReportAdapter.toExport(this.filtersData as IAcquiringReportFilters))
  }
}
