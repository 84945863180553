<template>
  <m-panel
    :title="t('nsi.dictionaries')"
    :use-type-by-route="false"
    :body-loading="loading"
  >
    <div class="nsi-configuration__warning mb-indent-validation">
      <m-icon
        icon="warning"
        color="warning"
      />
      <span class="prompt-notice">{{ t('nsi.egiszWarning') }}</span>
    </div>

    <m-input
      v-tooltip="t('nsi.userKeyTooltip')"
      :value="nsiConfiguration.nsiUserKey"
      class="nsi-configuration__userKey w-25"
      :label="t('nsi.userKey')"
      required
      validator-name="nsi_user_key"
      @registerValidator="onRegisterValidator"
      @input="nsiConfiguration.setValue('nsiUserKey', $event)"
    />

    <!-- Включаемые справочники -->
    <div
      v-for="nsiDictionary in nsiConfiguration.switchableDictionariesInfo"
      :key="nsiDictionary.id"
      class="nsi-configuration__dictionary mb-indent"
    >
      <m-checkbox
        class="inline-block"
        :value="nsiDictionary.enabled"
        :m-fixed-height="false"
        :label="nsiDictionary.title"
        @input="nsiDictionary.setValue('enabled', $event)"
      />

      <div class="nsi-info">
        <div class="prompt-notice">
          {{ t('nsi.last_update') }}:
          <span :class="{ red: !loading && !nsiDictionary.version }">
            {{ nsiDictionary.updatedAt }}
          </span>
        </div>
        <div class="prompt-notice">
          {{ t('nsi.version_number') }}: {{ nsiDictionary.version || '-' }}
        </div>
      </div>
    </div>

    <!-- Кнопки -->
    <div class="nsi-configuration__footer flex gap-indent-small align-items-center">
      <m-button-submit @click="onSubmit" />

      <m-button
        :text="t('nsi.sync')"
        icon="download"
        type="success"
        @click="updateDictionaries"
      />

      <m-icon
        v-if="hasNotUpdatedDictionaries"
        v-tooltip="t('nsi.hasTooOld')"
        icon="warning"
        class="warning"
      />
      <m-icon
        v-else
        v-tooltip="t('nsi.allActual')"
        icon="readyCircle"
        class="success"
      />
    </div>
  </m-panel>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { NSIConfigurationApi } from '@/vue_apps/app_configuration/NSI/entities/NSIConfigurationApi'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'

export default defineComponent({
  name: 'NSIForm',

  components: {
    MButton,
    MCheckbox,
    MInput,
    MButtonSubmit,
    MIcon,
    MPanel,
  },

  mixins: [SpinnerHolder, ValidationParentMixin],

  props: {
    nsiConfiguration: NSIConfigurationApi,
  },

  computed: {
    hasNotUpdatedDictionaries () {
      return this.nsiConfiguration.dictionariesInfo.items.some((item) => item.tooOld)
    },
  },

  methods: {
    async onSubmit () {
      if (this.hasErrors()) { return }

      await this.withSpinner(this.nsiConfiguration.save())
      await this.withSpinner(this.nsiConfiguration.load())
    },

    async updateDictionaries () {
      const result = await this.nsiConfiguration.setupDictionaries() || {}
      if ('errors' in result && 'error' in result.errors) {
        return Notificator.error(result.errors.error)
      }
    },
  },
})
</script>
