<template>
  <si-field>
    <si-column
      v-if="doctorAreaModuleEnabled"
      :name="header.number.sortable"
      class="tbl-icon"
      :active="sortableColumn"
    >
      {{ header.number.text }}
    </si-column>

    <si-column
      :name="header.order.sortable"
      :active="sortableColumn"
      class="number"
      @sort-change="onSortChange"
    >
      {{ header.order.text }}
    </si-column>

    <si-column
      :name="header.client.sortable"
      :active="sortableColumn"
      class="short_name cut"
      @sort-change="onSortChange"
    >
      {{ header.client.text }}
    </si-column>

    <si-column
      :name="header.age.sortable"
      :active="sortableColumn"
      class="age cut"
      @sort-change="onSortChange"
    >
      {{ header.age.text }}
    </si-column>

    <si-column
      :name="header.emkNumber.sortable"
      :active="sortableColumn"
      class="emk_number cut"
      @sort-change="onSortChange"
    >
      {{ header.emkNumber.text }}
    </si-column>

    <si-column
      :name="header.date.sortable"
      :active="sortableColumn"
      class="date"
      @sort-change="onSortChange"
    >
      {{ header.date.text }}
    </si-column>

    <si-column
      :name="header.title.sortable"
      :active="sortableColumn"
      class="title"
      @sort-change="onSortChange"
    >
      {{ header.title.text }}
    </si-column>

    <si-column
      :name="header.measureUnitShortTitle.sortable"
      :active="sortableColumn"
      class="measureUnitShortTitle"
      @sort-change="onSortChange"
    >
      {{ header.measureUnitShortTitle.text }}
    </si-column>

    <div class="short_name">
      {{ header.doctor.text }}
    </div>
    <si-column
      :name="header.amount.sortable"
      :active="sortableColumn"
      class="amount"
      @sort-change="onSortChange"
    >
      {{ header.amount.text }}
    </si-column>

    <si-column
      :name="header.sum.sortable"
      :active="sortableColumn"
      class="sum"
      @sort-change="onSortChange"
    >
      {{ header.sum.text }}
      <footnote :text="header.sum.footnote" />
    </si-column>

    <si-column
      :name="header.toPay.sortable"
      :active="sortableColumn"
      class="sum"
      @sort-change="onSortChange"
    >
      {{ header.toPay.text }}
      <footnote :text="header.toPay.footnote" />
    </si-column>

    <si-column
      :name="header.paid.sortable"
      :active="sortableColumn"
      class="sum"
      @sort-change="onSortChange"
    >
      {{ header.paid.text }}
    </si-column>

    <si-column
      :name="header.discountValue.sortable"
      :active="sortableColumn"
      class="sum"
      @sort-change="onSortChange"
    >
      {{ header.discountValue.text }}
    </si-column>

    <si-column
      v-if="workplacesEnabled"
      :name="header.receipt.sortable"
      :active="sortableColumn"
      class="receipt-field"
      @sort-change="onSortChange"
    >
      {{ header.receipt.text }}
    </si-column>

    <si-column
      :name="header.documentNumber.sortable"
      :active="sortableColumn"
      class="document-number"
      @sort-change="onSortChange"
    >
      {{ header.documentNumber.text }}
    </si-column>

    <si-column
      :name="header.documentCreatedAt.sortable"
      :active="sortableColumn"
      class="document-createdAt"
      @sort-change="onSortChange"
    >
      {{ header.documentCreatedAt.text }}
    </si-column>
  </si-field>
</template>

<script>
import SiField from '@/vue_components/sort_items/si_table/si_field'
import SiColumn from '@/vue_components/sort_items/si_table/si_column'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import Footnote from '@/vue_components/common/footnote'

export default {
  name: 'ProceduresReportTableHeader',
  components: { Footnote, SiColumn, SiField },
  props: {
    doctorAreaModuleEnabled: PropsTypes.Boolean(undefined, true),
    workplacesEnabled: PropsTypes.Boolean(undefined, true),
    header: PropsTypes.Object(undefined, true),
    sortableColumn: PropsTypes.String(undefined, true),
  },

  methods: {
    onSortChange ($event) {
      this.$emit('onSortChange', $event)
    },
  },
}
</script>
