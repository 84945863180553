import {
  IAcquiringReportTransaction,
} from '@/vue_apps/ReportsModule/AcquiringReport/interfaces/IAcquiringReportTransaction'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import {
  AcquiringListItemBase,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Acquiring/AcquiringListItemBase'
import {
  ACQUIRING_PAYMENT_TYPE_CODE,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringPaymentType'
import { tScoped } from '@/helpers/i18nHelpers'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

const ts = tScoped('finance')

export class AcquiringReportCells extends AcquiringListItemBase {
  payerCell: MTableCell

  receiptPrintedCell: MTableCell

  constructor (item: IAcquiringReportTransaction) {
    super(item)

    this.setPaymentTypeCell(item.paymentType)

    this.payerCell = new MTableCell(item.payer.title)
      .addTooltip(true)

    this.amountCell = new MTableCell(vueFilters.currency(item.amount))
      .addTooltip(true)

    this.setReceiptPrintedCell(item.receiptPrinted)
  }

  private setPaymentTypeCell (paymentTypeCode: ACQUIRING_PAYMENT_TYPE_CODE) {
    this.paymentTypeCell = paymentTypeCode === ACQUIRING_PAYMENT_TYPE_CODE.CARD
      ? new MTableCell()
        .addTypeIcon('billed')
        .addTooltip(ts('transactionNumber', { number: this.id }))
      : new MTableCell()
        .addTypeIcon('')
        .addClasses('report-acquiring_sbp-icon')
        .addTooltip(ts('transactionNumber', { number: this.id }))
  }

  private setReceiptPrintedCell (receiptPrinted: boolean) {
    const icon = receiptPrinted
      ? 'print'
      : 'notPrinted'

    const cssClasses = receiptPrinted
      ? 'success'
      : 'warning'

    const tooltip = receiptPrinted
      ? t('printed')
      : t('not_printed')

    this.receiptPrintedCell = new MTableCell()
      .addTypeIcon(icon)
      .addTooltip(tooltip)
      .addClasses(cssClasses)
  }
}
