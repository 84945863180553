import { reportErrorText } from '@/_api/_requests/helpers'

export default {
  data () {
    return {
      errorEntity: '',
      isHidden: true,
      loadButton: $('#report_submit'),
      exportButton: $('.export'),
    }
  },

  computed: {
    subtitle () {
      return this.subtitleParts
        ? `${this.subtitleParts.filter(Boolean).join('. ')}.`
        : ''
    },
  },

  watch: {
    'list.loading' (value: boolean) {
      if (value) {
        return this.loadButton.attr('disabled', 'disabled')
      }

      this.loadButton.removeAttr('disabled')

      if (typeof this.afterReportUpdated !== 'function') { return }
      this.afterReportUpdated()
    },

    'csvBuilder.loading' (value: boolean) {
      value
        ? this.exportButton.addClass('disabled')
        : this.exportButton.removeClass('disabled')
    },
  },

  methods: {
    updateFilters (filters: Record<string, any>) {
      const filtersWithSorting = {
        ...filters,
        sorting: this.sorting,
      }

      this.list.resetFilters(filtersWithSorting)
    },

    show () {
      this.isHidden = false
    },

    hide () {
      this.isHidden = true
      this.list.resetData()
    },

    setErrorEntity (entity: string) {
      this.errorEntity = entity
    },

    showError (message: string) {
      if (!this.errorEntity) {
        console.error('errorEntity не указан')
      }

      Utils.reportError(
        this.errorEntity,
        reportErrorText(message, this.errorEntity)
      )()
    },

    showReportError () {
      this.showError('fetchMessage')
    },

    showExportError () {
      this.showError('exportMessage')
    },
  },
}
