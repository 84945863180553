import { extractId } from '@/_medods_standart_library/msl'
import { MEntryTypePresenter } from '@/_api/MEntryTypePresenter/MEntryTypePresenter'
import { camelToSnake } from '@/_api/_requests/helpers'

/**
 * @param {IEntryPackEntryType[]} entryTypes
 * @return {Promise<[]>}
 */
export const addEntryPack = async (entryTypes) => {
  const entryTypesIds = entryTypes.map(extractId)

  const fullEntryTypes = await new MEntryTypePresenter().fetchByIds(entryTypesIds, true)
  let entryTypesHashMap = Utils.arrayToMap(fullEntryTypes)

  entryTypes.forEach((entryType) => {
    entryTypesHashMap[entryType.id].amount = entryType.amount
    ;(entryTypesHashMap[entryType.id].entry_type_members || []).forEach((member) => {
      member.amount = (member.amount || 1) * entryType.amount
    })

    entryTypesHashMap[entryType.id].measure_unit = camelToSnake(entryType.measureUnit)
  })

  entryTypesHashMap = null

  return fullEntryTypes
}
