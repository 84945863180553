import { TEntryRawLikeNestedFieldSet } from '@/specific/orders_helpers/NDS/orderShowNDS/declarations'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { TAX_RATES, TAX_RATES_TEXTS } from '@/vue_apps/Taxes/const/const'

const checkEntryIsComplex = (entry: TEntryRawLikeNestedFieldSet) => Boolean(entry._members)

const checkNdsIsNDSNone = (entry: TEntryRawLikeNestedFieldSet) => entry.tax_scheme === TAX_RATES.NDS_NONE

const checkNdsIsNDSNoneWhenMembersPrintingOn = (entry: TEntryRawLikeNestedFieldSet) =>
  checkEntryIsComplex(entry)
    ? entry._ndsValueText === TAX_RATES_TEXTS[TAX_RATES.NDS_NONE]
    : checkNdsIsNDSNone(entry)

export const drawNDSTotal =
  (topLevelEntries: TEntryRawLikeNestedFieldSet[]) => {
    const totalNDSSumContainer = document.querySelector('#order_nds_sum_value') as HTMLElement
    if (!totalNDSSumContainer) { return }

    const ndsNoneChecker = gon.application.print_receipt_with_complex_members
      ? checkNdsIsNDSNoneWhenMembersPrintingOn
      : checkNdsIsNDSNone

    const allNdsIsNDSNone = (topLevelEntries || []).every(ndsNoneChecker)

    if (allNdsIsNDSNone) {
      totalNDSSumContainer.innerText = TAX_RATES_TEXTS[TAX_RATES.NDS_NONE]

      return
    }

    totalNDSSumContainer.innerText = vueFilters.currency((topLevelEntries || []).reduce(
      (acc, entry) => acc + entry._ndsValue, 0)
    ).replace(' ', '')
  }
