import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

export const getAllProtocolsSchema = () => new MSiSchema()
  .addPagination()
  .addTableSchema(
    new MTableSchema()
      .addHeaders({
        pdfGenerated: new MTableHeader().addFixedWidth('40px'),
        date: new MTableHeader().addFixedWidth('100px'),
        title: new MTableHeader(),
      })
      .addCheckboxes()
  )
