<template>
  <div class="medical-records-protocol-selector hidden-print">
    <div class="flex align-items-center gap-indent-small w-100">
      <m-select-lazy
        :value="protocolsListValue"
        option-label="info"
        class="flex-basis-50"
        use-first-time-fetch
        full-width
        :m-fixed-height="false"
        :fetch-method="(params) => presenter.list({ entryIds: [entryId], ...params })"
        use-icon
        :clearable="false"
        :label="t('documents_owners.currentMedDocument')"
        :placeholder="placeholder"
        @syncItemsStored="protocolsStoredList = $event"
        @syncItems="protocolsDisplayedList = $event"
        @firstTimeFetched="onChange($event[0])"
        @changeByLazy="onChange"
      />

      <m-button
        v-if="protocolsListValue && canManageEntry"
        template="short_edit"
        @click="onProtocolEdit"
      />

      <m-button
        v-if="protocolDraftData && canManageEntry"
        :text="t('protocols.restoreFromDraft')"
        icon="history"
        type="text"
        @click="onProtocolEditWithRestoreFromDraft"
      />
    </div>
  </div>
</template>

<script>
import { ProtocolApi } from '@/vue_apps/ProtocolsApp/entities/ProtocolApi'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { drawProtocol } from '@/vue_apps/MedicalRecords/MedicalRecordsProtocol/actions/drawProtocol'
import { applyPrintingTools } from '@/vue_apps/MedicalRecords/MedicalRecordsProtocol/actions/applyPrintingTools'
import { ENTITIES } from '@/vue_components/store/modules/egisz/ws/egisz_ws'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import { MProtocolPresenter } from '@/vue_apps/ProtocolsApp/api/MProtocolPresenter'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { ProtocolDraftStorage } from '@/plugins/dynamic_forms/components/editor/ProtocolDraftStorage'
import { PROTOCOLS_ID } from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/consts'

export default {
  name: 'MedicalRecordsProtocolApp',

  components: { MButton, MSelectLazy },

  mixins: [
    SpinnerHolder,
  ],

  props: {
    entryId: { type: Number, required: true },
    medicalRecord: { type: Number, required: true },
  },

  data () {
    return {
      /** @type {ProtocolsListItem} */
      protocolsListValue: null,
      presenter: new MProtocolPresenter(),
      protocol: new ProtocolApi(),

      /**
       * Работа с protocolsStoredList и protocolsDisplayedList
       *   - это работа с дичайшими костылями для красивого обновления иконок в списке
       */
      protocolsStoredList: [],
      protocolsDisplayedList: [],
      protocolDraftData: null,
    }
  },

  computed: {
    placeholder () {
      return t('enums.unselected.he')
    },

    protocolId () {
      return this.protocolsListValue?.id
    },

    canManageEntry () {
      return this.$security.canManageEntry ||
          this.protocol.doctor?.id === gon.application.current_user.id
    },
  },

  created () {
    this.wsSubscribe()
    this.$pubSub.subscribe('broadcast:ProtocolDraftStorage:Saved', this.setProtocolDraftData)
    this.$pubSub.subscribe('broadcast:ProtocolDraftStorage:Dropped', this.dropProtocolDraftData)
  },

  beforeDestroy () {
    this.$pubSub.unsubscribe('broadcast:ProtocolDraftStorage:Saved', this.setProtocolDraftData)
    this.$pubSub.unsubscribe('broadcast:ProtocolDraftStorage:Dropped', this.dropProtocolDraftData)
  },

  methods: {
    wsSubscribe () {
      if (!gon.application.egisz_module_enabled) { return }

      Services.wsSubscriptions.egisz.connect(({ data, action }) => {
        if (action !== ENTITIES.PDF_GENERATED) { return }

        /** @type {ProtocolsListItem} */
        const protocolStoredListItem = this.protocolsStoredList.find(({ id }) => id === data?.id)
        if (protocolStoredListItem) { protocolStoredListItem.setPdfGenerated(true) }

        /** @type {ProtocolsListItem} */
        const protocolDisplayedListItem = this.protocolsDisplayedList.find(({ id }) => id === data?.id)
        if (protocolDisplayedListItem) { protocolDisplayedListItem.setPdfGenerated(true) }

        if (this.protocolsListValue?.id === data?.id) { this.protocolsListValue.setPdfGenerated(true) }

        if (data?.id === this.protocol.id) { this.protocol.setValue('pdfGenerated', true) }
      })
    },

    async onChange (protocolListItem) {
      if (!protocolListItem) { return }

      this.protocolsListValue = protocolListItem

      const { id } = protocolListItem

      this.protocol = new ProtocolApi({ id })
      const {
        errors,
        templateData,
        templateHtml,
        printClinicHeader,
        printDoctorSignature,
        printEntryInfo,
        printEntryTitle,
      } = await this.withSpinner(this.protocol.load())

      if (errors) { return }

      drawProtocol(templateData, templateHtml)

      applyPrintingTools({
        printClinicHeader,
        printDoctorSignature,
        printEntryInfo,
        printEntryTitle,
      })

      if (!id) { return }
      this.setProtocolDraftData({ protocolId: id })
    },

    setProtocolDraftData ({ protocolId }) {
      if (protocolId !== this.protocolId) { return }
      this.protocolDraftData = new ProtocolDraftStorage(protocolId).restore()
    },

    dropProtocolDraftData ({ protocolId }) {
      if (protocolId !== this.protocolId) { return }
      this.protocolDraftData = null
    },

    onProtocolEdit () {
      Utils.openInNewTab(
        this.$routes.protocols_page_path(
          this.medicalRecord,
          `${this.protocol.id}/edit`,
          { anchor: PROTOCOLS_ID }
        )
      )
    },

    onProtocolEditWithRestoreFromDraft () {
      Utils.openInNewTab(
        this.$routes.protocols_page_path(
          this.medicalRecord,
          `${this.protocol.id}/edit`,
          { anchor: PROTOCOLS_ID, byDraft: true })
      )
    },
  },
}
</script>
