import { createVueApp } from '@/vue_components/create_vue_app'
import CallsReport from '@/vue_components/reports/CallsReport/CallsReport.vue'
import { getFilters } from '@/vue_components/reports/CallsReport/consts/getFilters'

$document.on('rez/reports/calls', function () {
  Report.init()

  const reportTable = createVueApp({
    el: '#report_right_container',
    name: 'CallsReportApp',
    render: (h) => h(CallsReport),
    useChildren: true,
  })

  $('#report_submit').on('click', function () {
    reportTable.show()
    reportTable.updateFilters(getFilters())
  })

  $('#report_reset').on('click', () => {
    reportTable.hide()
  })

  $('.export').on('click', reportTable.export)
})
