import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { IAcquiringReportTotal } from '@/vue_apps/ReportsModule/AcquiringReport/interfaces/IAcquiringReportTotal'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { IReport } from '@/_declarations/IReport'

export class AcquiringReportTotalCell {
  paymentTypeCell: MTableCell

  constructor (item: IAcquiringReportTotal, count: IReport['count']) {
    this.paymentTypeCell = new MTableCell(t('reports.acquiring.total', {
      count: count || '',
      amount: vueFilters.currency(item.amount),
    }))
      .addClasses('absolute')
  }
}
