import { NSIConfigurationModel } from '@/vue_apps/app_configuration/NSI/entities/NSIConfigurationModel'
import { INSIConfigurationProps } from '@/vue_apps/app_configuration/NSI/declarations/INSIConfigurationProps'
import { NSISwitchableDictionaryModel } from '@/vue_apps/app_configuration/NSI/entities/NSISwitchableDictionaryModel'
import { NSIDictionariesInfo } from '@/vue_apps/app_configuration/NSI/entities/NSIDictionariesInfo'

export class NSIConfigurationLogic extends NSIConfigurationModel {
  fillProps (props?: INSIConfigurationProps) {
    if (!props) { return }

    this.nsiUserKey = props.nsiUserKey

    this.switchableDictionariesInfo = Object
      .entries(props.switchableDictionariesInfo)
      .map(([id, dict]) =>
        new NSISwitchableDictionaryModel({ id, ...dict })
      )

    this.dictionariesInfo = new NSIDictionariesInfo(props.dictionariesInfo)
  }
}
