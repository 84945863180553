<template>
  <div class="nsi-configuration">
    <n-s-i-form
      class="nsi-configuration__form"
      :nsi-configuration="nsiConfiguration"
    />
    <n-s-i-dictionaries-list
      class="nsi-configuration__dictionaries"
      :dictionaries="nsiConfiguration.dictionariesInfo"
      @createNsiDictionariesSuccess="onCreateNsiDictionariesSuccess"
    />
  </div>
</template>

<script>
import NSIForm from '@/vue_apps/app_configuration/NSI/components/NSIForm.vue'
import { NSIConfigurationApi } from '@/vue_apps/app_configuration/NSI/entities/NSIConfigurationApi'
import NSIDictionariesList from '@/vue_apps/app_configuration/NSI/components/NSIDictionariesList.vue'

export default {
  name: 'NSIConfigurationApp',

  components: {
    NSIDictionariesList,
    NSIForm,
  },

  data () {
    return {
      nsiConfiguration: new NSIConfigurationApi(),
    }
  },

  mounted () {
    this.nsiConfiguration.load()
  },

  methods: {
    onCreateNsiDictionariesSuccess () {
      this.nsiConfiguration.load()
    },
  },
}
</script>
