<template>
  <div class="add-protocol-button flex">
    <div
      v-if="canManage && hasExtraItems"
      class="add-protocol-button__dot-warn"
    />

    <m-drop-down
      v-if="canManage"
      split-button
      :button-tooltip="t('protocols.byTemplate')"
      :use-search="false"
      :items="items"
      type="success"
      trigger="hover"
      @click="onClick({ id: EAddProtocolSources.byTemplate, title: '' })"
      @itemClick="onClick"
    >
      <m-icon icon="templateRecord" />
      <template #dropdownItemAppend="{ item }">
        <div
          v-if="canManage && hasExtraItems && item.warn"
          class="add-protocol-button__dot-warn-item"
        />
      </template>
    </m-drop-down>
    <template v-else>
      <m-button
        v-tooltip="t('protocols.byScratch')"
        type="success"
        icon="plus"
        @click="onClick({ id: EAddProtocolSources.byScratch , title: '' })"
      />

      <m-button
        v-tooltip="t('protocols.byPrevProtocol')"
        type="success"
        plus-icon
        icon="file"
        class="ml-5"
        @click="onClick({ id: EAddProtocolSources.byPrevProtocol , title: '' })"
      />
    </template>

    <reusable-protocols-tree-modal
      use-search
      @registerApi="modalApi[EAddProtocolSources.byTemplate] = $event"
      @templateClick="redirectBy(EAddProtocolSources.byTemplate, $event.id)"
    >
      <span />
    </reusable-protocols-tree-modal>

    <based-on-previous-protocol-modal
      :id="protocolId"
      :client-id="clientId"
      @registerApi="modalApi[EAddProtocolSources.byPrevProtocol] = $event"
      @protocolClick="redirectBy(EAddProtocolSources.byPrevProtocol, $event.id)"
    >
      <span />
    </based-on-previous-protocol-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MDropDown from '@/vue_present/_base/MDropDown/MDropDown.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { EAddProtocolSources, getAddProtocolSources } from '@/vue_apps/ProtocolsApp/consts/addProtocolSources'
import { ICatalog } from '@/_declarations/ICatalog'
import ReusableProtocolsTreeModal from '@/vue_present/Reuse/ProtocolsTree/ReusableTemplateRecordsTreeModal.vue'
import BasedOnPreviousProtocolModal
  from '@/vue_apps/ProtocolsApp/components/AddProtocolButton/BasedOnPreviousProtocolModal.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { WindowCreatedByController } from '@/vue_apps/ProtocolsApp/logic/WindowCreatedByController'
import { ProtocolDraftStorage } from '@/plugins/dynamic_forms/components/editor/ProtocolDraftStorage'

const BASE_ITEMS_COUNT = 2

export default defineComponent({
  name: 'AddProtocolButton',

  components: {
    MButton,
    BasedOnPreviousProtocolModal,
    ReusableProtocolsTreeModal,
    MIcon,
    MDropDown,
  },

  props: {
    protocolId: { type: Number, default: null },
    clientId: { type: Number, required: true },
    medicalRecordId: { type: Number, required: true },
    entry: { type: Object as PropType<ICatalog>, default: null },
  },

  data () {
    return {
      items: getAddProtocolSources(),
      modalApi: {
        [EAddProtocolSources.byTemplate]: null as { openModal: () => void } | null,
        [EAddProtocolSources.byPrevProtocol]: null as { openModal: () => void } | null,
      } as Record<EAddProtocolSources, { openModal: () => void } | null>,

      EAddProtocolSources,
    }
  },

  computed: {
    canManage () {
      return this.$security.canViewTemplateRecord
    },

    hasExtraItems () {
      return this.items.length > BASE_ITEMS_COUNT
    },
  },

  watch: {
    '$route.name' (to, from) {
      if (to === from) { return }
      this.initItems()
    },
  },

  created () {
    this.initItems()

    ;[
      'broadcast:ProtocolDraftStorage:Dropped',
      'broadcast:ProtocolDraftStorage:Saved',
    ].forEach((target) => {
      this.$pubSub.subscribe(target, (event) => { this.setItems(event) })
    })
  },

  beforeDestroy () {
    ;[
      'broadcast:ProtocolDraftStorage:Dropped',
      'broadcast:ProtocolDraftStorage:Saved',
    ].forEach((target) => {
      this.$pubSub.unsubscribe(target, (event) => { this.setItems(event) })
    })
  },

  methods: {
    initItems () {
      this.setItems({ protocolId: ProtocolDraftStorage.DEFAULT_PROTOCOL_ID })
    },

    setItems ({ protocolId }) {
      if (protocolId !== ProtocolDraftStorage.DEFAULT_PROTOCOL_ID) { return }
      this.items = getAddProtocolSources({
        protocolDraft: Boolean(new ProtocolDraftStorage(this.protocolId, this.clientId).restore()),
      })
    },

    onClick (item: ICatalog<EAddProtocolSources>) {
      if (item.id === EAddProtocolSources.byScratch) {
        return this.redirectBy(EAddProtocolSources.byScratch, -1)
      }

      if (item.id === EAddProtocolSources.byTemplate) {
        if (!this.$security.canViewTemplateRecord) {
          return Notificator.error(t('no_permission'))
        }
      }

      if (item.id === EAddProtocolSources.byDraft) {
        return this.redirectBy(item.id, -1)
      }

      this.modalApi[item.id].openModal()
    },

    redirectBy (source: EAddProtocolSources, id: number) {
      const url = Routes.protocols_page_path(this.medicalRecordId, 'new', {
        [source]: id,
        entry: this.entry || undefined,
      })

      setTimeout(() => {
        WindowCreatedByController.set(
          Utils.openInNewTab(location.origin + url),
          gon.page.controller
        )
      })
    },
  },
})
</script>
