export const taxCertificatesReportSortingMap = Object.freeze({
  id: 'id',
  certificateId: 'certificateId',
  certificateNumber: 'certificateNumber',
  correctionNumber: 'correctionNumber',
  payerFullName: 'payerFullName',
  payerInn: 'payerInn',
  payerBirthDate: 'payerBirthDate',
  payerDocumentKind: 'payerDocumentKind',
  payerSeriesNumber: 'payerSeriesNumber',
  payerIssueDate: 'payerIssueDate',
  mark: 'mark',
  clientFullName: 'clientFullName',
  clientInn: 'clientInn',
  clientBirthdate: 'clientBirthDate',
  clientDocumentKind: 'clientDocumentKind',
  clientSeriesNumber: 'clientSeriesNumber',
  clientIssueDate: 'clientIssueDate',
  sumOne: 'sumOne',
  sumTwo: 'sumTwo',
  authenticatorFullName: 'authenticatorFullName',
  certificateDate: 'certificateDate',
  certificateXmlFileName: 'certificateXmlFileName',
})
