import { MListService } from '@/_api/_requests/MListService'
import { getSiGeneratorSchema } from '@/vue_present/_base/Tables/MSiGenerator/logic/getSiGeneratorSchema'
import { convertFiltersToString } from '@/vue_apps/ReportsModule/_filters/helpers/convertFiltersToString'
import { omit } from 'lodash'
import { isArray } from '@/helpers/typeofHelpers'

export class MReportListService extends MListService {
  __sorting = []

  total = {}

  siSchema = getSiGeneratorSchema()

  filtersString = ''

  __useGeneralFiltersString = false

  /**
   * @param {Object} initialFilters
   * @param {MPresenterBase} presenter
   * @param {IMListServiceConfig} config - для бизнес-костылей
   */
  constructor (initialFilters, presenter, config = {}) {
    super(initialFilters, presenter, config)
    this.__useGeneralFiltersString = config.useGeneralFiltersString ?? false
    this.setSortingMap(config.sortingMap || {})
  }

  toClientDataAdapter = (response) => response

  get sorting () {
    return this.__sorting
  }

  resetData () {
    super.resetData()
    this.total = {}
  }

  resetFilters (filters, { withInitialFilters = true } = {}) {
    const initialFilters = withInitialFilters
      ? this.__initialFilters
      : this.__getDefaultValuesFromInitialFilters()

    const allFilters = { ...initialFilters, ...filters }
    super.resetFilters(allFilters)

    if (filters) { this.filtered = true }
  }

  /**
   * @private
   */
  __getDefaultValuesFromInitialFilters () {
    return Object.entries(this.__initialFilters)
      .reduce((acc, [key, value]) => {
        if (isArray(value)) {
          acc[key] = []

          return acc
        }

        if (!value) {
          acc[key] = value
        }

        return acc
      }, {})
  }

  setSorting ({ name, order, priority }) {
    const target = this.__sortingMap[name]
    if (!target) {
      this.__sorting = []

      return
    }

    this.__sorting = [{ target, order, priority }]
  }

  setSortingMap (sortingMap) {
    this.__sortingMap = sortingMap
  }

  setSiSchema (siSchema) {
    this.siSchema = siSchema
  }

  /**
   * @param {(response) => unknown} adapterFn
   */
  setToClientDataAdapter (adapterFn = (response) => response) {
    this.toClientDataAdapter = adapterFn
  }

  setFiltersString () {
    this.filtersString = convertFiltersToString(
      omit(this.filters, ['limit', 'offset', 'searchQuery']),
      this.__useGeneralFiltersString
    )
  }

  async fetchAll () {
    this.setFiltersString()

    return super.fetchAll()
  }

  async export () {
    console.warn('Метод export не реализован')
  }
}
