import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'
import { tScoped } from '@/helpers/i18nHelpers'

const ts = tScoped('finance')

export const getAcquiringReportSchema = () =>
  new MSiSchema()
    .addPagination()
    .addTableSchema(
      new MTableSchema()
        .addMinWidth('1600px')
        .addHeaders({
          paymentTypeCell: new MTableHeader('№').addFixedWidth('60px'),
          operationTypeCell: new MTableHeader(ts('operationType')).addFixedWidth('135px'),
          statusCell: new MTableHeader(t('status')).addFixedWidth('125px'),
          createdAtCell: new MTableHeader(t('common.date')).addFixedWidth('145px'),
          amountCell: new MTableHeader(t('sum')).addFixedWidth('200px'),
          paymentCell: new MTableHeader(t('payment')).addFixedWidth('90px'),
          userCell: new MTableHeader(t('employee')),
          completedByCell: new MTableHeader(`${t('employee')}*`).addTooltip(t('tradeAcquiring.completedByFullName')),
          payerCell: new MTableHeader(t('payer')),
          workplaceCell: new MTableHeader(t('workplace')).addFixedWidth('140px'),
          receiptPrintedCell: new MTableHeader((ts('slipReceipt'))).addFixedWidth('100px'),
        })
        .addSorting()
        .addFooter()
    )
