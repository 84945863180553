import { ItemBase } from '@/_api/_classes/ItemBase'
import {
  INSIConfigurationSwitchableDictionary,
} from '@/vue_apps/app_configuration/NSI/declarations/INSIConfigurationSwitchableDictionary'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

export class NSISwitchableDictionaryModel extends ItemBase {
  _permit = ['id', 'enabled', 'updatedAt', 'version']

  id: string

  enabled: boolean = false

  updatedAt: string = ''

  version: string = ''

  title: string

  constructor (props: INSIConfigurationSwitchableDictionary) {
    super()

    this._fillProps(props)
    this.updatedAt = props.updatedAt
      ? vueFilters.dateTime(props.updatedAt)
      : '-'
    this.title = t('useDictionary', { dictionary: t(props.id, { scope: 'nsi.titles' }), scope: 'nsi' })
  }
}
