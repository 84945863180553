import { createVueApp } from '@/vue_components/create_vue_app'
import { snakeToCamel } from '@/_api/_requests/helpers'
import MedCardPrintModal from '@/vue_apps/MedicalRecords/MedCardPrintModal/MedCardPrintModal.vue'

let vueApp = null
export const createMedicalRecordsFixedDocumentsButton = (el = '#vue_fixed_documents_button_root') => {
  if (vueApp?.$destroy) { vueApp.$destroy() }
  vueApp = createVueApp({
    el,
    name: 'MedCardPrintModalRoot',
    render: (h) => h(MedCardPrintModal, {
      props: {
        client: snakeToCamel(gon.specific.client),
        medicalRecordId: gon.specific.medical_record_id,
      },
    }),
  })
}
