<template>
  <si v-if="!isHidden">
    <div class="report-subtitle cut">
      {{ subtitle }}
    </div>
    <si-table :spinner="isLoading">
      <template #header>
        <si-field>
          <si-column
            :name="header.title.sortable"
            :active="sortableColumn"
            class="title cut"
            @sort-change="onSortChange"
          >
            {{ header.title.text }}
          </si-column>
          <si-column
            :name="header.measureUnitShortTitle.sortable"
            :active="sortableColumn"
            class="measureUnitShortTitle"
            @sort-change="onSortChange"
          >
            {{ header.measureUnitShortTitle.text }}
          </si-column>
          <si-column
            :name="header.amount.sortable"
            :active="sortableColumn"
            class="amount cut"
            @sort-change="onSortChange"
          >
            {{ header.amount.text }}
          </si-column>
          <si-column
            :name="header.sum.sortable"
            :active="sortableColumn"
            class="sum cut"
            @sort-change="onSortChange"
          >
            {{ header.sum.text }}
            <span
              class="tooltip-bottom asterisk"
              :data-tooltip="header.sum.footnote"
            >
              *
            </span>
          </si-column>
          <si-column
            :name="header.finalSum.sortable"
            :active="sortableColumn"
            class="to_pay cut"
            @sort-change="onSortChange"
          >
            {{ header.finalSum.text }}
            <span
              class="tooltip-bottom asterisk"
              :data-tooltip="header.finalSum.footnote"
            >
              *
            </span>
          </si-column>
          <si-column
            :name="header.percent.sortable"
            :active="sortableColumn"
            class="percent cut"
            @sort-change="onSortChange"
          >
            {{ header.percent.text }}
            <span
              class="tooltip-bottom asterisk"
              :data-tooltip="header.percent.footnote"
            >
              *
            </span>
          </si-column>
        </si-field>
      </template>
      <template
        v-if="rows.length"
        #body
      >
        <si-field
          v-for="(row, rowIndex) in rows"
          :key="rowIndex"
        >
          <div class="cell title">
            {{ row.title }}
          </div>
          <div class="cell measureUnitShortTitle">
            {{ row.measureUnitShortTitle }}
          </div>
          <div class="cell amount">
            {{ $filters.replaceNull(row.amount) }}
          </div>
          <div class="cell sum">
            {{ $filters.price(row.sum) }}
          </div>
          <div class="cell to_pay">
            {{ $filters.price(row.finalSum) }}
          </div>
          <div class="cell percent">
            {{ $filters.price(row.sumPercent) }}
          </div>
        </si-field>
      </template>
      <template #footer>
        <si-field
          v-if="rows.length && isDirty"
          class="total"
        >
          <div class="title cut">
            {{ footer.title }}
          </div>
          <div class="measureUnitShortTitle" />
          <div class="amount">
            <span
              class="tooltip-bottom"
              :data-tooltip="footer.amount"
            >
              {{ footer.amount }}
            </span>
          </div>
          <div class="sum">
            <span
              class="tooltip-bottom"
              :data-tooltip="$filters.currency(footer.sum)"
            >
              {{ $filters.currency(footer.sum) }}
            </span>
          </div>
          <div
            class="to_pay"
          >
            <span
              class="tooltip-bottom"
              :data-tooltip="$filters.currency(footer.finalSum)"
            >
              {{ $filters.currency(footer.finalSum) }}
            </span>
          </div>
          <div class="percent">
            <!-- no total for percent -->
          </div>
        </si-field>
      </template>
    </si-table>
    <si-footer v-if="rows.length">
      <pagination
        :page-count="pageCount"
        layout="prev, pager, next"
        class="hidden-print"
        @current-change="loadData"
      />
    </si-footer>
  </si>
</template>

<script>
/**
 * @typedef {Object} ReportEntry
 * @property {Number} count
 * @property {Object[]} data
 * @property {Object} total
 */

/**
 * @typedef {Object} Entry
 * @property {String} title
 * @property {Number} amount
 * @property {Number} sum
 * @property {Number} finalSum
 * @property {Number} sumPercent
 */
/**
 * @typedef {ReportEntry} EntryReportData
 * @property {{entry: Entry}[]} data
 */
import Pagination from '@/vue_components/common/pagination.vue'
import SiFooter from '@/vue_components/sort_items/si_footer.vue'
import SiTable from '@/vue_components/sort_items/si_table/si_table.vue'
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'
import Report from '@/vue_components/mixins/report.js'
import SortableReport from '@/vue_components/mixins/sortableReport.js'
import ReportWithTooltips from '@/vue_components/mixins/withTooltips.js'
import Si from '@/vue_components/sort_items/si.vue'
import SiColumn from '@/vue_components/sort_items/si_table/si_column.vue'
import ReportFiltersFactory from '@/specific/reports/ReportFiltersFactory'

export default {
  name: 'Entries',

  components: {
    SiColumn,
    Pagination,
    Si,
    SiTable,
    SiField,
    SiFooter,
  },

  mixins: [Report, SortableReport, ReportWithTooltips],

  props: {
    clinic: { type: Object, required: true },
    period: { type: String, default: '' },
    reportKind: { type: Object, required: true },
    reportCategory: { type: Object, required: true },
    machines: { type: Array, required: true },
    user: { type: Object, required: true },
    laboratory: { type: Object, default: null },
    assistant: { type: Object, default: null },
  },

  data () {
    return {
      header: {
        title: {
          text: t('title'),
          sortable: 'entryTitle',
        },
        amount: {
          text: t('amount'),
          sortable: 'entryAmount',
        },
        sum: {
          text: `${t('sum')} (${Utils.currencySign()})`,
          footnote: t('sum_without_discount'),
          sortable: 'entrySum',
        },
        finalSum: {
          text: `${t('to_pay')} (${Utils.currencySign()})`,
          footnote: t('sum_with_discount'),
          sortable: 'entryFinalSum',
        },
        percent: {
          text: t('to_pay_procent'),
          footnote: t('to_pay_procent_footnote'),
          sortable: 'entrySumPercent',
        },
        measureUnitShortTitle: {
          text: t('measureUnitShort'),
          sortable: 'entryMeasureUnitShortTitle',
        },
      },
    }
  },

  computed: {
    subtitleParts () {
      return [
        this.period,
        this.clinic.name,
        this.reportKind.name,
        this.reportCategory.name,
        this.user.name,
        this.assistant && this.assistant.name,
        this.laboratory && this.laboratory.name,
        this.machines.map((machine) => machine.name),
      ]
    },
  },

  created () {
    this.reportClient.init({
      url: Routes.report_entries_path(),
    })
    this.requestBuilder.addStep(() => ReportFiltersFactory.defineFor('entries', this))
  },

  methods: {
    /**
     *
     * @param {EntryReportData} result
     */
    fillTable (result) {
      this.rows = result.data.map(({ entry }) => entry)

      this.footer = {
        title: t('total'),
        amount: result.total.amount,
        sum: result.total.sum,
        finalSum: result.total.finalSum,
      }
    },

    afterReportUpdated () {
      this.updateTooltips()
    },
  },

}
</script>
