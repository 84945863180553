<template>
  <cancellations
    ref="report"
    class="cancellations-report"
    :is-hidden="isHidden"
    v-bind="filters"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ReportWrapper from '@/vue_components/mixins/reportWrapper'
import Cancellations from '@/vue_components/reports/CancellationsReport/Cancellations.vue'
import { getFilters } from '@/specific/reports/cancellations'

export default defineComponent({
  name: 'CancellationsReportApp',

  components: { Cancellations },

  mixins: [ReportWrapper],

  data () {
    return {
      filters: getFilters(),
    }
  },
})
</script>
