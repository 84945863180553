import applicationFactory from '../plugins/dynamic_forms/application_factory'
import { introductionModalBuilder } from './introduction_modal.js'

// https://app.clickup.com/t/g760a8
// TODO: удалить когда будет реализовываться https://app.clickup.com/t/djz9et
import { snilsAddMask, subscribeToSnilsValidationError } from '@/specific/clients/methods/snils'
import { registerEntryTypesBreadcrumbsSub } from '@/specific/entry_types_helpers/entryTypesSubs'
import { createOrderBoostPopup } from '@/vue_apps/Orders/OrderBoostPopup'
import { createOrderMassSelect } from '@/vue_apps/Orders/OrderMassSelect'
import { resetDiscount } from '@/specific/orders_helpers/resetDiscount'
import { dropNamesFromFields } from '@/specific/orders_helpers/dropNamesFromFields'
import { postUpdateDefaultFields } from '@/specific/orders_helpers/postUpdateDefaultFields'
import { dropPerformer } from '@/vue_apps/Orders/helpers/dropPerformer'
import { cloneDeep, parseInt } from 'lodash'
import {
  MPersonalCheckupPresenter,
} from '@/vue_apps/CheckupsModule/api/MPersonalCheckupPresenter/MPersonalCheckupPresenter'
import { createEntryTypeSearchAdd } from '@/vue_present/Reuse/EntryTypes/EntryTypesSearchAdd'
import { orderAddEntryTypeCallback } from '@/specific/orders_helpers/orderAddEntryTypeCallback'
import { orderAddEntryPackCallback } from '@/specific/orders_helpers/orderAddEntryPackCallback'
import { fixUndefinedStoreId } from '@/specific/orders_helpers/fixUndefinedStoreId'
import { getMultiPrintDocumentsUrl } from '@/helpers/getMultiPrintDocumentsUrl'
import { createOrderPoliciesWithGuaranteeLetters } from '@/vue_apps/FinanceModule/OrderPoliciesWithGuaranteeLetters'
import { subscribeToLaboratoryField } from '@/specific/entry_types_helpers/field_enable_check'
import { createVueApp } from '@/vue_components/create_vue_app'
import OrderLeftInfo from '@/vue_components/order/order_left_info.vue'
import DiscountMasterApp from '@/vue_components/order/discount/DiscountMasterApp.vue'
import { createOrdersQuickAdditionApp } from '@/vue_apps/FinanceModule/OrdersQuickAddition'
import { orderShowNDS } from '@/specific/orders_helpers/NDS/orderShowNDS'

$document.on('rez/orders/show', function () {
  orderShowNDS(cloneDeep(gon.specific.entries))

  const $delete = $('#delete-record')
  const documents = gon.specific.documents

  $delete.deleteButton({
    url: Routes.order_path(gon.specific.order.id),
    redirect: Routes.finance_module_path(),
    name: t('order_1'),
  })

  $('#documents-button').on('click', function () {
    DocumentForm.show()
  })

  $('#client_documents_btn').on('click', function () {
    $('#client_documents').modal('show')
  })

  $('#add_client_documents_btn').on('click', function () {
    DocumentForm.show()
  })

  DocumentForm.init({
    documents,
    itemEditable: true,
    groupEditable: true,
    inModal: true, //enable document type edit in orders
    groupEditOlnly: true,
    client_id: gon.specific.client_id,
    order_id: gon.specific.order_id,
    customer_legal_entity_id: gon.specific.payer_company ? gon.specific.payer_company.id : undefined,
    customer_individual_id: gon.specific.payer_client ? gon.specific.payer_client.id : undefined,
    clientLegalRepresentatives: gon.specific.client.legal_representatives,
    clientLegalRepresentativesModalSelector: '#order_specifc_client_legal_representatives_modal',
    documentDatesModalSelector: '#document_dates_modal',
    source: 'show',
    redirectDestination: 'orders_show', //method in controller for redirect after action
    origin: 'order',
  })

  $('#client_doc_types_multiselect_form').checkboxMultiselect([
    ['client_id', gon.specific.client_id],
    ['order_id', gon.specific.order_id],
  ])

  $('#client_docs_multiselect_form')
    .checkboxMultiselect([
      ['client_id', gon.specific.client_id],
      ['order_id', gon.specific.order_id],
    ])
    .on('submit', function (event) {
      const formData = new FormData(event.currentTarget)
      const ids = formData.getAll('ids[]')
      const actions = formData.get('actions')

      if (actions === 'delete') { return }

      Turbolinks.visit(getMultiPrintDocumentsUrl(ids))
    })

  $('#documents_list .fa-trash-alt').deleteButton({
    name: t('document_1'),
    onSuccess (data, elem) {
      elem.closest('tr').remove()
    },
  })

  // 7jt1ev - orders/* - Создать заказ в лабораторию
  // Добавит вывод модального окна, если модуль Интеграции с лабораториями не активирован
  if (gon.specific.laboratories_integration && !gon.specific.laboratories_integration.moduleIsEnabled) {
    const modal = introductionModalBuilder('analysis_order_btn_modal', 'fa-flask', T.introduction.laboratories)

    if (modal) {
      $('#analysis_order_btn').on('click', function () {
        modal.modalShow()

        return false
      })
    }
  }
})

$document.on('rez/orders/new rez/orders/edit', function () {
  gon.specific.orderIsLoaded = false

  const Order = FormFactory.build('order')
  switch (pageInfo.action) {
    case 'new':
      Order.newItem()
      break
    case 'edit':
      Order.editItem()
      break
  }

  createVueApp({
    el: '#payer-selector',
    name: 'OrderLeftInfoApp',
    render: (h) => h(OrderLeftInfo),
  })

  $('#create_agreement_btn').on('click', function () {
    DocumentForm.show({
      clientId: gon.specific.order.client_id,
      customerClientId: gon.specific.order.customer_client_id,
      companyId: gon.specific.order.customer_company_id,
    })
  })

  $('#order_entries_table').on('keydown', '.f-order-entry-price', (event) => {
    if (event.key !== 'Enter') { return }

    event.preventDefault()
    event.stopPropagation()
    $(event.target).change()
  })

  Services.pubSub.subscribe('ORDER.PAYER_SELECTOR.UPDATE_CLIENT_INFO', (client) => {
    $('#client_info_fio').find('.client_info_fio_link').text(client.fullname)
  })

  if (gon.specific.stores && !gon.specific.stores.moduleIsEnabled) {
    const modal = introductionModalBuilder('stub_orders_stock_modal', 'fa-warehouse', T.introduction.stores)

    if (modal) {
      $('.js_stub_orders_stock').on('click', function () {
        modal.modalShow()
      })
    }
  }

  // https://app.clickup.com/t/g760a8
  // TODO: удалить когда будет реализовываться https://app.clickup.com/t/djz9et 1 / 4
  if (gon.page.controller === 'orders' && document.querySelector('#discount-new-form')) {
    Utils.OrderDiscountVue = createVueApp({
      el: '#discount-new-form',
      render: (h) => h(DiscountMasterApp),
      useChildren: true,
    })
  }

  applicationFactory.generate('backend')
  snilsAddMask('.client-snils')
  subscribeToSnilsValidationError()

  registerEntryTypesBreadcrumbsSub()

  // ORDER-BOOST

  const $container = $('#order_entries_table')
  const changesBanned = gon.specific.useBanPaidOrderAttributes
  const orderLocked = gon.specific.order_locked
  const isNewOrder = !gon.specific.order_id

  PubSub.on('page.form.order.calculateBonusDiscount.recalculate', () => {
    setTimeout(() => {
      if (changesBanned && orderLocked && !gon.specific.orderIsLoaded) {
        dropNamesFromFields($container)
      }

      if (isNewOrder && !gon.specific.orderIsLoaded) {
        postUpdateDefaultFields()

        // случай, когда врач не имеет доступа к клинике
        if (gon.specific.useCurrentClinic) {
          dropPerformer($container)
        }
      }

      if (!isNewOrder && !gon.specific.orderIsLoaded) {
        fixUndefinedStoreId()
      }

      $container.show()
      gon.specific.orderIsLoaded = true
    }, 0)
  })

  const personalCheckupId = parseInt(gon.page.params.personal_checkup_id)
  personalCheckupId && PubSub.on('page.form.order.submitSuccess', async (target, { item }) => {
    if (!item?.id) { return }

    const { errors } = await new MPersonalCheckupPresenter().setOrderId(personalCheckupId, item.id)
    if (errors) { return }

    Services.pubSub.emit(
      'updatePersonalCheckupOrderId',
      { orderId: item.id, personalCheckupId },
      true
    )
  })

  if (changesBanned && orderLocked) { return }

  createOrderMassSelect()
  createOrderBoostPopup()
  createEntryTypeSearchAdd(undefined, {
    entryTypeLegacyCallback: orderAddEntryTypeCallback,
    entryPackLegacyCallback: orderAddEntryPackCallback,
    useSearch: false,
    useEntryTypes: false,
  })

  if (!gon.specific.order_locked) {
    setTimeout(() => {
      createOrdersQuickAdditionApp()
    }, 1000)
  }

  createOrderPoliciesWithGuaranteeLetters({
    clientId: gon.specific.client_id,
  })

  setTimeout(subscribeToLaboratoryField, 0)
})

/**
 * Сброс скидки на бОльшую при создании счета
 */
$document.on('rez/orders/new', function () {
  resetDiscount()
})
