import applicationFactory from '../plugins/dynamic_forms/application_factory'
import chunk from 'lodash/chunk'
import { getPrintingToolsValues } from '@/helpers/printing_tools'

$document.on('rez/template_records/index', function () {
  if (!gon.application.doctor_area_module_enabled) return

  tinymce.remove()
  applicationFactory.generate('templateRec')

  const printEntryInfoField = document.querySelector('#print_entry_info_wrapper input[type="hidden"]')
  const printEntryTitleField = document.querySelector('#print_entry_title_wrapper input[type="hidden"]')
  const printClinicHeaderField = document.querySelector('#print_clinic_header_wrapper input[type="hidden"]')
  const printDoctorSignatureField = document.querySelector('#print_doctor_signature_wrapper input[type="hidden"]')

  const printingToolsValues = getPrintingToolsValues(gon.specific)

  if (printEntryInfoField) {
    printEntryInfoField.value = Number(printingToolsValues.entryInfo)
  }

  if (printEntryTitleField) {
    printEntryTitleField.value = Number(printingToolsValues.entryTitle)
  }

  if (printClinicHeaderField) {
    printClinicHeaderField.value = Number(printingToolsValues.clinicHeader)
  }

  if (printDoctorSignatureField) {
    printDoctorSignatureField.value = Number(printingToolsValues.doctorSignature)
  }

  const addButton = document.querySelectorAll('.template-records-add-btn')
  const actionsMarked = document.querySelector('.dropdown-toggle')
  const saveButton = document.querySelector('.modal-save')
  const importMenuBtn = document.querySelector('#import-items')
  const canManage = Services.security.canManageTemplateRecord

  if (!canManage) {
    addButton.forEach((item) => item.classList.add('hide'))
    actionsMarked.classList.add('hide')
    saveButton.classList.add('hide')
    importMenuBtn.classList.add('hide')
  }

  if (Utils) {
    $('#import-items').on('click', function () {
      $('#file_for_import').val('')
      $('#import-items-modal').megamodal('show')
      $('#import-items-modal').find('.modal-footer').hide()
      $('#import-items-modal').find('.megamodal_resize_full').hide()
      $('#import-button').hide()

      $('#result-import').hide()
      $('.items-form').show()
    })
  }

  $('#import-items-modal').bind('hidden.bs.modal', (e) => {
    $('#file_for_import').val('')
  })

  const importButton = document.querySelector('#import-button')
  const category = $('#category')
  let json = []

  const sendItems = (selectedItems) => {
    const CHUNK_SIZE = 5
    const chunks = chunk(selectedItems, CHUNK_SIZE)
    let successCount = 0
    let failCount = 0
    chunks.forEach((chunk, i) => {
      $.ajax({
        method: 'POST',
        url: Routes.import_template_records_path(),
        data: { items: chunk },
        success (data) {
          successCount += chunk.length
        },
        error (res) {
          failCount += chunk.length
          console.error(res.responseText)
        },
        complete () {
          if (successCount + failCount === selectedItems.length) {
            const resultText = `${T.success_import}: ${successCount},
                                ${T.error_count}: ${failCount}`
            $('#result-import').text(resultText)
            category.categoriesx('forceReload')
            $('#file_for_import').val('')
            $('#result-import').show()
            $('.items-form').hide()
            $('#import-button').attr('disabled', false)
          }
        },
      })
    })
  }

  const sendCategories = (selectedItems) => {
    const categories = []
    const categoriesMap = new Map()
    for (const item of selectedItems) {
      if (item.category && !categoriesMap.has(item.category.id)) {
        categoriesMap.set(item.category.id, true)
        categories.push(item.category)
      }
    }

    $.ajax({
      method: 'POST',
      url: Routes.import_template_records_path(),
      data: {
        categories,
      },
      success (oldToNewMap) {
        for (const item of selectedItems) {
          if (item.category) {
            item.category.id = oldToNewMap[item.category.id]
          }
        }
        sendItems(selectedItems)
      },
      error: (res) => console.error(res.responseText),
    })
  }

  importButton.onclick = function () {
    $('#import-button').attr('disabled', true)
    const selectedItems = []
    $('.import-items-checkbox:checked').each(function (i, item) {
      const id = $(item).attr('data-id')
      const importElement = json.find(function (item) {
        return item.id == id
      })
      selectedItems.push(importElement)
    })

    sendCategories(selectedItems)

    clearImportList()
  }

  function clearImportList () {
    // Очистить контейнер с элементами импорта
    const listContainer = document.querySelector('.import-items-list')
    listContainer.innerHTML = '' // Удаляет все дочерние элементы внутри контейнера

    $('#file_for_import').val('') // Очистить поле файла
    $('#import-button').attr('disabled', false)
  }

  document.querySelector('#file_for_import').addEventListener('change', handleFileSelect, false)

  function handleFileSelect (evt) {
    const files = evt.target.files

    for (const file of files) {
      const reader = new FileReader()

      reader.onload = function (e) {
        try {
          const newJson = JSON.parse(e.target.result)

          // Не перезаписывать json, а добавлять новые элементы
          json = json.concat(newJson)

          const listContainer = document.querySelector('.import-items-list')
          listContainer.innerHTML = ''

          const table = document.createElement('table')
          table.classList.add('table', 'table-striped', 'table-hover')
          const thead = document.createElement('thead')
          const tbody = document.createElement('tbody')

          const headerRow = document.createElement('tr')
          const headerCheckboxTh = document.createElement('th')
          headerCheckboxTh.classList.add('import-all-records-checkbox')
          const headerTitleTh = document.createElement('th')
          headerTitleTh.textContent = 'Все записи'

          const masterCheckbox = document.createElement('input')
          masterCheckbox.type = 'checkbox'
          masterCheckbox.addEventListener('change', function () {
            const checkboxes = document.querySelectorAll('.import-items-checkbox')
            checkboxes.forEach(function (cb) {
              cb.checked = masterCheckbox.checked
            })
            $('#import-button').toggle($('.import-items-checkbox:checked').length > 0)
          })

          headerCheckboxTh.appendChild(masterCheckbox)
          headerRow.appendChild(headerCheckboxTh)
          headerRow.appendChild(headerTitleTh)
          thead.appendChild(headerRow)

          // Перебираем все элементы из всех загруженных файлов и отображаем их
          json.forEach(function (item) {
            const tr = document.createElement('tr')
            const checkbox = document.createElement('input')
            checkbox.type = 'checkbox'
            checkbox.classList.add('import-items-checkbox')
            checkbox.setAttribute('data-id', item.id)
            checkbox.onchange = function () {
              const allChecked = document.querySelectorAll('.import-items-checkbox:checked').length === json.length
              masterCheckbox.checked = allChecked
              $('#import-button').toggle($('.import-items-checkbox:checked').length > 0)
            }

            const td = document.createElement('td')
            td.classList.add('options')
            td.appendChild(checkbox)
            const textTd = document.createElement('td')
            textTd.textContent = item.title
            tr.appendChild(td)
            tr.appendChild(textTd)
            tbody.appendChild(tr)
          })

          table.appendChild(thead)
          table.appendChild(tbody)
          listContainer.appendChild(table)
        } catch (ex) {
          alert('Error parsing JSON: ' + ex)
        }
      }
      reader.readAsText(file)
    }
  }
})
