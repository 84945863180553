import { createVueApp } from '@/vue_components/create_vue_app'
import NSIConfiguration from '@/vue_apps/app_configuration/NSI/NSIConfigurationApp.vue'

let vueApp = null
export const createNSIConfigurationApp = (el = '#vue_nsi_configuration_root') => {
  if (vueApp?.destroy) { vueApp.destroy() }

  vueApp = createVueApp({
    el,
    name: 'NSIConfigurationApp',
    render: (h) => h(NSIConfiguration),
  })
}
