<template>
  <div class="entry-protocols flex flex-column">
    <m-si-generator
      :items="protocolsList.data"
      :si-generator-schema="schema"
      :current-page="protocolsList.currentPage"
      :page-count="protocolsList.totalPages"
      :add-button-config="addBtnConfig"
      @update:currentPage="protocolsList.setPage($event)"
      @onItemClick="onProtocolClick"
      @onItemDelete="onDeleteProtocolClick"
      @onItemEdit="onEditProtocolClick"
      @onCellClick="onCellClick"
    >
      <template #filters>
        <add-protocol-button
          :client-id="clientId"
          :medical-record-id="medicalRecordId"
          :entry="entry"
        />

        <entry-protocols-client-protocols
          :entry-id="entryId"
          :client-id="clientId"
          :entry-protocol-ids="entryProtocolIds"
          @updateList="fetchList()"
        />
      </template>

      <template #itemOptionDelete="{ item }">
        <m-popover
          :message="t('protocols.detachProtocol?')"
          @yes="onDeleteProtocolClick(item)"
        >
          <template #reference>
            <m-icon
              v-tooltip="t('protocols.detach')"
              class="pointer"
              icon="delete"
              color="danger"
              @click.stop="() => {}"
            />
          </template>
        </m-popover>
      </template>
    </m-si-generator>
  </div>
</template>

<script lang="ts">
import { ENTITIES } from '@/vue_components/store/modules/egisz/ws/egisz_ws'
import { getProtocolsListSchemaForEntries } from '@/vue_apps/MedicalRecords/EntryProtocols/entities/getProtocolsSchema'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import { PROTOCOLS_ID } from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/consts'
import { ProtocolsListItem } from '@/vue_apps/MedicalRecords/EntryProtocols/entities/ProtocolsListItem'
import AddProtocolButton from '@/vue_apps/ProtocolsApp/components/AddProtocolButton.vue'
import { TMSiGeneratorSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiGeneratorTypes'
import { MEntryPresenter } from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/api/MEntryPresenter'
import { IApiErrors } from '@/_declarations/IApiErrors'
import EntryProtocolsClientProtocols
  from '@/vue_apps/MedicalRecords/EntryProtocols/componets/EntryProtocolsClientProtocols.vue'
import MPopover from '@/vue_present/_base/MPopover/MPopover.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { WindowCreatedByController } from '@/vue_apps/ProtocolsApp/logic/WindowCreatedByController'
import { MListServiceApi } from '@/_api/_requests/MListServiceApi/MListServiceApi'
import {
  protocolsListAdapter,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/api/protocolsConfig'
import {
  setDraftToProtocolsListItems,
} from '@/vue_apps/MedicalRecords/EntryProtocols/logic/setDraftToProtocolsListItems'
import { ProtocolDraftStorage } from '@/plugins/dynamic_forms/components/editor/ProtocolDraftStorage'

export default {
  name: 'EntryProtocolsApp',

  components: {
    MIcon,
    MPopover,
    EntryProtocolsClientProtocols,
    AddProtocolButton,
    MSiGenerator,
  },

  props: {
    entryId: { type: Number, required: true },
    entryTitle: { type: String, required: true },
    entryDate: { type: String, required: true },
    clientId: { type: Number, required: true },
    clinic: { type: Object, required: true },
    doctor: { type: Object, required: true },
    medicalRecordId: { type: Number, required: true },

    configurationPrintEntryTitle: Boolean,
    configurationPrintEntryInfo: Boolean,
    configurationPrintClinicHeader: Boolean,
    configurationPrintDoctorSignature: Boolean,
  },

  data () {
    return {
      protocolsList: new MListServiceApi({
        src: Routes.list_api_internal_protocols_path(),
        DataItemEntity: ProtocolsListItem,
        defaultFilters: { entryIds: [this.entryId] },
        adapter: protocolsListAdapter,
      }),
      schema: getProtocolsListSchemaForEntries() as TMSiGeneratorSchema,
    }
  },

  computed: {
    entry () {
      return {
        id: this.entryId,
        title: this.entryTitle,
        date: this.entryDate,
      }
    },

    addBtnConfig () {
      return {
        tooltip: t('add'),
        canManage: 'EntryTemplate',
      }
    },

    entryProtocolIds () {
      return this.protocolsList.data.map((item) => item.id)
    },
  },

  created () {
    if (tinymce.activeEditor) { tinymce.activeEditor.remove() }
    if (!this.entryId) { throw new Error('`entryId` is required') }
    this.fetchList()
    this.wsSubscribe()

    this.$pubSub.subscribe(
      'broadcast:ProtocolEditorView:ProtocolChanged',
      ({ entryIds }) => this.fetchListIfUpdateRequired(entryIds)
    )

    this.$pubSub.subscribe('broadcast:ProtocolDraftStorage:Saved', (event) => { this.setProtocolsDrafts(event, true) })
    this.$pubSub.subscribe('broadcast:ProtocolDraftStorage:Dropped', (event) => { this.setProtocolsDrafts(event, false) })
  },

  beforeDestroy () {
    this.$pubSub.unsubscribe(
      'broadcast:ProtocolEditorView:ProtocolChanged',
      ({ entryIds }) => this.fetchListIfUpdateRequired(entryIds)
    )

    this.$pubSub.unsubscribe('broadcast:ProtocolDraftStorage:Saved', (event) => { this.setProtocolsDrafts(event, true) })
    this.$pubSub.unsubscribe('broadcast:ProtocolDraftStorage:Dropped', (event) => { this.setProtocolsDrafts(event, false) })
  },

  methods: {
    fetchListIfUpdateRequired (entryIds = []) {
      if (!entryIds.includes(this.entryId)) { return }

      return this.fetchList()
    },

    async fetchList () {
      await this.protocolsList.fetchAll()
      this.setProtocolsDrafts()
    },

    setProtocolsDrafts ({ protocolId } = {}, value = true) {
      const protocolsIds = protocolId
        ? [protocolId]
        : ProtocolDraftStorage.getProtocolIds()

      setDraftToProtocolsListItems(this.protocolsList.data, protocolsIds, value)
    },

    wsSubscribe () {
      if (!gon.application.egisz_module_enabled) { return }

      Services.wsSubscriptions.egisz.connect(({ data, action }) => {
        if (action !== ENTITIES.PDF_GENERATED) { return }

        /** @type {ProtocolsListItem} */
        const protocolItem = this.protocolsList.data.find(({ id }) => id === data?.id)
        if (!protocolItem) { return }

        protocolItem.setPdfGenerated(true)
      })
    },

    async onProtocolClick (protocol: ProtocolsListItem) {
      if (!this.$security.canManageEntryTemplate) { return }
      this.onEditProtocolClick(protocol)
    },

    onEditProtocolClick (protocol: ProtocolsListItem, config = {
      routeParams: {},
    }) {
      if (!this.$security.canManageEntryTemplate) { return }

      WindowCreatedByController.set(
        Utils.openInNewTab(
          this.$routes.protocols_page_path(
            this.medicalRecordId, `${protocol.id}/edit`,
            { anchor: PROTOCOLS_ID, ...config.routeParams }
          )
        ),
        gon.page.controller
      )
    },

    onOpenProtocol (protocol: ProtocolsListItem) {
      if (!this.$security.canManageEntryTemplate) { return }
      Utils.openInNewTab(
        this.$routes.protocols_page_path(
          this.medicalRecordId,
          protocol.id, { anchor: PROTOCOLS_ID }
        )
      )
    },

    onCellClick ({ cell, item }: { cell: string; item: ProtocolsListItem }) {
      if (cell === 'draft') {
        this.onEditProtocolClick(item, { routeParams: { byDraft: true } })
      }

      if (cell === 'newTab') {
        this.onOpenProtocol(item)
      }
    },

    async onDeleteProtocolClick (protocol) {
      const result = await new MEntryPresenter().detachProtocols(this.entryId, [protocol.id])
      if ((result as IApiErrors)?.errors) { return }
      await this.fetchList()
    },

    errorsHandler (errors) {
      if (errors === 'exists_error') {
        Notificator.error(t('semds.errors.semdsExists'))
      }
    },
  },
}
</script>
