<template>
  <m-checkbox
    class="reusable-mine"
    :value="mine"
    :label="t('mine')"
    border
    @input="onInput"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'
import { IHuman } from '@/_declarations/IHuman'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'ReusableMine',

  components: { MCheckbox },

  model: {
    prop: 'value',
    event: 'setMine',
  },

  props: {
    value: { type: Object as PropType<IHuman>, default: null },
  },

  emits: [
    'setMine',
  ],

  data () {
    return {
      userId: gon.application.current_user.id,
    }
  },

  computed: {
    ...mapGetters('globalCatalogs/doctorStore', {
      vxCurrentDoctor: 'vxGetCurrentDoctor',
    }),

    mine () {
      return this.value?.id === this.userId
    },
  },

  methods: {
    onInput (value: boolean) {
      this.$emit(
        'setMine',
        value
          ? this.vxCurrentDoctor
          : null
      )
    },
  },
})
</script>
