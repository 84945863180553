<template>
  <si v-if="!isHidden">
    <div class="report-subtitle cut">
      {{ subtitle }}
    </div>
    <epic-spinner :visibility="list.loading" />
    <si-table
      ref="table"
      horizontal
    >
      <template #header>
        <si-field>
          <si-column
            :name="header.number.sortable"
            :active="sortableColumn"
            class="number"
            @sort-change="onSortChange"
          >
            {{ header.number.text }}
          </si-column>
          <si-column
            :name="header.date.sortable"
            :active="sortableColumn"
            class="datetime"
            @sort-change="onSortChange"
          >
            {{ header.date.text }}
          </si-column>
          <si-column
            :name="header.type.sortable"
            :active="sortableColumn"
            class="type"
            @sort-change="onSortChange"
          >
            {{ header.type.text }}
          </si-column>
          <si-column
            :name="header.status.sortable"
            :active="sortableColumn"
            class="status"
            @sort-change="onSortChange"
          >
            {{ header.status.text }}
          </si-column>
          <div class="short_name cut">
            {{ header.responsible.text }}
          </div>
          <si-column
            :name="header.client.sortable"
            :active="sortableColumn"
            class="short_name cut"
            @sort-change="onSortChange"
          >
            {{ header.client.text }}
          </si-column>
          <si-column
            :name="header.phone.sortable"
            :active="sortableColumn"
            class="phone"
            @sort-change="onSortChange"
          >
            {{ header.phone.text }}
          </si-column>
          <si-column
            :name="header.duration.sortable"
            :active="sortableColumn"
            class="duration"
            @sort-change="onSortChange"
          >
            {{ header.duration.text }}
          </si-column>
          <si-column
            :name="header.result.sortable"
            :active="sortableColumn"
            class="result cut"
            @sort-change="onSortChange"
          >
            {{ header.result.text }}
          </si-column>
          <si-column
            :name="header.refuseReason.sortable"
            :active="sortableColumn"
            class="refuse_result cut"
            @sort-change="onSortChange"
          >
            {{ header.refuseReason.text }}
          </si-column>
          <si-column
            :name="header.comment.sortable"
            :active="sortableColumn"
            class="comment cut"
            @sort-change="onSortChange"
          >
            {{ header.comment.text }}
          </si-column>
          <si-column
            :name="header.attractionSource.sortable"
            :active="sortableColumn"
            class="attraction_source cut"
            @sort-change="onSortChange"
          >
            {{ header.attractionSource.text }}
          </si-column>
        </si-field>
      </template>
      <template
        v-if="list.data.length"
        #body
      >
        <si-field
          v-for="(row, rowIndex) in list.data"
          :key="rowIndex"
        >
          <div class="number">
            {{ row.id }}
          </div>
          <div class="datetime">
            {{ utcDateTime(row.dateTime) }}
          </div>
          <div class="type">
            <span :class="row.type.icon" />
            {{ row.type.text }}
          </div>
          <div class="status">
            <span :class="row.status.icon" />
            {{ row.status.text }}
          </div>
          <div
            :data-tooltip="responsible(row.responsibles)"
            class="short_name cut"
          >
            {{ responsible(row.responsibles) }}
          </div>
          <div
            :data-tooltip="$filters.fullName(row.client)"
            class="short_name wrap"
          >
            <a
              v-if="row.client && !row.client.deletedAt"
              :href="row.client.link"
            >
              {{ $filters.fullName(row.client) }}
            </a>
            <span
              v-else
              class="deleted"
            >
              {{ $filters.fullName(row.client) }}
            </span>
          </div>
          <div class="phone">
            {{ phone(row.phone) }}
          </div>
          <div class="duration">
            {{ duration(row.duration) }}
          </div>
          <div
            :data-tooltip="row.result"
            class="result cut"
          >
            {{ row.result }}
          </div>
          <div
            :data-tooltip="row.refuseResult"
            class="refuse_result cut"
          >
            {{ row.refuseResult }}
          </div>
          <div
            :data-tooltip="row.comment"
            class="comment cut"
          >
            {{ row.note }}
          </div>
          <div
            :data-tooltip="row.attractionSource && row.attractionSource.title"
            class="attraction_source cut"
          >
            {{ row.attractionSource && row.attractionSource.title }}
          </div>
        </si-field>
      </template>
      <template #footer>
        <si-field
          v-if="list.totals"
          class="total"
        >
          <div class="number no-clip">
            {{ footer.total }}: {{ footer.count }}
          </div>
          <div class="datetime" />
          <div class="type" />
          <div class="status" />
          <div class="short_name cut" />
          <div class="short_name cut" />
          <div class="phone" />
          <div class="duration" />
          <div class="result cut" />
          <div class="refuse_result cut" />
          <div class="comment cut" />
          <div class="attraction_source cut" />
        </si-field>
      </template>
    </si-table>
    <si-footer>
      <m-pagination
        :page-count="list.totalPages"
        :current-page="list.currentPage"
        @update:currentPage="list.setPage($event)"
      />
    </si-footer>
  </si>
</template>

<script>
import HorizontalReport from '@/vue_components/mixins/horizontalReport.js'
import SortableReport from '@/vue_components/mixins/sortableReport.js'
import ReportWithTooltips from '@/vue_components/mixins/withTooltips.js'
import SiFooter from '@/vue_components/sort_items/si_footer.vue'
import SiTable from '@/vue_components/sort_items/si_table/si_table.vue'
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'
import SiColumn from '@/vue_components/sort_items/si_table/si_column.vue'
import Si from '@/vue_components/sort_items/si.vue'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import formatter from '@/lib/formatters/formatter'
import ReportGRPC from '@/vue_components/mixins/reportGRPC'
import { MListServiceApi } from '@/_api/_requests/MListServiceApi/MListServiceApi'
import { MgRPCStrategy } from '@/_api/_requests/MListServiceApi/strategies/MgRPCStrategy'
import { GRPCBuilderCSV } from '@/_api/_classes/GRPC/Receiver/GRPCBuilderCSV'
import MPagination from '@/vue_present/_base/Tables/MPagination/MPagination.vue'
import { callsAdapter } from '@/vue_components/reports/CallsReport/api/callsAdapter'
import { header } from '@/vue_components/reports/CallsReport/consts/header'
import { getFilters } from '@/vue_components/reports/CallsReport/consts/getFilters'
import { EWSChannels } from '@/services/declarations/EWSChannels'

export default {
  name: 'CallsReport',

  components: {
    MPagination,
    Si,
    SiTable,
    SiField,
    SiFooter,
    SiColumn,
    EpicSpinner,
  },

  mixins: [
    ReportGRPC,
    HorizontalReport,
    SortableReport,
    ReportWithTooltips,
  ],

  data () {
    return {
      header,

      list: new MListServiceApi({
        src: Routes.api_internal_analytics_reports_calls_path(),
        defaultFilters: getFilters(),
        DataItemEntity: Object,
        TotalsItemEntity: Object,
        adapter: callsAdapter,
        Strategy: MgRPCStrategy,
      }, {
        wsChannel: EWSChannels.REPORT,
        errorCallback: () => { this.showReportError() },
      }),

      csvBuilder: new GRPCBuilderCSV({
        wsChannel: EWSChannels.REPORT,
        fileName: t('telephony.calls'),
        src: Routes.api_internal_analytics_reports_calls_path(),
        adapter: callsAdapter,
        errorCallback: () => { this.showExportError() },
      }),
    }
  },

  computed: {
    subtitleParts () {
      return [
        this.list.filters.clinic.name,
        this.list.filters.period,
        this.list.filters.user.name,
        this.list.filters.attractionSources.map((source) => source.name),
        this.list.filters.callType.name,
        this.list.filters.callStatus.name,
        this.list.filters.callResult.name,
        this.list.filters.refuseReason.name,
      ]
    },

    footer () {
      return {
        total: t('reports.total_calls'),
        ...this.list.totals,
      }
    },
  },

  created () {
    this.setErrorEntity('callsReport')
  },

  methods: {
    responsible (users) {
      return users.map((user) => formatter.formatValue(user, ['client:fullName'])).join(', ')
    },

    phone (phone) {
      return formatter.formatValue(phone, ['client:phone'])
    },

    utcDateTime (date) {
      return formatter.formatValue(date, ['date:utcDateTime'])
    },

    duration (date) {
      return formatter.formatValue(date, ['date:duration'])
    },

    afterReportUpdated () {
      this.updateTooltips()
    },

    export () {
      this.csvBuilder.send(getFilters())
    },
  },
}
</script>
