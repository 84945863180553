<template>
  <div
    id="control_panel"
    class="panel panel-primary panel-inner"
  >
    <panel-heading
      v-if="stepsPanelEnabled"
      :title="T.laboratories.analysis_order_progress"
      icon="fad fa-tasks"
    >
      <template #buttons>
        <m-button
          v-if="isKdl && !resetContainersBtnEnabled"
          :text="t('laboratories.containersCalculation')"
          button-style="fill"
          type="dark"
          size="mini"
          @click="containersCalculation"
        />

        <el-button
          v-if="questionnaireEnabled"
          :disabled="questionnaireBtnDisabled"
          :class="['btn', 'btn-grey-dark', 'btn-xs']"
          :loading="questionnaireLoading"
          icon="fad fa-fw fa-question-square"
          @click="askQuestionnaire"
        >
          {{ T.laboratories.ask_questionnaire }}
        </el-button>

        <el-button
          v-if="preanalyticsEnabled"
          :loading="preanalyticsBtnLoading"
          :disabled="preanalyticsDisabled"
          :class="['btn', 'btn-grey-dark', 'btn-xs']"
          icon="fad fa-fw fa-vial"
          @click="sendPreanalyticsRequest"
        >
          {{ T.laboratories.request_preanalytics }}
        </el-button>

        <template v-if="sendingEnabled">
          <el-button
            :loading="sendingOrderBtnLoading"
            :disabled="sendingBtnDisabled"
            :class="['btn', 'btn-grey-dark', 'btn-xs']"
            icon="fad fa-fw fa-box"
            @click="sendAnalysisOrder"
          >
            {{ T.laboratories.send_order_to_laboratory }}
          </el-button>
        </template>
      </template>

      <div class="order_steps">
        <epic-spinner :visibility="showLoadingSpinner" />
        <div
          v-for="step in steps"
          :key="step"
          :class="stepClass(step)"
        >
          <span :class="statusIcon(step)" />
          <span>{{ stepTitle(step) }}</span>
        </div>
      </div>
    </panel-heading>

    <div
      v-if="writePermission"
      id="edit_buttons"
    >
      <div class="left_container flex gap-indent-small hidden-print">
        <m-button
          v-if="cancelOrderBtnEnabled && !isKdl"
          v-loading="cancelOrderBtnLoading"
          :disabled="cancelOrderBtnDisabled"
          :text="t('laboratories.cancel_analysis_order')"
          icon="close"
          @click="cancelAnalysisOrder"
        />

        <m-button
          v-if="checkResultBtnEnabled"
          v-loading="checkResultBtnLoading"
          :disabled="checkResultBtnDisabled"
          :text="t('laboratories.check_analysis_result')"
          icon="reset"
          @click="checkOrderResults"
        />

        <m-button
          v-if="editBtnEnabled"
          :disabled="editBtnDisabled"
          template="edit"
          @click="editOrder"
        />

        <m-button
          v-tooltip="removeOrderBtnDisabled ? t('laboratories.cant_remove_order_sent') : t('laboratories.remove_analysis_order')"
          :disabled="removeOrderBtnDisabled"
          :text="t('laboratories.remove_analysis_order')"
          template="delete"
          @click="removeAnalysisOrder"
        />
      </div>
    </div>

    <questionnaire
      :questions="questions"
      :questionnaire-loading="questionnaireLoading"
      :modal-visible="modalVisible"
      @save-questions="saveQuestions"
      @close-modal="closeModal"
    />
  </div>
</template>
<script>
import PanelHeading from '../../common/panel_heading'
import Questionnaire from './questionnaire'
import EpicSpinner from '../../epic_spinner/epic_spinner.vue'
import {
  ANALYSIS_ORDER_PUBSUB_PREFIX,
  ANALYSIS_ORDER_STATES,
  CONTROL_PANEL_ICONS,
  LABORATORY_SYSTEM_NAMES,
  STEPS,
} from '../constants.js'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { questionnaireAdapter } from '@/vue_components/analysis_orders/const/questionnaireAdapter'

export default {
  name: 'ControlPanel',
  components: {
    MButton,
    PanelHeading,
    Questionnaire,
    EpicSpinner,
  },

  props: {
    analysisOrderState: PropsTypes.Number(1),
    steps: PropsTypes.Array(),
    checkResultBtnEnabled: PropsTypes.Boolean(true),
    cancelOrderBtnEnabled: PropsTypes.Boolean(true),
    editBtnEnabled: PropsTypes.Boolean(true),
    sendingOrderBtnLoading: Boolean,
    preanalyticsBtnLoading: Boolean,
    healthCheckLabellerBtnLoading: Boolean,
    cancelOrderBtnLoading: Boolean,
    checkResultBtnLoading: Boolean,
    removeOrderBtnLoading: Boolean,
    resetContainersBtnEnabled: Boolean,
    currentLaboratory: PropsTypes.String(),
    workplaceId: PropsTypes.Number(),
    workplaceList: PropsTypes.Array(),
  },

  data () {
    return {
      questionnaireValidated: false,
      questionnaireLoading: false,
      sendingBtnLoading: false,
      modalVisible: false,
      printBarcodesBtnClicked: false,
      printCoverLetterBtnClicked: false,
      requestQuestionnaireBtnClicked: false,
      containersHaveEntries: false,
      sendBtnClicked: false,
      preanalyticsArrived: false,
      questions: [],
    }
  },

  computed: {
    showLoadingSpinner () {
      return this.steps.length === 0
    },

    questionnaireFilled () {
      return (this.questionnaireValidated || (
        this.questions.filter((question) => question.required)
          .every((question) => question.answer !== null) &&
                    this.questions.length !== 0
      )) || (this.analysisOrderState === ANALYSIS_ORDER_STATES.SENT)
    },

    preanalyticsRequired () {
      return this.steps.includes(STEPS.PREANALYTICS)
    },

    orderSent () {
      return (
        (this.analysisOrderState !== ANALYSIS_ORDER_STATES.PREPARED) &&
          (this.analysisOrderState !== ANALYSIS_ORDER_STATES.REFUSED)
      )
    },

    orderCanceled () {
      return this.analysisOrderState === ANALYSIS_ORDER_STATES.REFUSED
    },

    stepsPanelEnabled () {
      return this.analysisOrderState !== ANALYSIS_ORDER_STATES.REFUSED
    },

    fullPermissions () {
      return Boolean(gon.application.security.current_user_permissions.all)
    },

    readPermission () {
      if (this.fullPermissions) return true

      return gon
        .application
        .security
        .current_user_permissions.AnalysisOrder
        .includes('View')
    },

    writePermission () {
      if (this.fullPermissions) return true

      return gon
        .application
        .security
        .current_user_permissions.AnalysisOrder
        .includes('Manage')
    },

    sendingBtnDisabled () {
      if (!this.writePermission) return true

      if (this.preanalyticsRequired) {
        return this.orderSent || (!this.questionnaireFilled || !this.preanalyticsArrived)
      }

      if (this.isKdl && !this.resetContainersBtnEnabled) return true

      return this.orderSent || !this.questionnaireFilled
    },

    preanalyticsDisabled () {
      if (!this.writePermission) return true

      return this.orderSent || !this.questionnaireFilled || this.preanalyticsArrived
    },

    questionnaireBtnDisabled () {
      if (!this.writePermission) return true

      return this.orderSent
    },

    barcodesPrinted () {
      return this.printBarcodesBtnClicked
    },

    coverLetterPrinted () {
      return this.printCoverLetterBtnClicked
    },

    entriesAssigned () {
      return this.containersHaveEntries && this.orderSent
    },

    questionnaireEnabled () {
      return this.stepEnabled(STEPS.QUESTIONNAIRE)
    },

    sendingEnabled () {
      return this.stepEnabled(STEPS.SEND_TO_LAB)
    },

    preanalyticsEnabled () {
      return this.stepEnabled(STEPS.PREANALYTICS)
    },

    healthCheckLabellerEnabled () {
      return this.stepEnabled(STEPS.HEALTH_CHECK_LABELLER)
    },

    cancelOrderBtnDisabled () {
      return !this.orderSent
    },

    editBtnDisabled () {
      return this.orderSent || (this.isKdl && this.orderCanceled)
    },

    checkResultBtnDisabled () {
      return !this.orderSent
    },

    removeOrderBtnDisabled () {
      return this.orderSent
    },

    isInvitro () {
      return this.currentLaboratory === LABORATORY_SYSTEM_NAMES.INVITRO
    },

    isKdl () {
      return this.currentLaboratory === LABORATORY_SYSTEM_NAMES.KDL ||
      this.currentLaboratory === LABORATORY_SYSTEM_NAMES.KDL_CHECKUPS
    },
  },

  watch: {
    preanalyticsArrived () {
      this.$emit('onPreanalyticsArrived')
    },

    questionnaireFilled () {
      this.$emit('onQuestionnaireFilled')
    },
  },

  mounted () {
    this.subscribeToEvents()
  },
  updated () {
    this.$nextTick(Utils.updateTooltips)
  },
  methods: {
    statusIcon (stepName) {
      switch (stepName) {
        case STEPS.QUESTIONNAIRE: {
          if (this.questionnaireFilled) return CONTROL_PANEL_ICONS.CHECKED

          return CONTROL_PANEL_ICONS.UNCHEKED
        }
        case STEPS.CALCULATION_CONTAINERS: {
          if (this.resetContainersBtnEnabled) return CONTROL_PANEL_ICONS.CHECKED

          return CONTROL_PANEL_ICONS.UNCHEKED
        }
        case STEPS.SEND_TO_LAB: {
          if (this.orderSent) return CONTROL_PANEL_ICONS.CHECKED

          return CONTROL_PANEL_ICONS.UNCHEKED
        }
        case STEPS.PRINT_BARCODES: {
          if (this.barcodesPrinted) return CONTROL_PANEL_ICONS.CHECKED

          return CONTROL_PANEL_ICONS.UNCHEKED
        }
        case STEPS.PRINT_COVER_LETTER: {
          if (this.coverLetterPrinted) return CONTROL_PANEL_ICONS.CHECKED

          return CONTROL_PANEL_ICONS.UNCHEKED
        }
        case STEPS.ASSIGN_ENTRIES_TO_CONTAINERS: {
          if (this.entriesAssigned) return CONTROL_PANEL_ICONS.CHECKED

          return CONTROL_PANEL_ICONS.UNCHEKED
        }
        case STEPS.PREANALYTICS: {
          if (this.preanalyticsArrived) return CONTROL_PANEL_ICONS.CHECKED

          return CONTROL_PANEL_ICONS.UNCHEKED
        }
        default: return CONTROL_PANEL_ICONS.UNCHEKED
      }
    },

    stepTitle (stepName) {
      if (this.isKdl && stepName === STEPS.PRINT_COVER_LETTER) {
        return this.t('laboratories.steps.create_waybill')
      }

      return t(`laboratories.steps.${stepName}`)
    },

    stepClass (stepName) {
      switch (stepName) {
        case STEPS.QUESTIONNAIRE: {
          if (this.questionnaireFilled) return 'questionnaire_status filled'

          return 'questionnaire_status'
        }
        case STEPS.CALCULATION_CONTAINERS: {
          if (this.resetContainersBtnEnabled) return 'calculation_containers_status filled'

          return 'calculation_containers_status'
        }
        case STEPS.SEND_TO_LAB: {
          if (this.orderSent) return 'send_status filled'

          return 'send_status'
        }
        case STEPS.PRINT_BARCODES: {
          if (this.barcodesPrinted) return 'print_barcodes_status filled'

          return 'print_barcodes_status'
        }
        case STEPS.PRINT_COVER_LETTER: {
          if (this.coverLetterPrinted) {
            if (this.isKdl) {
              return 'create_waybill_status filled'
            }

            return 'print_cover_letter_status filled'
          }

          if (this.isKdl) {
            return 'create_waybill_status'
          }

          return 'print_cover_letter_status'
        }
        case STEPS.ASSIGN_ENTRIES_TO_CONTAINERS: {
          if (this.entriesAssigned) return 'assign_entries_to_containers_status filled'

          return 'assign_entries_to_containers_status'
        }
        case STEPS.PREANALYTICS: {
          if (this.preanalyticsArrived) return 'preanalytics filled'

          return 'preanalytics'
        }
        default:
          return 'step_status'
      }
    },

    stepEnabled (step) {
      return this.steps.includes(step)
    },

    closeModal () {
      this.modalVisible = false
    },

    containersCalculation () {
      this.$emit('containersCalculation')
    },

    saveQuestions (questions) {
      this.$emit('save-questions', questions)
    },

    askQuestionnaire () {
      this.questionnaireLoading = true
      if (this.questions.length === 0) {
        this.$emit('request-questions')
      } else {
        this.showQuestionnaire()
      }
    },

    askAnalysisOrderSteps () {
      this.$emit('request-analysis-order-steps')
    },

    sendAnalysisOrder (workplace = null) {
      this.sendBtnClicked = true
      this.$emit('update:workplaceId', workplace.id || null)
      this.$emit('send-analysis-order')
    },

    sendPreanalyticsRequest () {
      this.$emit('send-preanalytics-request')
    },

    editOrder () {
      if (this.editBtnDisabled) return

      this.$emit('edit-analysis-order')
    },

    cancelAnalysisOrder () {
      if (this.cancelOrderBtnDisabled) return

      bootbox.confirmYN(T.laboratories.confirm_cancel_analysis_order, (res) => {
        if (res) {
          this.$emit('cancel-analysis-order')
        }
      })
    },

    checkOrderResults () {
      if (this.checkResultBtnDisabled) return

      this.$emit('check-result')
    },

    removeAnalysisOrder () {
      if (this.removeOrderBtnDisabled) return

      bootbox.confirmYN(T.laboratories.confirm_remove_analysis_order, (res) => {
        if (res) {
          this.$emit('remove-analysis-order')
        }
      })
    },

    parseQuestions (questions) {
      const questionsAdaptedArray = questionnaireAdapter(questions)

      return questionsAdaptedArray.map((question) => {
        if (question.variants instanceof Array) {
          question.variants = question.variants.map((variant) => {
            try {
              return JSON.parse(variant)
            } catch (e) {
              return variant
            }
          })
        }
        question.error = false

        return question
      })
    },

    showQuestionnaire (data = null) {
      this.questionnaireLoading = false
      this.modalVisible = true
      if (data !== null) this.questions = this.parseQuestions(data)
      if (this.questions.length === 0) {
        this.questionnaireValidated = true
        this.modalVisible = false
        Notificator.info(T.laboratories.no_available_questions)
      }
    },

    subscribeToEvents () {
      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.control_panel.show_questionnaire`, (data) => {
        this.showQuestionnaire(data.result)
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.control_panel.print_barcodes`, () => {
        this.printBarcodesBtnClicked = true
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.control_panel.biomaterial_containers_changed`, (data) => {
        this.containersHaveEntries = data.allContainersHaveEntries
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.control_panel.preanalytics`, (data) => {
        this.preanalyticsArrived = true

        this.$forceUpdate()
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.control_panel.cover_letter_printed`, () => {
        this.printCoverLetterBtnClicked = true
      })
    },
  },
}
</script>
