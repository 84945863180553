<template>
  <m-panel
    class="protocol-view"
    :title="panelTitle"
    icon="file"
    :body-css-classes="['flex flex-row gap-indent overflow-hidden gap-indent-mid', {
      'p-0': isEditorMode,
      'background-hip-square': isEditorMode && canManage
    }]"
    data-turbolinks="false"
  >
    <div class="protocol-view__body">
      <router-view
        v-if="canManage"
        :id="protocolId"
        :client="client"
        :medical-record="medicalRecord"
        @setProtocolTitle="panelTitle = $event"
      />
      <introduction
        v-else
        introduction-text=""
        hide-button
        back-button
      />
    </div>

    <template v-if="drawTabs">
      <div
        v-show="!isEditorMode"
        id="entry_list_area"
        class="protocol-view__tabs hidden-print"
      >
        <div class="new_attachment hidden drag-and-drop" />

        <medical-records-tabs-app
          :client-id="client.id"
          :medical-record-id="medicalRecord.id"
          :default-tab="defaultTab"
        />
      </div>
    </template>

    <template #headExtra>
      <med-card-print-modal
        v-if="drawMedcardPrintModal"
        :client="client"
        :medical-record-id="medicalRecord.id"
      />

      <medical-records-print-documents
        v-if="drawMedicalRecordsPrintDocuments"
        :client="client"
      />

      <m-button
        v-if="isPrintPage"
        size="mini"
        template="print"
        type="dark"
        button-style="fill"
        @click="onPrintClick"
      />
    </template>
  </m-panel>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MedicalRecordsTabsApp from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/MedicalRecordsTabsApp.vue'
import { PROTOCOLS_ID } from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/consts'
import { PROTOCOLS_ROUTE_NAMES } from '@/vue_apps/ProtocolsApp/router/protocolsRouteNames'
import { IClient } from '@/_declarations/IClient'
import MedCardPrintModal from '@/vue_apps/MedicalRecords/MedCardPrintModal/MedCardPrintModal.vue'
import MedicalRecordsPrintDocuments
  from '@/vue_apps/MedicalRecords/MedicalRecordsPrintDocuments/MedicalRecordsPrintDocuments.vue'
import { IMedicalRecord } from '@/_declarations/IMedicalRecord'
import Introduction from '@/vue_components/introduction/introduction.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'

export default defineComponent({

  name: 'ProtocolsApp',

  components: {
    MButton,
    Introduction,
    MedicalRecordsPrintDocuments,
    MedCardPrintModal,
    MedicalRecordsTabsApp,
    MPanel,
  },

  props: {
    client: { type: Object as PropType<IClient>, required: true },
    medicalRecord: { type: Object as PropType<IMedicalRecord>, required: true },
    defaultTab: { type: String, default: PROTOCOLS_ID },
  },

  data () {
    return {
      panelTitle: '',
    }
  },

  computed: {
    canManage () {
      return this.$security.canManageEntryTemplate
    },

    protocolId () {
      return +this.$route.params.id || null
    },

    isShowMode () {
      return this.$route.name === PROTOCOLS_ROUTE_NAMES.SHOW
    },

    isEditorMode () {
      return this.$route.name === PROTOCOLS_ROUTE_NAMES.EDIT || this.$route.name === PROTOCOLS_ROUTE_NAMES.NEW
    },

    isPrintPage () {
      return this.$route.name === PROTOCOLS_ROUTE_NAMES.PRINT
    },

    drawTabs () {
      return !this.isPrintPage
    },

    drawMedcardPrintModal () {
      return !this.isEditorMode && !this.isPrintPage
    },

    drawMedicalRecordsPrintDocuments () {
      return !this.isEditorMode && !this.isPrintPage
    },
  },

  created () {
    if (!this.canManage) {
      this.panelTitle = t('insufficient_access_rights')
    }
  },

  methods: {
    onPrintClick () {
      window.print()
    },
  },
})
</script>
