<template>
  <catalogs-base
    :catalog-icon="catalogIcon"
    :catalog-title="t('analysis_laboratories')"
    :table-icon="catalogIcon"
    :table-title="panelTableTitle"
    :can-manage="canManage"
    :custom-class-form="customClass"
    :mode="mode"
    @close="closeForm"
  >
    <template #primary>
      <si-generator
        v-if="canView"
        v-loading="loading"
        :add-item-tooltip="t('laboratories.add_laboratory')"
        :table-schema="analysisLaboratoriesTableSchema"
        primary-icon="laboratories"
        :items="items"
        :active-item-id="activeItemId"
        :can-manage="canManage"
        class="flex-grow-1"
        @onAddItem="setSelectedLaboratory()"
        @onItemClick="setSelectedLaboratory"
      >
        <template #control-search>
          <span />
        </template>

        <template #table-header>
          <div class="si-field">
            <div class="catalogs_table__title">
              <span>
                {{ t('title') }}
              </span>
            </div>
          </div>
        </template>

        <template
          v-for="item in items"
          #tr-icon="{ item }"
        >
          <div
            :key="`id:${item.id}`"
            class="col col__icon"
          >
            <i class="fad fa-microscope font-size-17" />
          </div>
        </template>
      </si-generator>
    </template>

    <template #form>
      <laboratory-master
        :selected-laboratory="selectedLaboratory"
        :can-manage="canManage"
        :is-read-only="!canManage"
        :mode="mode"
        :clinics="clinics"
        :laboratory-list="laboratoriesList"
        @successSubmit="fetchLabs()"
        @successDelete="fetchLabs(); closeForm()"
      />
    </template>
  </catalogs-base>
</template>
<script>
import CatalogsBase from '@/vue_apps/catalogs_root/_catalog_base/CatalogsBase.vue'
import SiGenerator from '@/vue_components/sort_items/SiGenerator/SiGenerator.vue'
import {
  analysisLaboratoriesTableSchema,
  getDefaultAuthLaboratory,
  MODES_TITLES,
} from '@/vue_apps/catalogs_root/analysis_laboratories/const/const'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import {
  analysisLaboratoriesEndpoint,
} from '@/api_entities/catalogs/analysis_laboratories/analysis_laboratories_endpoint'
import { EXTRA_CHARGE_TYPES } from '@/vue_apps/catalogs_root/analysis_laboratories/const/helpers'
import LaboratoryMaster from '@/vue_apps/catalogs_root/analysis_laboratories/components/LaboratoryMaster.vue'
import { clinicEndpoint } from '@/api_entities/clinics/clinic_endpoint'
import { reportErrorText } from '@/vue_components/egisz/egisz_module/const/egisz_errors/egisz_errors'
import { MODES } from '@/vue_apps/catalogs_root/_catalog_base/const/const'

export default {
  name: 'AnalysisLaboratoriesCatalog',
  components: {
    LaboratoryMaster,
    CatalogsBase,
    SiGenerator,
  },

  mixins: [SpinnerHolder],

  data () {
    return {
      analysisLaboratoriesTableSchema,
      canManage: Utils.can(Utils.ACTIONS.MANAGE, 'AnalysisLaboratory'),
      canView: Utils.can(Utils.ACTIONS.VIEW, 'AnalysisLaboratory'),
      catalogIcon: 'fad fa-fw fa-microscope',
      mode: MODES.SHOW,
      items: [],
      EXTRA_CHARGE_TYPES,
      clinics: [],
      selectedLaboratory: null,
    }
  },

  computed: {
    panelTableTitle () {
      return MODES_TITLES[this.mode] || ''
    },

    customClass () {
      return this.mode === MODES.CREATE ? 'panel-green' : 'panel-orange'
    },

    activeItemId () {
      return this.selectedLaboratory && this.selectedLaboratory.id
    },

    laboratoriesList () {
      return this.items.reduce((acc, item) => {
        acc[item.title] = item.id

        return acc
      }, {})
    },
  },

  async created () {
    await this.fetchClinics()
    await this.fetchLabs()
  },

  methods: {
    fetchLabs () {
      const promise = analysisLaboratoriesEndpoint.getAll({ limit: 50, offset: 0 })
        .then(({ data }) => {
          this.items = data
        })
        .catch(Utils.reportError(
          'AnalysisLaboratories:getlabs() error',
          reportErrorText('list_message', 'laboratories')
        ))

      this.withSpinner(promise)
    },

    fetchClinics () {
      const promise = clinicEndpoint.fetchAll({ only_active: true })
        .then((data) => {
          this.clinics = data.data
        })
        .catch(Utils.reportError(
          'AnalysisLaboratories:fetchClinics()',
          reportErrorText('list_message', 'clinics')
        ))

      this.withSpinner(promise)
    },

    async setSelectedLaboratory (laboratory = []) {
      if (!laboratory.id) {
        this.mode = MODES.CREATE
        this.selectedLaboratory = {}

        return
      }

      const result = await analysisLaboratoriesEndpoint.fetchOne(laboratory.id)
      if (result?.errors) { return }

      const existingClinicIds = Object.keys(result.authAnalysisLaboratories)

      const missingClinics = this.clinics.filter(
        (clinic) => !existingClinicIds.includes(clinic.id.toString())
      )

      const missingAuthData = missingClinics.reduce((acc, clinic) => ({
        ...acc,
        [clinic.id]: getDefaultAuthLaboratory(clinic.id),
      }), {})

      if (missingClinics.length) {
        result.authAnalysisLaboratories = {
          ...result.authAnalysisLaboratories,
          ...missingAuthData,
        }
      }

      this.mode = MODES.EDIT
      this.selectedLaboratory = result
    },

    closeForm () {
      this.mode = MODES.SHOW
      this.selectedLaboratory = null
    },
  },
}
</script>
