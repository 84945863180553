import { INSIConfigurationDictionary } from '@/vue_apps/app_configuration/NSI/declarations/INSIConfigurationDictionary'
import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

export class NSIDictionaryListItem extends MListServiceItem {
  id: string

  tooOld: boolean

  oid: MTableCell

  title: MTableCell

  fixedVersion: MTableCell

  version: MTableCell

  updatedAt: MTableCell

  constructor (props: INSIConfigurationDictionary) {
    super()

    this.id = props.id
    this.updateAttributes(props)
  }

  updateAttributes (props: INSIConfigurationDictionary) {
    this.oid = new MTableCell(props.oid)
      .addTooltip(true)
    this.title = new MTableCell(props.title).addCut().addTooltip(true)
    this.fixedVersion = new MTableCell(props.fixedVersion)
      .addTypeIcon((props.fixedVersion && 'close-plan') || '')
      .addClasses('success')
      .addTooltip(props.fixedVersion && t('nsi.dictionariesTable.fixedVersion'))
    this.version = new MTableCell(props.version)

    this.updatedAt = props.updatedAt
      ? new MTableCell(vueFilters.dateTime(props.updatedAt))
      : new MTableCell()
        .addTypeIcon('warning')
        .addClasses('warning')
        .addTooltip(t('nsi.tooOld'))

    this.tooOld = !props.updatedAt
  }
}
