import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'
import { TMSiGeneratorSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiGeneratorTypes'
import { tScoped } from '@/helpers/i18nHelpers'

const $t = tScoped('nsi.dictionariesTable')

export const getSchema = () => new MSiSchema()
  .addTableSchema(
    new MTableSchema()
      .addHeaders({
        oid: new MTableHeader($t('oid')).addFixedWidth('230px'),
        title: new MTableHeader($t('title')),
        fixedVersion: new MTableHeader().addFixedWidth('40px')
          .addTypeIcon('close-plan')
          .addTooltip($t('fixedVersion')),
        version: new MTableHeader($t('version')).addFixedWidth('80px'),
        updatedAt: new MTableHeader($t('updatedAt')).addFixedWidth('150px'),
      })
  ) as TMSiGeneratorSchema
