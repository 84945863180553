import htmlHelper from '../plugins/dynamic_forms/helpers/htmlHelper'
import { createMedicalRecordsTabsApp } from '@/vue_apps/MedicalRecords/MedicalRecordsTabs'
import { subscribeToDragAndDrop } from '@/specific/medical_records/subscribeToDragAndDrop'
import { createMedicalRecordsProtocolApp } from '@/vue_apps/MedicalRecords/MedicalRecordsProtocol'
import { GLOBAL_ENTRY_TYPES_KINDS } from '@/_global_scripts/GLOBAL_CONSTS'
import { applyPrintingTools } from '@/vue_apps/MedicalRecords/MedicalRecordsProtocol/actions/applyPrintingTools'
import { createProtocolsApp } from '@/vue_apps/ProtocolsApp'
import { createMedicalRecordShowApp } from '@/vue_apps/MedicalRecords/MedicalRecordsShow'
import { createMedicalRecordsDocumentsButton } from '@/vue_apps/MedicalRecords/MedicalRecordsPrintDocuments'
import { createMedicalRecordsFixedDocumentsButton } from '@/vue_apps/MedicalRecords/MedCardPrintModal'

$document.on('rez/medical_records/show', function () {
  if (!gon.application.doctor_area_module_enabled) return

  htmlHelper.clearHtml()

  if (gon.specific.entry?.kind !== GLOBAL_ENTRY_TYPES_KINDS.GOOD) {
    createMedicalRecordsProtocolApp()
  }

  if (gon.specific.entry?.kind === GLOBAL_ENTRY_TYPES_KINDS.GOOD) {
    applyPrintingTools({
      printClinicHeader: false,
      printDoctorSignature: false,
      printEntryInfo: false,
      printEntryTitle: false,
    })
  }

  const documents = gon.specific.documents

  $('#print_medical_record').on('click', function () {
    DocumentForm.show()
  })

  $('#print_fixed_documents').on('click', function () {
    $('#fixed_documents_modal').modal('show')
  })

  $('.tooth-statable').on('click', () => {
    Notificator.info(t('change_dental_card_click_edit_button'))
  })

  DocumentForm.init({
    documents,
    client_id: gon.specific.client_id,
    clientLegalRepresentatives: gon.specific.client.legal_representatives,
    clientLegalRepresentativesModalSelector: '#client_legal_representatives_modal',
    documentDatesModalSelector: '#document_dates_modal',
    source: 'show',
  })

  $('#client_doc_types_multiselect_form').checkboxMultiselect([
    ['client_id', gon.specific.client_id],
  ])

  createMedicalRecordsTabsApp()
  createMedicalRecordShowApp()
  createMedicalRecordsFixedDocumentsButton()
  createMedicalRecordsDocumentsButton()
  subscribeToDragAndDrop(gon.page.client_id)

  $('#mr_data_area .attachment-preview-image, .attachment-preview-pdf').each((i, preview) => {
    $(preview).attachmentPreview({
      type: preview.dataset.mimetype,
      hideDownloadBtn: true,
      hidePrintBtn: true,
      headerText: $(preview).find('img').attr('alt'),
    })
  })
  $('#mr_data_area .attachment-preview-no').attachmentPreview({ type: '', hideDownloadBtn: true })
})

/* Отдельная мусорка для переключателей зубов */
$document.on('rez/medical_records/edit rez/medical_records/show', function () {
  $('#select_card_teeth_kind').milkChecker({ container: $('.dental-card') })
  $('#select_card_teeth_kind_teeth-map').milkChecker({ container: $('.dental-card') })
})

const PRINT_ENTRY_TITLE_FIX_MEDICAL_PRINT_STORE = 'print_entry_title_fix_medical_print'

$document.on('rez/medical_records/print', function () {
  const $printEntryTitleCheckbox = document.querySelector('#print_entry_title')
  const $fakePrintPanels = document.querySelectorAll('.fake-print-panel')
  const checked = JSON.parse(localStorage.getItem(PRINT_ENTRY_TITLE_FIX_MEDICAL_PRINT_STORE))

  $printEntryTitleCheckbox.checked = checked
  toggleShowTitleEntries($fakePrintPanels, checked)

  $printEntryTitleCheckbox.addEventListener('change', function (event) {
    localStorage.setItem(PRINT_ENTRY_TITLE_FIX_MEDICAL_PRINT_STORE, event.target.checked)
    toggleShowTitleEntries($fakePrintPanels, event.target.checked)
  })

  function toggleShowTitleEntries (panels, toggle) {
    const display = toggle ? 'flex' : 'none'
    panels.forEach((panel) => {
      panel.querySelector('.panel-heading').style.setProperty('display', display, 'important')
    })
  }

  htmlHelper.clearHtml()
})

$document.on('rez/medical_records/edit', function () {
  const form = FormFactory.build('medical_record')
  form.editItem()

  $('#medical_records_weight_input')
    .on('keydown', function (event) {
      if (event.key === '-') { return false }
      if (!Utils.onlyNumbersKeydownTemplate.includes(event.key)) { return false }
      if (['Backspace', 'Delete'].includes(event.key)) { return true }
    })
    .on('change', function (event) {
      const newValue = parseFloat(event.target.value)
      this.value = newValue < 0
        ? 0
        : newValue > 9999
          ? 9999
          : newValue
    })
})

$document.on('rez/medical_records/protocols', function () {
  createProtocolsApp()
  subscribeToDragAndDrop(gon.page.client_id)
})
