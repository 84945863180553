import { transformKeys } from '@/api_entities/_helpers/transform_keys'
import { camelCase } from 'lodash'
import {
  mappedVariablesValues,
  replaceVariables,
  variablesParser,
} from '@/vue_apps/catalogs_root/analysis_laboratories/const/variables'
import { helixMapper, invitroMapper, kdlMapper } from '@/api_entities/catalogs/analysis_laboratories/const'
import { SPECIAL_CLINICS } from '@/vue_apps/catalogs_root/analysis_laboratories/const/create_laboratories'
import { camelToSnake, snakeToCamel } from '@/_api/_requests/helpers'

export const analysisLaboratoryAdapter = {
  toClient (data) {
    const laboratory = snakeToCamel(data)
    const authData = laboratory.authAnalysisLaboratories.reduce((acc, item) => {
      item.clinicId = Number(item.clinicId)
      acc[item.clinicId] = transformKeys(camelCase)(item)

      return acc
    }, {})

    return {
      ...laboratory,
      entriesExtraChargeValue: +laboratory.entriesExtraChargeValue,
      authAnalysisLaboratories: authData,
    }
  },
}

export const analysisLaboratoriesGetAllAdapter = {
  toClient (data) {
    data.data = data.data.map((item) => {
      item.entries_extra_charge_value = Number(item.entries_extra_charge_value)

      return item
    })
    data.data = snakeToCamel(data.data)

    return data
  },

  toServer (laboratory) {
    if (!laboratory.id) { return { title: laboratory.title } }
    if (!laboratory.systemName) {
      return {
        title: laboratory.title,
        entries_extra_charge_type: '',
        entries_extra_charge_value: '',

      }
    }

    laboratory.barcodeTemplate = replaceVariables(
      laboratory.barcodeTemplate,
      variablesParser(laboratory.barcodeTemplate || ''),
      mappedVariablesValues(laboratory.systemName)
    )

    let data = {}
    if (laboratory.systemName === SPECIAL_CLINICS.INVITRO) {
      data = invitroMapper(laboratory)
    }

    if (laboratory.systemName === SPECIAL_CLINICS.HELIX) {
      data = helixMapper(laboratory)
    }

    if (laboratory.systemName === SPECIAL_CLINICS.KDL || laboratory.systemName === SPECIAL_CLINICS.KDL_CHECKUPS) {
      data = kdlMapper(laboratory)
    }

    return camelToSnake(data)
  },
}
