import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

export const getSchema = () =>
  new MSiSchema()
    .addTableSchema(
      new MTableSchema()
        .addHeaders({
          title: new MTableHeader(t('fixed_document_types')),
        })
    )
