<template>
  <m-panel
    class="orders-quick-addition user-select-none bg-success"
    :is-collapsed.sync="collapsed"
    is-parent-control
    collapsable
    icon="order"
    type="success"
    :title="t('fast_adding')"
    :use-type-by-route="false"
    placement="right"
  >
    <entry-types-tree-app
      v-show="!collapsed"
      :legacy-callback="legacyCallback"
    />
    <div
      v-show="collapsed"
      class="orders-quick-addition__expander pointer"
      @click="collapsed = false"
    >
      {{ t('fast_adding') }}
    </div>
  </m-panel>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import EntryTypesTreeApp from '@/vue_present/Reuse/EntryTypes/EntryTypesTree/EntryTypesTreeApp.vue'
import { fetchEntryType } from '@/vue_present/Reuse/EntryTypes/EntryTypesTree/legacy/fetchEntryType'

export default defineComponent({
  name: 'OrdersQuickAdditionApp',

  components: { EntryTypesTreeApp, MPanel },

  data () {
    return {
      collapsed: true,

      async legacyCallback (item) {
        const entryType = await fetchEntryType(item.id)
        PubSub.emit('page.form.order.orderEntryList.askAddEntry', { item: entryType })
      },
    }
  },
})
</script>
