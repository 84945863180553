import { ACQUIRING_STATUS_CODE } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringStatus'
import { DateHelpers } from '@/helpers/DateHelpers'

const getDefaultStatus = () => ({
  id: ACQUIRING_STATUS_CODE.SUCCESS,
  title: t('finance.transactionStatus.success'),
})

export const getDefaultFilters = () => ({
  clinic: null,
  workplace: null,
  period: DateHelpers.getCurrentMonthPeriod(),
  paymentType: null,
  operationType: null,
  status: getDefaultStatus(),
  payer: null,
  userFilter: null,
})
