/* При сортировке сбрасывается позиция скролла по X */

const extractScrollPos = (el) => {
  if (!el) { return { scrollTop: 0, scrollLeft: 0 } }

  return { scrollTop: el.scrollTop, scrollLeft: el.scrollLeft }
}

export default {
  data () {
    return {
      sorting: [],
      sortableColumn: '',
    }
  },

  methods: {
    async onSortChange ({ name, order }) {
      /* 🩼🩼🩼 Пришлось делать if/else из-за непонятной зависимости в data и this.loadData */
      if (!name) {
        this.sortableColumn = ''
        this.sorting = []
      } else {
        this.sortableColumn = name
        this.sorting = [{
          target: name,
          priority: 1, // use 1 or any other number > 0 to make analytics service not ignore sorting
          order,
        }]
      }

      const tableEl = this.$refs?.table?.$el
      const { scrollLeft } = extractScrollPos(tableEl)

      typeof this.list?.setFilterValue === 'function' // для gRPC отчётов
        ? await this.list.setFilterValue('sorting', this.sorting, { withResetPage: false })
        : await this.loadData(this.currentPage)

      if (!scrollLeft) { return }
      tableEl.scrollLeft = scrollLeft
    },
  },

  created () {
    if (this.requestBuilder) {
      this.requestBuilder.addStep(() => ({ sorting: this.sorting }))
    }
  },
}
