import applicationFactory from '../plugins/dynamic_forms/application_factory'
import CategoriesCopyModal from '@/components/categories_copy_modal'
import { NotFoundError } from '../modules/utils/errors'
import { createPricesImportVue } from '@/vue_components/EntryTypes/PricesImport'
import { setDefaultCovidFields, subscribeToLaboratoryField } from '@/specific/entry_types_helpers/field_enable_check'
import { createEntryTypesBreadcrumbs } from '@/vue_components/EntryTypes/Breadcrumbs'
import { registerEntryTypesBreadcrumbsSub } from '@/specific/entry_types_helpers/entryTypesSubs'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { UNSPECIFIED_TAX_RATE_TEXT, TAX_RATES_SENDABLE_TO_DRIVER, TAX_RATES_TEXTS } from '@/vue_apps/Taxes/const/const'

$document.on('rez/entry_types/index', function () {
  if (tinymce.activeEditor) {
    tinymce.activeEditor.remove()
  }
  const GOOD = 5
  const COMPLEX = 6
  const category = $('#category')
  const modalShow = $('#modal_entry_type_show')
  const addCategory = $('#add-category')
  const addItem = $('#add-item')
  const search = $('#entry_type_catalog_search')
  const categoryButtons = $('#category-buttons')
  const pluginEntryTypeSelector = $('#modal_entry_type_members .entry_type_selector')

  const EntryType = FormFactory.build('entry_type', {
    categoryList: category,
  })

  const groupForm = FormFactory.build('category_members_editing', {
    categoryList: category,
  })

  function updateItemBeforeShow (item) {
    addTaxRateMetaFieldsToItem(item)
    item.is_complex = item.kind === COMPLEX
  }

  function addTaxRateMetaFieldsToItem (item) {
    const taxRate = item.tax_scheme

    item.is_tax_rate_not_sendable_to_driver = taxRate &&
      !TAX_RATES_SENDABLE_TO_DRIVER.includes(taxRate)

    item.tax_rate_text = taxRate
      ? TAX_RATES_TEXTS[taxRate]
      : UNSPECIFIED_TAX_RATE_TEXT
  }

  function renderDependence (data, params) {
    const template = $('#handlebars_consumable_dependence_show').html()
    const render = Handlebars.compile(template)
    const msg = 'Удалить ' + params.title + '? <br/> Данный товар используется как расходный материал в следующих услугах:' + render(data)
    $('.bootbox-body').html(msg)

    $('.clickable').on('click', function () {
      const id = $(this).attr('id')
      $('#modal_entry_type_show').viewInModal('show', data.dependence[id])
      bootbox.hideAll()
    })
  }

  function checkGood (params) {
    if (params.kind === GOOD) {
      $.get(Routes.consumable_dependences_entry_type_path(params.id))
        .success(function (data) {
          if (data.dependence.length > 0) {
            renderDependence(data, params)
          }
        })
    }
  }
  groupForm.newItem()

  search.smartSearch({
    url: '/entry_types/search',
    method: 'get',
    onSelect (item) {
      updateItemBeforeShow(item)
      modalShow.viewInModal('show', item)
      setTimeout(function () {
        search.val('').change()
      }, 300)

      createEntryTypesBreadcrumbs('#entryTypeBreadcrumbsShow', item)
    },
    itemRender: $.EntryTypeSelector.smartSearchItemRender({ includePrice: true }),
  })

  const fastAddPanel = FormFactory.build('category_fast_panel', {
    onSubmit (params) {
      groupForm.newGroup(params)
    },
  })

  category.on('medods.categoriesx.loading', function () {
    addCategory.attr('disabled', 'disabled')
    addItem.attr('disabled', 'disabled')
  })

  category.on('medods.categoriesx.loaded', function () {
    addCategory.removeAttr('disabled')
    addItem.removeAttr('disabled')
  })

  modalShow.viewInModal({
    template: $('#handlebars_entry_type_show').html(),
    onEditBtn (item, closer) {
      EntryType.editItem(item)
      setDefaultCovidFields(item)
      $('.entry_type_members_area').empty()
      closer()
    },
    onDeleteBtn (item, closer) {
      checkGood(item)
      Utils.askDestroy({
        title: item.title,
        url: Routes.entry_type_path(item.id, { format: 'json' }),
        success () {
          category.categoriesx('removeItem', item)
          closer()
        },
      })
    },
    onCopyBtn (item, closer) {
      EntryType.newItem($.extend(item, { automatic_count: 2, copy: true }), true)
      closer()
    },
  })

  const copyModal = new CategoriesCopyModal({
    url: Routes.entry_types_categories_path(),
    categoryType: 7,
    initialCategory: null,
    onSubmit (copyCategoryId, destinationCategoryId) {
      $.ajax({
        method: 'POST',
        url: Routes.copy_categories_path({
          category_id: copyCategoryId,
          destination_id: destinationCategoryId,
        }),
        success (copy) { category.categoriesx('forceReload') },
        error () { Notificator.error(T.abstract_error_message) },
      })
    },
  })

  const isEditAvailable = Services.security.canManageEntryType
  const cssOffsetValue = isEditAvailable ? 190 : 112

  category.categoriesx({
    name: 'entry_types',
    url: Routes.entry_types_categories_path(),
    category_type: 7,
    editable: isEditAvailable,
    groupEdit: isEditAvailable,
    groupForm,
    itemForm: EntryType,
    responsive: {
      offset: cssOffsetValue,
    },
    itemUrl: Routes.entry_type_path,
    onItemEdit (item) {
      EntryType.editItem(item)
    },
    onItemRemove (item) {
      checkGood(item)
      Utils.askDestroy({
        title: item.title,
        url: Routes.entry_type_path(item.id) + '.json',
        success () {
          category.categoriesx('removeItem', item)
        },
      })
    },

    onItemMove (item, categoryId, cb) {
      EntryType.changeCatgory(item, categoryId, cb)
    },

    onItemCopy (itemRequest) {
      EntryType.newItem(itemRequest.then((loadedItem) => {
        loadedItem.clone = true

        return loadedItem
      }))
    },

    onCategoryCopy (cat) {
      copyModal.show(cat.id)
    },

    onClick (item) {
      updateItemBeforeShow(item)
      modalShow.viewInModal('show', item)
      setDefaultCovidFields(item)
      createEntryTypesBreadcrumbs('#entryTypeBreadcrumbsShow', item)
    },

    columns: [
      { // price column
        render (item, td) {
          return td.text(vueFilters.price(item.price))
        },
      },
    ],
  })

  category.on('medods.categoriesx.groupButtonsShown', function () {
    fastAddPanel.disable()
  })

  category.on('medods.categoriesx.groupButtonsHidden', function () {
    fastAddPanel.enable()
  })
  PubSub.on('page.form.members_editing.shown', function () {
    category.hide()
    categoryButtons.addClass('disabled-block')
    Utils.updateTooltipsInstances(document.querySelector('.fast-fields-area'))
  })

  PubSub.on('page.form.members_editing.hidden', function () {
    category.show()
    categoryButtons.removeClass('disabled-block')
  })

  PubSub.on('page.form.entry_type.submitSuccess', function () {
    // it's crutch which updates items of categoriesx
    // after creating new position

    pluginEntryTypeSelector
      .data('plugin-entry-type-selector')
      .list
      .categoriesx('forceReload')
  })

  addCategory.on('click', function () {
    category.categoriesx('newCategory')
  })

  addItem.on('click', function () {
    EntryType.newItem({ automatic_count: 2 }, true)
    setDefaultCovidFields({ analysis_laboratory: null })
  })

  applicationFactory.generate('backend')
  Utils.resizeToFullscreen()

  // if there is an id of the entry_type in query params
  // then try to open a dialog for it
  const editId = Utils.getQueryParams().id
  if (editId) {
    const entryType = fetch(Routes.entry_type_path(editId))
      .then((res) => {
        // only try to parse response as JSON if response code is ok,
        // or you might get a SyntaxError if server has responded not in JSON
        if (res.ok) {
          return res.json()
        }
      })
    // show modal first, start loading the entry
    EntryType.editItem(entryType)
      .catch((err) => {
        // check if this is our custom error, not some network thing
        if (err instanceof NotFoundError) {
          // ask to open the `newItem` form
          bootbox.confirm({
            message: `${T.no_such_entry}. ${T.create}?`,
            backdrop: true,
            buttons: {
              confirm: {
                label: T.create,
              },
              cancel: {
                className: 'btn-default',
              },
            },
            callback (shouldCreate) {
              if (shouldCreate) {
                EntryType.newItem({ automatic_count: 2 }, false)
              }
            },
          })
        } else {
          // maybe make other error messages more accurate
          Notificator.error(T.abstract_error_message)
          console.error(err)
        }
      })
  }
})

$document.on('rez/entry_types/index', () => {
  createPricesImportVue('#vue_prices_import')
  setTimeout(subscribeToLaboratoryField, 0)

  registerEntryTypesBreadcrumbsSub()
})
