<template>
  <div class="dictionaries__list-wrapper position-relative">
    <m-si-generator
      class="dictionaries__list"
      :items="dictionaries.items"
      :si-generator-schema="schema"
    />

    <div
      v-if="currentDictionaryInQueue"
      class="dictionaries__loading-bar"
    >
      {{ loadingBarText }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import { getSchema } from '@/vue_apps/app_configuration/NSI/const/getSchema'
import { NSIDictionariesInfo } from '@/vue_apps/app_configuration/NSI/entities/NSIDictionariesInfo'
import { INSIConfigurationDictionary } from '@/vue_apps/app_configuration/NSI/declarations/INSIConfigurationDictionary'
import { snakeToCamel } from '@/_api/_requests/helpers'
import { ENSIConfigurationUpdateEvents } from '@/vue_apps/app_configuration/NSI/entities/ENSIConfigurationUpdateEvents'

export default defineComponent({
  name: 'NSIDictionariesList',

  components: { MSiGenerator },

  props: {
    dictionaries: {
      type: Object as PropType<NSIDictionariesInfo>,
      required: true,
    },
  },

  emits: [
    'createNsiDictionariesSuccess',
  ],

  data () {
    return {
      schema: getSchema(),
      currentDictionaryInQueue: null,
    }
  },

  computed: {
    loadingBarText () {
      return `${t('nsi.loadingBarText')}: ${t(this.currentDictionaryInQueue, { scope: 'nsi.titles' })}`
    },
  },

  created () {
    Services.wsSubscriptions.user.connect(({ data, meta }) => {
      switch (meta.event) {
        case ENSIConfigurationUpdateEvents.Progress:
          return this.createNsiDictionariesProgress(snakeToCamel(data))
        case ENSIConfigurationUpdateEvents.Success:
          return this.createNsiDictionariesSuccess()
        case ENSIConfigurationUpdateEvents.Failure:
          return this.createNsiDictionariesFailure(data)
      }
    })
  },

  methods: {
    resetCurrentDictionary () {
      this.currentDictionaryInQueue = null
    },

    createNsiDictionariesProgress (dictionaries: Record<string, INSIConfigurationDictionary>) {
      if (dictionaries.downloadQueue) {
        this.currentDictionaryInQueue = dictionaries.downloadQueue

        return
      }

      Object.keys(dictionaries)
        .forEach((dictionaryName) => {
          this.dictionaries.updateItem(dictionaryName, dictionaries[dictionaryName])
        })
    },

    createNsiDictionariesSuccess () {
      this.resetCurrentDictionary()
      Notificator.success(t('nsi.successes.updated'))
      this.$emit('createNsiDictionariesSuccess')
    },

    createNsiDictionariesFailure (error) {
      this.resetCurrentDictionary()
      Utils.reportError(
        'NSIDictionariesList.createNsiDictionariesFailure()',
        t('nsi.errors.syncErrors')
      )(error)
    },
  },
})
</script>
