import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'

const baseAction = (
  path: string,
  title: string,
  clientId: number
) => {
  Turbolinks.visit(Routes.new_document_path({
    fixed_doc_type: true,
    path,
    title,
    client_id: clientId,
  }))
}

const makeItem = (clientId: number, documentPath: string, titlePrefix: string) => ({
  id: documentPath,
  title: new MTableCell(t(`${titlePrefix}${documentPath}`)),
  action () {
    return baseAction(documentPath, t(`${titlePrefix}${documentPath}`), clientId)
  },
})

export const getFixedDocumentRows = ({
  clientId,
  medicalRecordId,
}): Array<{
  id: string
  title: MTableCell
  action: () => void
}> => {
  const item = (documentPath: string, titlePrefix: string = 'medical_card_') =>
    makeItem(clientId, documentPath, titlePrefix)

  return [
    item('form_027_y'),
    item('medical_card_title_page', ''),
    item('medical_card_full_page', ''),
    item('form_003_y'),
    item('form_003-2_y-88'),
    item('dental_card_title_page', ''),
    {
      ...item('medical_card_entries', ''),
      action () {
        Turbolinks.visit(Routes.medical_record_print_path(medicalRecordId))
      },
    },
    {
      ...item('medical_card_protocols', ''),
      action () {
        Turbolinks.visit(`/medical_records/${medicalRecordId}/protocols/print`)
      },
    },
    // {
    //   ...item('medical_card_form_088_y', ''),
    //   action () {
    //     Turbolinks.visit(Routes.semd_documents_path('semd121/new', { clientId: gon.specific.client.id }))
    //   },
    // },
    item('form_107_1_y'),
    item('form_095_y'),
    item('form_066u_02'),
    item('form_025_1_y'),
    item('form_057_y_04'),
  ]
}
