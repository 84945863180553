<template>
  <m-select
    class="payer-search-input"
    v-bind="$attrs"
    :label="label"
    :placeholder="placeholder"
    filterable
    v-on="$listeners"
    @filterValueChange="onFilterValueChange"
    @change="onChange"
  >
    <template #options="{ valueKey, optionLabel }">
      <el-option-group
        v-if="useClient && clients.length"
        :label="clientLabel"
      >
        <el-option
          v-for="client in clients"
          :key="client[valueKey]"
          :label="client[optionLabel]"
          :value="client"
        />
      </el-option-group>

      <el-option-group
        v-if="useCompany && companies.length"
        :label="companyLabel"
      >
        <el-option
          v-for="company in companies"
          :key="company[valueKey]"
          :label="company[optionLabel]"
          :value="company"
        >
          <m-icon
            v-tooltip="company[optionLabel]"
            class="more-info-company"
            icon="info"
            color="gray"
            size="13px"
          />
          {{ company[optionLabel] }}
        </el-option>
      </el-option-group>
    </template>
  </m-select>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { PayerSearchPresenter } from '@/vue_present/Reuse/Lists/PayerSearchInput/api/PayerSearchPresenter'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { IPayerSearchClient } from '@/vue_present/Reuse/Lists/PayerSearchInput/interfaces/IPayerSearchClient'
import { PAYER_TYPE } from '@/vue_present/Reuse/Lists/PayerSearchInput/consts/consts'
import { IPayerSearchCompany } from '@/vue_present/Reuse/Lists/PayerSearchInput/interfaces/IPayerSearchCompany'

export default defineComponent({
  name: 'PayerSearchInput',

  components: { MIcon, MSelect },

  props: {
    useClient: { type: Boolean, default: true },
    useCompany: { type: Boolean, default: true },
    label: { type: String, default: t('payer') },
    placeholder: { type: String, default: t('finance.allPayers') },
  },

  emits: [
    'change',
    'onChangeCompany',
    'onChangeClient',
  ],

  data () {
    return {
      presenter: new PayerSearchPresenter(),
      clients: [],
      companies: [],
    }
  },

  computed: {
    useLabel () {
      return this.useClient && this.useCompany
    },

    clientLabel () {
      return this.useLabel
        ? t('clients')
        : ''
    },

    companyLabel () {
      return this.useLabel
        ? t('companies')
        : ''
    },
  },

  methods: {
    async onFilterValueChange (value: string) {
      if (!value) { return }

      const { clients, companies } = await this.presenter.fetchPayers(value)
      this.clients = clients
      this.companies = companies
    },

    onChange (value: IPayerSearchCompany | IPayerSearchClient) {
      this.$emit('change', value)

      if (!value) {
        this.$emit('onChangeCompany', null)
        this.$emit('onChangeClient', null)

        return
      }

      if (value.payerType === PAYER_TYPE.COMPANY) {
        this.$emit('onChangeClient', null)
        this.$emit('onChangeCompany', value)

        return
      }

      this.$emit('onChangeCompany', null)
      this.$emit('onChangeClient', value)
    },
  },
})
</script>
