import { NSIDictionaryListItem } from '@/vue_apps/app_configuration/NSI/entities/NSIDictionaryListItem'
import { INSIConfigurationDictionary } from '@/vue_apps/app_configuration/NSI/declarations/INSIConfigurationDictionary'

export class NSIDictionariesInfo {
  items: NSIDictionaryListItem[]

  private itemsMap: Record<string, NSIDictionaryListItem> = {}

  constructor (props: Record<string, INSIConfigurationDictionary> = {}) {
    this.items = Object
      .entries(props)
      .map(([id, dict]) => new NSIDictionaryListItem({ id, ...dict }))

    this.itemsMap = Utils.arrayToMap(this.items)
  }

  updateItem (id: string, item: INSIConfigurationDictionary) {
    if (!this.itemsMap[id]) { return }

    this.itemsMap[id].updateAttributes(item)
  }
}
