<template>
  <div class="workplace-selector">
    <m-select
      :value="workplaceId"
      :label="t('egisz.components.egisz_med_documents.form.workplace')"
      :items="vxGetWorkplaces"
      :m-fixed-height="false"
      use-custom-result="simple"
      :required="required"
      :validator-name="validatorName"
      :disabled="vxGetWorkplacesIsLoading || vxGetCertificatesIsLoading"
      @change="onWorkplaceChange"
    />
  </div>
</template>

<script>
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'WorkplaceSelector',

  components: { MSelect },

  props: {
    workplaceId: { type: Number, default: null },
    required: Boolean,
    validatorName: { type: String, default: null },
  },

  emits: ['workplaceChange'],

  data () {
    return {
      workplaceList: [],
    }
  },

  computed: {
    ...mapGetters('globalCatalogs/workplacesStore', {
      vxGetWorkplaces: 'vxGetWorkplaces',
      vxGetCertificates: 'vxGetCertificates',
      vxGetWorkplacesIsLoading: 'vxGetWorkplacesIsLoading',
      vxGetCertificatesIsLoading: 'vxGetCertificatesIsLoading',
    }),
  },

  created () {
    this.fetchWorkplaces()
  },

  methods: {
    ...mapActions('globalCatalogs/workplacesStore', {
      vxFetchWorkplaces: 'vxFetchWorkplaces',
      vxFetchCertificates: 'vxFetchCertificates',
    }),

    onWorkplaceChange (workplace) {
      this.$emit('workplaceChange', workplace)
    },

    async fetchWorkplaces () {
      await this.vxFetchWorkplaces({ connected: this.connected })
    },
  },
}
</script>
