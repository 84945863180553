import { DEFAULT_LIMIT } from '@/vue_components/store/modules/filters_base'
import { GRPC_FORMAT_TYPE } from '@/_api/_classes/GRPC/consts/consts'
import { ReportParamFactory } from '@/specific/reports/shared'
import { headerRow, totalRow } from '@/specific/reports/procedures/const'
import { defineDateFormat } from '@/specific/reports/export'
import { DEFAULT_OFFSET, MAX_LIMIT } from '@/specific/reports/const'

export const proceduresAdapter = {
  toClient (data) {
    return {
      data: data.data.map(({ entry }) => entry),
      totalItems: data.count,
      totalPages: Math.ceil(data.count / DEFAULT_LIMIT),
      totals: data.total,
    }
  },

  toServer (data, format = GRPC_FORMAT_TYPE.JSON) {
    return {
      report: {
        period: data.period,
        format,
      },

      clinicIds: ReportParamFactory.buildArrayParam(data.clinic),
      entryKinds: ReportParamFactory.buildArrayParam(data.entryKind),
      doctorIds: ReportParamFactory.buildArrayParam(data.doctor),
      administratorIds: ReportParamFactory.buildArrayParam(data.administrator),
      entryTypeIds: ReportParamFactory.buildArrayParam(data.entryTypes),
      entryTypeCategoryIds: ReportParamFactory.buildArrayParam(data.entryTypeCategories),
      clientIds: ReportParamFactory.buildArrayParam(data.client),
      clientGroupIds: ReportParamFactory.buildArrayParam(data.clientGroups),
      clientSex: data.clientSex.id,
      entryWithDiscount: data.entryWithDiscount.id,
      entryWithOrder: data.entryWithOrder.id,
      clientDeletedAt: data.clientStatus.id,
      doctorSpecialtiesIds: ReportParamFactory.buildArrayParam(data.doctorSpecialtiesIds),

      sorting: data.sorting,
      limit: data.limit,
      offset: data.offset,
    }
  },

  toExport (data) {
    return {
      ...proceduresAdapter.toServer(data, GRPC_FORMAT_TYPE.CSV),

      locale: {
        headerRow,
        totalRow,
        dateFormat: defineDateFormat(),
        csvComma: gon.application.csv_character,
        floatingComma: gon.localization.currency.decimal_character,
        currencyCodeString: gon.localization.currency.code_string,
      },

      offset: DEFAULT_OFFSET,
      limit: MAX_LIMIT,
    }
  },
}
