import { TEntryRawLikeNestedFieldSet } from '@/specific/orders_helpers/NDS/orderShowNDS/declarations'
import { GLOBAL_ENTRY_TYPES_KINDS } from '@/_global_scripts/GLOBAL_CONSTS'
import { getNDSForComplex } from '@/forms/components/order/NDS/getNDSForComplex'
import { getNDSForRow } from '@/forms/components/order/NDS/shared'

export const prepareTopLevelEntries =
  (topLevelEntries: TEntryRawLikeNestedFieldSet[] = [], members: TEntryRawLikeNestedFieldSet[] = []) => {
    ;(topLevelEntries || []).forEach((topLevelEntry) => {
      topLevelEntry.get = (key) => topLevelEntry[key]
      topLevelEntry._members = (members || []).filter((member) => member.complex_id === topLevelEntry.id)
      topLevelEntry.getMembers = () => topLevelEntry._members

      const { schemeTitle, schemeValueText } = topLevelEntry.kind !== GLOBAL_ENTRY_TYPES_KINDS.COMPLEX
        ? getNDSForRow(topLevelEntry)
        : getNDSForComplex(topLevelEntry)

      topLevelEntry._ndsTitle = schemeTitle
      topLevelEntry._ndsValueText = schemeValueText
    })
  }
