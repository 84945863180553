import { TEntryRawLikeNestedFieldSet } from '@/specific/orders_helpers/NDS/orderShowNDS/declarations'

const drawNDS = (tableContainer, entry: TEntryRawLikeNestedFieldSet) => {
  const rowContainer = tableContainer.querySelector(`tr[data-id="${entry.id}"]`)
  if (!rowContainer) { return }

  const ndsTitleContainer = rowContainer.querySelector('.f-order-entry-tax-scheme') as HTMLElement
  const ndsSumContainer = rowContainer.querySelector('.f-order-entry-tax-scheme-sum') as HTMLElement

  if (!ndsTitleContainer) { return }
  ndsTitleContainer.innerText = entry._ndsTitle

  if (!ndsSumContainer) { return }

  ndsSumContainer.innerText = entry._ndsValueText
}

export const drawNDSForTopLevelEntries =
  (topLevelEntries: TEntryRawLikeNestedFieldSet[]) => {
    const tableContainer = document.querySelector('#order_entries_area_tbody')
    ;(topLevelEntries || []).forEach((topLevelEntry) => {
      drawNDS(tableContainer, topLevelEntry)
      topLevelEntry._members.forEach((member) => drawNDS(tableContainer, member))
    })
  }
