<template>
  <div class="entry-protocols__client-protocols">
    <m-modal
      :visible="modalVisible"
      modal-title-icon="file"
      :dialog-title="t('protocols.clientProtocols')"
      @close="closeModal()"
    >
      <div
        v-if="requestDone"
        class="client-protocols__notice prompt-notice"
      >
        {{ t('protocols.attachProtocolTooltip') }}
      </div>

      <m-si-generator
        :items="filteredItems"
        :si-generator-schema="allProtocolsSchema"
        @onSetSelectedItems="selectedItems = $event"
      />

      <template #footer-left>
        <m-button
          v-tooltip="!selectedIds.length && t('enums.unselected.it')"
          template="save"
          :text="t('protocols.attach')"
          :disabled="!selectedIds.length"
          @click="onSubmit"
        />
      </template>
    </m-modal>

    <m-button
      v-tooltip="t('protocols.attachProtocolTooltip')"
      :text="t('protocols.attachProtocol')"
      type="primary"
      icon="file"
      @click="openModal"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { MListServiceApi } from '@/_api/_requests/MListServiceApi/MListServiceApi'
import { ProtocolsListItem } from '@/vue_apps/MedicalRecords/EntryProtocols/entities/ProtocolsListItem'
import {
  getProtocolsFilters,
  protocolsListAdapter,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/api/protocolsConfig'
import { TMSiGeneratorSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiGeneratorTypes'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { ModalMixin } from '@/vue_present/mixins/ModalMixins/ModalMixin'
import { MEntryPresenter } from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/api/MEntryPresenter'
import { ICatalog } from '@/_declarations/ICatalog'
import { callAfterTimeout } from '@/helpers/ElementUI/callAfterTimeout'
import { getAllProtocolsSchema } from '@/vue_apps/MedicalRecords/EntryProtocols/entities/getAllProtocolsSchema'
import { IApiErrors } from '@/_declarations/IApiErrors'

export default defineComponent({
  name: 'EntryProtocolsClientProtocols',

  components: { MButton, MModal, MSiGenerator },

  mixins: [
    ModalMixin,
  ],

  props: {
    entryId: { type: Number, required: true },
    clientId: { type: Number, required: true },
    entryProtocolIds: { type: Array, default: () => [] },
  },

  data () {
    return {
      allProtocolsList: new MListServiceApi({
        src: Routes.list_api_internal_protocols_path(),
        DataItemEntity: ProtocolsListItem,
        defaultFilters: getProtocolsFilters({ clientId: this.clientId }),
        adapter: protocolsListAdapter,
        requiredFilters: ['clientId'],
        limit: 50,
      }),
      allProtocolsSchema: getAllProtocolsSchema() as TMSiGeneratorSchema,
      selectedItems: [],
      requestDone: false,
    }
  },

  computed: {
    selectedIds () {
      return this.selectedItems.map((item) => item.id)
    },

    filteredItems () {
      return this.allProtocolsList.data
        .filter((item: ICatalog) => !this.entryProtocolIds.includes(item.id))
    },
  },

  methods: {
    async openModal () {
      this.modalVisible = true
      await this.allProtocolsList.fetchAll()
      await this.$nextTick()
      this.requestDone = true
    },

    closeModal () {
      this.modalVisible = false
      this.requestDone = false
      callAfterTimeout(() => {
        this.allProtocolsList.resetData()
      })
    },

    async onSubmit () {
      const result = await new MEntryPresenter().attachProtocols(
        this.entryId,
        this.selectedIds
      )

      if ((result as IApiErrors)?.errors) { return }

      this.closeModal()
      this.$emit('updateList')
    },
  },
})
</script>
