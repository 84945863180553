import { fetchExportFile } from './export.js'
import { ReportParamFactory } from '@/specific/reports/shared'
import ReportFiltersFactory from '@/specific/reports/ReportFiltersFactory'
import { createVueApp } from '@/vue_components/create_vue_app'
import CancellationsReportApp from '@/vue_components/reports/CancellationsReport/CancellationsReportApp.vue'

$document.on('rez/reports/cancellations', function () {
  Report.init()
  const tableHeader = [
    { title: t('title') },
    { title: t('amount') },
    { title: t('sum') },
    { title: t('cost_price') },
  ]

  const reportTable = createVueApp({
    el: '#report_right_container',
    name: 'CancellationsReportAppRoot',
    render: (h) => h(CancellationsReportApp),
    useChildren: true,
  })

  $('#report_submit').on('click', function () {
    reportTable.show()
    reportTable.updateFilters(getFilters())
  })

  $('#report_reset').on('click', () => {
    reportTable.hide()
  })

  $('.export').on('click', function () {
    const filters = getFilters()

    const data = {
      csvComma: gon.application.csv_character,
      floatingComma: gon.localization.currency.decimal_character,
      headerRow: {
        entryTypeTitle: tableHeader[0].title,
        entryTotalAmount: tableHeader[1].title,
        entryTotalFinalSum: tableHeader[2].title,
        entryTotalCostSum: tableHeader[3].title,
        entryMeasureUnitShortTitle: t('measureUnitShort'),
      },
      totalRow: {
        total: t('total'),
      },
      entryConsumable: filters.entryConsumable.id,
      offset: 0,
      limit: -(1 << 32 - 1),
      report: {
        period: filters.period,
      },
      ...ReportFiltersFactory.defineFor('cancellations', filters),
    }

    fetchExportFile(data, Routes.report_cancellations_path(), t('cancellations'))
  })
})

export const getFilters = () => {
  return {
    period: ReportParamFactory.getRawParam('.period'),
    clinic: ReportParamFactory.getSelectParam('#report_clinic_id'),
    store: ReportParamFactory.getSelectParam('#report_store_id'),
    entryConsumable: ReportParamFactory.getSelectParam('#report_consumable'),
  }
}
