import {
  ACQUIRING_STATUS_CODE,
  ACQUIRING_STATUS_CODE_TO_TITLE_MAP,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringStatus'
import { tScoped } from '@/helpers/i18nHelpers'
import { camelCase } from 'lodash'
import { ICatalog } from '@/_declarations/ICatalog'

const ts = tScoped('finance.transactionStatus')

export const STATUS_FILTER_ITEMS = Object.values(ACQUIRING_STATUS_CODE)
  .filter((value) => typeof value === 'number')
  .reduce((acc: Array<ICatalog<ACQUIRING_STATUS_CODE>>, acquiringStatusCode: ACQUIRING_STATUS_CODE) => ([
    ...acc,
    {
      id: acquiringStatusCode,
      title: ts(camelCase(ACQUIRING_STATUS_CODE_TO_TITLE_MAP[acquiringStatusCode])),
    },
  ]), [])
