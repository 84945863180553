import { NSIConfigurationLogic } from '@/vue_apps/app_configuration/NSI/entities/NSIConfigurationLogic'
import { Api } from '@/_api/decorators/api/Api'
import { Callback } from '@/_api/decorators/callback'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { IApiErrors } from '@/_declarations/IApiErrors'
import { adapters } from './NSIConfigurationApi/adapters'

export class NSIConfigurationApi extends NSIConfigurationLogic {
  @Callback('fillProps')
  @Api.notify('fetchMessage', 'nsiCatalogs', { success: false })
  @Api.get(Routes.api_internal_configurations_nsi_path)
  load () {
    return {} as ICRUDReturn as Promise<void | IApiErrors>
  }

  @Api.notify('submitMessage', 'nsiCatalogs')
  @Api.patch(Routes.app_configuration_path)
  @Api.serverAdapter(adapters)
  save () {
    return {
      routeArgs: [1],
      payload: {
        nsiUserKey: this.nsiUserKey,
        switchableDictionariesInfo: this.switchableDictionariesInfo,
      },
    } as ICRUDReturn as Promise<void | IApiErrors>
  }

  @Api.notify('addLoadQueueMessage', 'nsiCatalogs')
  @Api.post(Routes.api_internal_configurations_nsi_setup_dictionaries_path)
  setupDictionaries () {
    return {} as ICRUDReturn as Promise<{ requestId: string } | IApiErrors>
  }
}
