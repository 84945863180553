import { tScoped } from '@/helpers/i18nHelpers'
import { orNull } from '@/_declarations/orNull'

const ts = tScoped('enums.call.result')

const callResults = [
  ts('in_progress'),
  ts('appointment_added'),
  ts('refused'),
  ts('finished'),
  ts('appointment_confirmed'),
  ts('appointment_canceled'),
  ts('on_hold'),
]

export const getCallResultItem = (callResultId: orNull<number>) => {
  return callResultId
    ? callResults.at(callResultId - 1)
    : ''
}
