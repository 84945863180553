import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'
import { omit } from 'lodash'

export const getProtocolsSchema = () =>
  new MSiSchema()
    .addPagination()
    .addTableSchema(
      new MTableSchema({
        headers: {
          pdfGenerated: new MTableHeader().addFixedWidth('40px'),
          date: new MTableHeader().addFixedWidth('100px'),
          title: new MTableHeader(),
          draft: new MTableHeader().addFixedWidth('40px'),
          edit: new MTableHeader().addFixedWidth('40px'),
        },
      })
        .addDeleteIcon(Services.security.canManageEntryTemplate)
    )

export const getProtocolsListSchemaForEntries = () => {
  const schema = getProtocolsSchema()
  schema.tableSchema.addHeaders({
    ...omit(schema.tableSchema.headers, ['edit']),
    newTab: new MTableHeader().addFixedWidth('40px'),
  }, false)

  return schema
}
