import { createVueApp } from '@/vue_components/create_vue_app'
import ProtocolView from '@/vue_apps/ProtocolsApp/pages/ProtocolView.vue'
import { snakeToCamel } from '@/_api/_requests/helpers'

let vueApp = null
export const createMedicalRecordShowApp = (el = '#vue_medical_record_show_root') => {
  if (vueApp?.$destroy) { vueApp.$destroy() }
  if (gon.page.controller === 'medical_records' && gon.page.action === 'show' && gon.page.params.entry_id) { return }

  vueApp = createVueApp({
    el,
    name: 'MedicalRecordShowApp',
    render: (h) => h(ProtocolView, {
      props: {
        client: Object.freeze(snakeToCamel(gon.specific.client)),
        medicalRecord: Object.freeze(snakeToCamel(gon.specific.medical_record)),
      },
    }),
  })
}
