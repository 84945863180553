<template>
  <div class="medical-records-tabs-container__tab documents-tab">
    <m-si-generator
      :si-generator-schema="schema"
      :items="list.data"
      :page-count="list.totalPages"
      :current-page="list.currentPage"
      :nfr-text="t('no_medical_documents')"
      :search-query="list.searchQuery"
      filters-class="flex-column"
      use-context-menu
      @update:currentPage="list.setPage($event)"
      @onItemClick="onItemClick($event)"
      @onItemContextMenuClick="lastContextClickedItem = $event"
      @update:searchQuery="list.search($event)"
      @onCellClick="onCellClick($event)"
    >
      <template #contextMenu>
        <m-context-menu-new-tab
          v-if="lastContextClickedItem"
          :url="$routes.document_path(lastContextClickedItem.id, { anchor: DEFAULT_DOCUMENT_ID })"
        />
      </template>
    </m-si-generator>
  </div>
</template>

<script lang="ts">
import { getDateTitleHeadlessSchema } from '@/vue_present/_base/Tables/_schemaTemplates/getDateTitleHeadlessSchema'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import MContextMenuNewTab from '@/vue_present/_base/Tables/MContextMenuNewTab.vue'
import { DEFAULT_DOCUMENT_ID } from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/consts'
import { MEDICAL_DOCUMENT_ID } from '@/vue_apps/ClientsModule/components/Documents/consts/consts'
import { MListServiceApi } from '@/_api/_requests/MListServiceApi/MListServiceApi'
import {
  DocumentListItem,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/api/DocumentListItem'
import { TMSiGeneratorSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiGeneratorTypes'
import { ICatalog } from '@/_declarations/ICatalog'

export default {
  name: 'DocumentsTab',

  components: {
    MContextMenuNewTab,
    MSiGenerator,
  },

  props: {
    clientId: { type: Number, required: true },
    medicalRecordId: { type: Number, required: true },
  },

  data () {
    return {
      list: new MListServiceApi({
        src: Routes.list_api_internal_medical_records_documents_path(),
        DataItemEntity: DocumentListItem,
        defaultFilters: {
          clientId: this.clientId,
          kind: MEDICAL_DOCUMENT_ID,
        },
        requiredFilters: ['clientId'],
      }),

      schema: getDateTitleHeadlessSchema().addSearch() as TMSiGeneratorSchema,
      lastContextClickedItem: null as ICatalog,
      DEFAULT_DOCUMENT_ID,
    }
  },

  mounted () {
    this.list.fetchAll()
  },

  methods: {
    fetchCurrentList () {
      this.list.fetchAll()
    },

    onItemClick ({ id }) {
      Turbolinks.visit(Routes.document_path(id, { anchor: DEFAULT_DOCUMENT_ID }))
    },

    onCellClick ({ cell, item }: { edit: string; item: ICatalog }) {
      if (cell === 'edit') {
        Turbolinks.visit(Routes.edit_document_path(item.id, { anchor: DEFAULT_DOCUMENT_ID }))
      }
    },
  },
}
</script>
