<template>
  <div class="attachments-tabs-hyperlink-modal">
    <m-modal
      :visible="modalVisible"
      modal-title-icon="externalLink"
      :dialog-title="title"
      width="900px"
      @close="onCloseModal"
    >
      <div
        v-loading="loading"
        class="attachments-tabs-hyperlink-modal__body"
      >
        <m-prompt-notice :text="t$('notice')" />

        <!-- Описание -->
        <m-input
          :label="t('text_data')"
          :value="attachmentTabHyperlink.title"
          @input="attachmentTabHyperlink.setValue('title', $event)"
        />

        <!-- Адрес ссылки -->
        <m-input
          :label="t$('linkLabel')"
          :value="attachmentTabHyperlink.link"
          :required="urlValidator"
          validator-name="link"
          @registerValidator="onRegisterValidator"
          @input="attachmentTabHyperlink.setValue('link', $event)"
        />
      </div>

      <template #footer-right-action>
        <m-button-submit
          :mode="submitButtonMode"
          :disabled="loading"
          @click="onSubmit"
        />
      </template>
    </m-modal>

    <m-button
      icon="externalLink"
      type="success"
      :text="t$('attachHyperlink')"
      @click="openModal()"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { ModalMixin } from '@/vue_present/mixins/ModalMixins/ModalMixin'
import {
  AttachmentTabHyperlinkApi,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AttachmentsTab/classes/AttachmentTabHyperlinkApi'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import MPromptNotice from '@/vue_present/_base/MPromptNotice/MPromptNotice.vue'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import { MODES } from '@/vue_apps/catalogs_root/_catalog_base/const/const'
import { urlValidator } from '@/lib/validators/urlValidator'

export default defineComponent({
  name: 'AttachmentsTabHyperlinkModal',

  components: {
    MButtonSubmit,
    MPromptNotice,
    MButton,
    MInput,
    MModal,
  },

  mixins: [
    i18nScopeMixin,
    ModalMixin,
    ValidationParentMixin,
    SpinnerHolder,
  ],

  props: {
    clientId: { type: Number, required: true },
  },

  emits: [
    'updateHyperlinksList',
    'openModalWithFetch',
    'updateFilesCount',
  ],

  data () {
    return {
      attachmentTabHyperlink: new AttachmentTabHyperlinkApi(),
    }
  },

  computed: {
    isEdit (): boolean {
      return Boolean(this.attachmentTabHyperlink.id)
    },

    title () {
      return this.isEdit
        ? this.t$('editTitle')
        : this.t$('createTitle')
    },

    submitButtonMode () {
      return this.isEdit
        ? MODES.EDIT
        : MODES.CREATE
    },
  },

  created () {
    this.setI18nScope('medicalRecord.tab.hyperlink')
    this.attachmentTabHyperlink.setValue('clientId', this.clientId)
    this.$emit('openModalWithFetch', this.openModalWithFetch)
  },

  methods: {
    urlValidator,

    openModalWithFetch (id: number) {
      this.openModal()
      this.withSpinner(this.attachmentTabHyperlink.fetch(id))
    },

    onCloseModal () {
      this.closeModal()
      this.attachmentTabHyperlink.clear()
      this.resetValidations()
    },

    async onSubmit () {
      if (this.hasErrors()) { return }

      const { errors } = await this.withSpinnerSimple(this.attachmentTabHyperlink.submit())
      if (errors) { return }

      if (!this.isEdit) {
        this.$emit('updateFilesCount')
      }

      this.$emit('updateHyperlinksList')
      this.onCloseModal()
    },
  },
})
</script>
