export const REQUEST_KEY = 'analysis_laboratory'

export const buildAnalysisLaboratory = (laboratory) => ({
  title: laboratory.title,
  entriesExtraChargeType: laboratory.entriesExtraChargeType,
  entriesExtraChargeValue: +laboratory.entriesExtraChargeValue,
  barcodeTemplate: laboratory.barcodeTemplate,
  allowSameEntries: laboratory.allowSameEntries,
})

export const buildAuthInvitro = (item) => ({
  id: item.id,
  clinicId: item.clinicId,
  clinicIdentifier: item.clinicIdentifier,
  apiToken: item.apiToken,
  requestCostPrice: item.requestCostPrice,
  workplaceId: item.workplaceId,
})

export const buildAuthHelix = (item) => ({
  id: item.id,
  clinicId: item.clinicId,
  clinicIdentifier: item.clinicIdentifier,
  username: item.username,
  password: item.password,
  clientId: item.clientId,
  labellerIntegration: item.labellerIntegration,
  labellerId: item.labellerId,
  labellerPassword: item.labellerPassword,
  clientSecret: item.clientSecret,
  clientKey: item.clientKey,
  requestCostPrice: item.requestCostPrice,
})

export const buildAuthKdl = (item) => ({
  id: item.id,
  clinicId: item.clinicId,
  username: item.username,
  password: item.password,
  minLabelNumber: item.minLabelNumber,
  maxLabelNumber: item.maxLabelNumber,
  clinicIdentifier: item.clinicIdentifier,
  requestCostPrice: item.requestCostPrice,
})

export const invitroMapper = (laboratory) => {
  const analysisLaboratory = buildAnalysisLaboratory(laboratory)
  analysisLaboratory.authAnalysisLaboratoriesAttributes =
        Object.values(laboratory.authAnalysisLaboratories).map(buildAuthInvitro)

  return analysisLaboratory
}

export const helixMapper = (laboratory) => {
  const analysisLaboratory = buildAnalysisLaboratory(laboratory)
  analysisLaboratory.authAnalysisLaboratoriesAttributes =
        Object.values(laboratory.authAnalysisLaboratories).map(buildAuthHelix)

  return analysisLaboratory
}

export const kdlMapper = (laboratory) => {
  const analysisLaboratory = buildAnalysisLaboratory(laboratory)
  analysisLaboratory.authAnalysisLaboratoriesAttributes =
        Object.values(laboratory.authAnalysisLaboratories).map(buildAuthKdl)

  return analysisLaboratory
}
