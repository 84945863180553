import { IReport } from '@/_declarations/IReport'
import { IAcquiringReportData } from '@/vue_apps/ReportsModule/AcquiringReport/interfaces/IAcquiringReportData'
import { IAcquiringReportTotal } from '@/vue_apps/ReportsModule/AcquiringReport/interfaces/IAcquiringReportTotal'
import { AcquiringReportCells } from '@/vue_apps/ReportsModule/AcquiringReport/classes/AcquiringReportCells'
import { AcquiringReportTotalCell } from '@/vue_apps/ReportsModule/AcquiringReport/classes/AcquiringReportTotalCell'
import { DEFAULT_LIMIT } from '@/vue_components/store/modules/filters_base'
import { PAYER_TYPE } from '@/vue_present/Reuse/Lists/PayerSearchInput/consts/consts'
import { orUndefined } from '@/_medods_standart_library/msl'
import { IAcquiringReportFilters } from '@/vue_apps/ReportsModule/AcquiringReport/interfaces/IAcquiringReportFilters'
import { MAX_LIMIT } from '@/specific/reports/const'
import { tScoped } from '@/helpers/i18nHelpers'
import {
  ACQUIRING_OPERATION_TYPE,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringOperationType'
import { ACQUIRING_STATUS } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringStatus'
import { camelCase } from 'lodash'
import { DateHelpers } from '@/helpers/DateHelpers'

const ts = tScoped('finance')

export const acquiringReportAdapter = {
  toServer (data: IAcquiringReportFilters) {
    const period = DateHelpers.toBasePeriod(data.period, Utils.longDateFormat).join(' - ')

    return {
      clinicIds: orUndefined(data.clinic) && [data.clinic.id],
      workplaceIds: orUndefined(data.workplace) && [data.workplace.id],
      report: { period },
      paymentTypes: orUndefined(data.paymentType) && [data.paymentType.id],
      operationTypes: orUndefined(data.operationType) && [data.operationType.id],
      statuses: orUndefined(data.status) && [data.status.id],
      payerClientIds: data.payer?.payerType === PAYER_TYPE.CLIENT
        ? [data.payer.id]
        : undefined,
      payerCompanyIds: data.payer?.payerType === PAYER_TYPE.COMPANY
        ? [data.payer.id]
        : undefined,
      userIds: orUndefined(data.userFilter) && [data.userFilter.id],

      limit: data.limit,
      offset: data.offset,
      sorting: data.__sorting,
    }
  },

  toClient (payload: IReport<IAcquiringReportData, IAcquiringReportTotal>, limit = DEFAULT_LIMIT) {
    return {
      ...payload,
      data: payload.data.map((item) => new AcquiringReportCells(item.tradeAcquiringTransaction)),
      total: new AcquiringReportTotalCell(payload.total, payload.count),
      totalItems: payload.count,
      totalPages: Math.floor(payload.count / limit) + 1,
    }
  },

  toExport (data: IAcquiringReportFilters) {
    return {
      ...acquiringReportAdapter.toServer(data),
      offset: 0,
      limit: MAX_LIMIT,
      headerRow: {
        tradeAcquiringTransactionId: '№',
        tradeAcquiringTransactionAmount: t('sum'),
        tradeAcquiringTransactionStatus: t('status'),
        tradeAcquiringTransactionOperationType: ts('operationType'),
        tradeAcquiringTransactionPaymentType: ts('paymentKind'),
        tradeAcquiringTransactionReceiptPrinted: t('receipt_printed'),
        userFullName: t('employee'),
        paymentId: t('paymentNumber'),
        payerTitle: t('payer'),
        workplaceTitle: t('workplace'),
        completedByFullName: t('tradeAcquiring.completedByFullName'),
        tradeAcquiringTransactionCreatedAt: t('created_at'),
      },

      tradeAcquiringTransactionStatus: getTradeAcquiringTransactionStatus(),
      tradeAcquiringTransactionOperationType: getTradeAcquiringTransactionOperationType(),

      tradeAcquiringTransactionPaymentType: {
        byCard: ts('byCard'),
        bySBP: ts('bySBP'),
      },

      totalRow: { total: t('reports.total') },
    }
  },
}

const getTradeAcquiringTransactionStatus = () => {
  return Object.values(ACQUIRING_STATUS).reduce((acc, status) => {
    const camelizedStatus = camelCase(status)

    return {
      ...acc,
      [camelizedStatus]: ts(`transactionStatus.${camelizedStatus}`),
    }
  }, {})
}

const getTradeAcquiringTransactionOperationType = () => Object.values(ACQUIRING_OPERATION_TYPE)
  .reduce((acc, operationType) =>
    ({
      ...acc,
      [operationType]: ts(`operationTypes.${operationType}`),
    }), {})
