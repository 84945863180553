<template>
  <div class="med-card-print-modal">
    <m-modal
      :dialog-title="t('document_types')"
      :visible="modalVisible"
      modal-title-icon="copy"
      @close="closeModal()"
    >
      <div class="w-100">
        <m-alert
          :text="t('choose_document_template')"
          type="warning"
        />
        <m-si-generator
          :items="items"
          :si-generator-schema="schema"
          @onItemClick="$event.action()"
        />
      </div>
    </m-modal>

    <slot name="reference">
      <m-button
        type="dark"
        button-style="fill"
        :text="t('print_medical_record')"
        icon="print"
        size="mini"
        @click="openModal()"
      />
    </slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { ModalMixin } from '@/vue_present/mixins/ModalMixins/ModalMixin'
import MAlert from '@/vue_present/_base/MAlert/MAlert.vue'
import { getSchema } from '@/vue_apps/MedicalRecords/MedCardPrintModal/getSchema'
import { getFixedDocumentRows } from '@/vue_apps/MedicalRecords/MedCardPrintModal/getFixedDocumentRows'
import { IClient } from '@/_declarations/IClient'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import { TMSiGeneratorSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiGeneratorTypes'

export default defineComponent({
  name: 'MedCardPrintModal',

  components: {
    MSiGenerator,
    MAlert,
    MButton,
    MModal,
  },

  mixins: [ModalMixin],

  props: {
    client: { type: Object as PropType<IClient>, required: true },
    medicalRecordId: { type: Number, required: true },
  },

  data () {
    return {
      schema: getSchema() as TMSiGeneratorSchema,
      items: getFixedDocumentRows({
        clientId: this.client.id,
        medicalRecordId: this.medicalRecordId,
      }),
    }
  },
})
</script>
