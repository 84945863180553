import { IPayerSearchDTO } from '@/vue_present/Reuse/Lists/PayerSearchInput/interfaces/IPayerSearchDTO'
import { IPayerSearchClientDTO } from '@/vue_present/Reuse/Lists/PayerSearchInput/interfaces/IPayerSearchClientDTO'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { IPayerSearchCompanyDTO } from '@/vue_present/Reuse/Lists/PayerSearchInput/interfaces/IPayerSearchCompanyDTO'
import { isArray } from '@/helpers/typeofHelpers'
import { FORMAL_ADDRESS_ID, PAYER_TYPE } from '@/vue_present/Reuse/Lists/PayerSearchInput/consts/consts'

export const payerSearchAdapter = {
  toClient (data: IPayerSearchDTO) {
    return {
      ...data,

      clients: data.clients.map((client) => ({
        ...client,
        title: getClientTitle(client),
        payerType: PAYER_TYPE.CLIENT,
      })),

      companies: data.companies.map((company) => ({
        ...company,
        title: getCompanyTitle(company),
        payerType: PAYER_TYPE.COMPANY,
      })),
    }
  },
}

const getClientTitle = (client: IPayerSearchClientDTO) => {
  const fullName = vueFilters.fullName(client)
  const birthdate = client.birthdate
    ? Utils.getFormattedDate(client.birthdate)
    : null
  const phone = client.phone
    ? `+ ${Services.phoneMask.setMask(client.phone)}`
    : null

  return [fullName, birthdate, phone].filter(Boolean).join(', ')
}

const getCompanyTitle = (company: IPayerSearchCompanyDTO) => {
  const address = getCompanyAddress(company)

  return [company.title, company.individualTaxNumber, address].filter(Boolean).join(', ')
}

const getCompanyAddress = (company: IPayerSearchCompanyDTO): string | undefined => {
  if (!isArray(company.addresses)) return

  const address = company.addresses.find((address) => address.addressType === FORMAL_ADDRESS_ID)

  if (!address) return

  return [
    address.region,
    address.area,
    address.city,
    address.street,
    address.house ? `д ${address.house}` : '',
    address.flat ? `оф ${address.flat}` : '',
  ].filter(Boolean).join(', ')
}
