<template>
  <div
    v-if="showBiomaterialContainers"
    id="biomaterial_containers"
    class="panel panel-primary panel-inner"
    :class=" {
      'hidden-print': isKdl
    }"
  >
    <panel-heading
      :title="T.laboratories.biomaterial_containers"
      class="tooltip-bottom"
      icon="fad fa-vials"
      panel-heading-class="hidden-print-fixed"
    >
      <!--  Кнопки на панели контейнеров  -->
      <template #buttons>
        <m-button
          v-if="isKdl && resetContainersBtnEnabled && !orderIsSent"
          :text="t('laboratories.containersReset')"
          button-style="fill"
          type="dark"
          size="mini"
          @click="containersReset"
        />

        <div
          v-if="!isInvitro"
          class="dropdown workplaces-dropdown hidden-print"
        >
          <button
            id="workplaces-dropdown__button"
            class="btn btn-xs btn-grey-dark grey-dark dropdown-toggle"
            :class="{'vue-skeleton-rect': workplaceListIsLoading}"
            data-toggle="dropdown"
            type="button"
            aria-expanded="false"
          >
            <i class="fad fa-fw fa-rectangle-landscape index-list-icon" />
            <span>{{ workplaceSelectorText }}</span>
          </button>

          <ul
            aria-labelledby="workplaces-dropdown__button"
            class="dropdown-menu dropdown-menu-right grey-dark-theme"
            role="menu"
          >
            <template v-if="workplaceListIsLoading">
              <li
                v-for="index in [0, 1, 2, 3]"
                :key="index"
                class="workplaces-dropdown__menu-item"
              >
                <skeleton
                  width="140px"
                  height="14px"
                />
              </li>
            </template>
            <template v-else>
              <li
                v-for="workplace in workplaceList"
                :key="workplace.id"
                class="workplaces-dropdown__menu-item ellipsis"
                @click="onWorkplaceItemClick(workplace)"
              >
                {{ workplace.title }}
              </li>
            </template>
          </ul>
        </div>

        <button
          class="btn btn-grey-dark btn-xs hidden-print"
          :disabled="printBarcodeBtnDisabled"
          @click="$emit('print-barcode', undefined, currentWorkplaceId)"
        >
          <i class="fad fa-print" />
          <span>{{ T.laboratories.print_barcodes }}</span>
        </button>

        <div
          v-if="isHelix"
          v-tooltip="labellerDisabledMap.tooltip"
          class="inline-block"
        >
          <button
            class="btn btn-grey-dark btn-xs hidden-print"
            :disabled="labellerDisabledMap.disabled"
            @click="$emit('print-labeller')"
          >
            <i class="fad fa-print" />
            <span>{{ t('laboratories.send_to_labeller') }}</span>
          </button>
        </div>
      </template>

      <!--  Табличка с контейнерами  -->
      <div id="containers_container">
        <div id="containers_table">
          <div id="containers_table_header">
            <div class="container_row">
              <div
                v-if="showContainerIconColumn"
                class="icon hidden-print"
              >
                {{ T.icon }}
              </div>
              <div
                class="number"
              >
                {{ T.code }}
              </div>
              <div class="title">
                {{ T.title }}
              </div>
              <div class="biomaterial">
                {{ T.material }}
              </div>
              <div
                v-if="showContainerQuantityColumn"
                class="amount"
              >
                {{ T.amount }}
              </div>
              <div
                v-if="orderIsNotReady && !isKdl"
                class="entries"
              >
                {{ T.services }}
              </div>
              <div class="barcode hidden-print">
                {{ T.laboratories.barcode }}
              </div>

              <div
                v-if="!isKdl"
                v-tooltip="t('labComment')"
                class="comment hidden-print"
              >
                <m-icon
                  icon="comment"
                  color="primary"
                />
              </div>

              <div class="options-container hidden-print">
                <div class="options" />
                <div class="options" />
              </div>
            </div>
          </div>
          <div id="containers_table_body">
            <div
              v-for="biomaterialContainer in biomaterialContainers"
              :key="biomaterialContainer.id"
              class="container_row"
            >
              <div
                v-if="showContainerIconColumn"
                class="icon hidden-print"
              >
                <biomaterial-container-icon
                  :container-code="biomaterialContainer.code"
                  :analysis-laboratory-system-name="analysisLaboratorySystemName"
                />
              </div>
              <div
                class="number tooltip-bottom cut"
                :title="biomaterialContainer.code"
              >
                {{ biomaterialContainer.code }}
              </div>
              <div
                class="title cut tooltip-bottom"
                :title="biomaterialContainer.title"
              >
                {{ biomaterialContainer.title }}
              </div>
              <div
                class="biomaterial cut tooltip-bottom"
                :title="biomaterialContainer.biomaterialTitle"
              >
                {{ biomaterialContainer.biomaterialTitles.join(', ') }}
              </div>
              <div
                v-if="showContainerQuantityColumn"
                class="amount"
              >
                {{ biomaterialContainer.quantity }}
              </div>
              <div
                v-if="orderIsNotReady && !isKdl"
                class="entries"
              >
                <el-select
                  v-model="biomaterialContainer.entryIds"
                  class="hidden-print"
                  :disabled="entrySelectorDisabled"
                  multiple
                  collapse-tags
                  :placeholder="T.laboratories.select_entry_type"
                  @change="$emit('entry-ids-changed', biomaterialContainer)"
                >
                  <el-option
                    v-for="entry in entries"
                    :key="entry.id"
                    :label="entry.title"
                    :value="entry.id"
                  >
                    <span
                      class="entry-select-popup cut tooltip-bottom"
                      :title="entry.title"
                    >
                      {{ entry.title }}
                    </span>
                  </el-option>
                </el-select>

                <span class="showed-print">
                  {{ entriesTitles }}
                </span>
              </div>
              <div class="barcode hidden-print">
                <template v-if="barcodeEditable">
                  <el-input
                    v-model="biomaterialContainer.label"
                    :disabled="barcodeEditInputDisabled"
                    :class="[biomaterialContainer.errors && biomaterialContainer.errors.length ? 'error' : '']"
                    clearable
                    :placeholder="T.laboratories.barcode"
                    @change="$emit('validate-barcode', biomaterialContainer)"
                  />
                </template>
                <template v-else>
                  {{ biomaterialContainer.label }}
                </template>
              </div>

              <div
                v-if="!isKdl"
                class="comment cut hidden-print"
              >
                <span
                  v-if="biomaterialContainer.comment"
                  v-tooltip="biomaterialContainer.comment"
                >
                  <m-icon
                    icon="comment"
                    color="primary"
                  />
                </span>
              </div>

              <div class="options-container hidden-print">
                <div
                  v-if="!isKdl"
                  class="options"
                >
                  <button
                    class="btn btn-grey-dark btn-xs tooltip-bottom"
                    :title="T.laboratories.show_processing"
                    @click="showProcessingInfo(biomaterialContainer.processing)"
                  >
                    <span class="fad fa-info-circle" />
                  </button>
                </div>
                <div class="options">
                  <biomaterial-print-list
                    :use-labeller="isHelix"
                    :print-barcode-btn-disabled="printBarcodeBtnDisabled"
                    :print-labeller-btn-disabled-map="labellerDisabledMap"
                    @print-barcode="$emit('print-barcode', biomaterialContainer.id, currentWorkplaceId)"
                    @print-labeller="$emit('print-labeller', biomaterialContainer.id, currentWorkplaceId)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <modal
        :modal-visibility="modalVisible"
        @close="closeModal"
      >
        <template #header>
          {{ T.laboratories.processing }}
        </template>

        <template #body>
          <div id="processing_info">
            {{ modalContent }}
          </div>
        </template>

        <template #footer-right>
          <el-button
            :class="['btn', 'btn-primary', 'btn-sm']"
            @click="closeModal"
          >
            {{ T.close }}
          </el-button>
        </template>
      </modal>
    </panel-heading>
  </div>
</template>
<script>
import PanelHeading from '@/vue_components/common/panel_heading.vue'
import BiomaterialContainerIcon from '@/vue_components/analysis_orders/components/BiomaterialContainerIcon'
import Modal from '../../modal.vue'
import Skeleton from '@/vue_components/skeleton'
import { ANALYSIS_ORDER_STATES, LABORATORY_SYSTEM_NAMES } from '../constants.js'
import BiomaterialPrintList
  from '@/vue_components/analysis_orders/components/BiomaterialContainers/BiomaterialPrintList'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default {
  name: 'BiomaterialContainers',
  components: {
    MIcon,
    MButton,
    BiomaterialPrintList,
    PanelHeading,
    Modal,
    Skeleton,
    BiomaterialContainerIcon,
  },
  props: {
    biomaterialContainers: {
      default: undefined,
      type: Array,
    },

    containersDates: {
      default: undefined,
      type: Array,
    },

    barcodeEditable: Boolean,

    entries: {
      default: undefined,
      type: Array,
    },

    analysisOrderState: {
      default: null,
      type: Number,
    },

    showEntrySelector: {
      default: true,
      type: Boolean,
    },

    workplaceId: {
      type: Number,
      default: null,
    },

    workplaceList: {
      type: Array,
      default: null,
    },

    workplaceListIsLoading: {
      type: Boolean,
      default: true,
    },

    analysisLaboratorySystemName: {
      type: String,
      default: null,
    },

    labellerConnected: Boolean,
    labellerIntegrationEnabled: Boolean,

    resetContainersBtnEnabled: Boolean,
  },

  data () {
    return {
      modalVisible: false,
      modalContent: '',
      currentWorkplaceId: null,
      workplaceSelectorText: '',
      commentLabs: 'comment',
    }
  },

  computed: {
    fullPermissions () {
      return (gon.application.security.current_user_permissions.all || [])
        .includes(Utils.ACTIONS.MANAGE)
    },

    readPermission () {
      if (this.fullPermissions) return true

      return gon
        .application
        .security
        .current_user_permissions
        .AnalysisOrder
        .includes('View')
    },

    writePermission () {
      if (this.fullPermissions) return true

      return gon
        .application
        .security
        .current_user_permissions
        .AnalysisOrder
        .includes('Manage')
    },

    barcodeEditInputDisabled () {
      return !this.writePermission
    },

    entrySelectorDisabled () {
      return !this.showEntrySelector && !this.writePermission
    },

    printBarcodeBtnDisabled () {
      if (!this.writePermission) { return true }

      return this.workplaceListIsLoading || !this.currentWorkplaceId
    },

    orderIsSent () {
      return this.analysisOrderState !== ANALYSIS_ORDER_STATES.PREPARED
    },

    orderIsNotReady () {
      return this.analysisOrderState !== ANALYSIS_ORDER_STATES.READY
    },

    showBiomaterialContainers () {
      if (!this.biomaterialContainers) return false

      return this.biomaterialContainers.length !== 0
    },

    showContainerQuantityColumn () {
      if (!this.biomaterialContainers) return false

      return this.biomaterialContainers.some((container) => container.quantity !== null)
    },

    showContainerIconColumn () {
      if (!this.biomaterialContainers) return false

      return this.biomaterialContainers.some((container) => container.icon !== null)
    },

    entriesTitles () {
      return (this.entries || []).map((entry) => entry.title).join(', ')
    },

    labellerDisabledMap () {
      if (!this.labellerIntegrationEnabled) {
        return {
          disabled: true,
          tooltip: t('laboratories.labeller_status.disabled'),
        }
      }

      if (!this.labellerConnected) {
        return {
          disabled: true,
          tooltip: t('laboratories.labeller_status.disconnected'),
        }
      }

      return { disabled: false, tooltip: '' }
    },

    isInvitro () {
      return this.analysisLaboratorySystemName === LABORATORY_SYSTEM_NAMES.INVITRO
    },

    isHelix () {
      return this.analysisLaboratorySystemName === LABORATORY_SYSTEM_NAMES.HELIX
    },

    isKdl () {
      return this.analysisLaboratorySystemName === LABORATORY_SYSTEM_NAMES.KDL ||
      this.analysisLaboratorySystemName === LABORATORY_SYSTEM_NAMES.KDL_CHECKUPS
    },
  },

  watch: {
    workplaceId (newVal) {
      this.currentWorkplaceId = newVal
    },

    workplaceList (newList) {
      if (newList) {
        const workplace = newList.find((item) => (item.id === this.currentWorkplaceId))
        this.onWorkplaceItemClick(workplace)
      }
    },
  },

  mounted () {
    this.workplaceSelectorText = T.laboratories.select_workplace
  },

  methods: {
    biomaterialArray (entry) {
      if (entry.biomaterialSets === undefined) return entry.biomaterials

      const arrayFromSets = []
      entry.biomaterialSets.forEach((set) => {
        set.biomaterials.forEach((biomaterial) => arrayFromSets.push(biomaterial))
      })

      return arrayFromSets
    },

    showProcessingInfo (processing) {
      this.modalVisible = true
      this.modalContent = processing.trim()
    },

    closeModal () {
      this.modalVisible = false
      this.modalContent = ''
    },

    containersReset () {
      this.$emit('containersReset')
    },

    /**
     * Обновление текста кнопки
     * Обновление currentWorkplaceId
     * @param workplace {Object}
     */
    onWorkplaceItemClick (workplace = { id: null, title: '' }) {
      this.workplaceSelectorText = workplace.title || T.laboratories.select_workplace
      this.currentWorkplaceId = workplace.id
    },
  },
}
</script>
