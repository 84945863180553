export default {
  data () {
    return {
      isHidden: true,
    }
  },

  methods: {
    updateFilters (filters) {
      this.filters = filters
      // use `$nextTick` here to wait for props to update
      this.$nextTick(() => {
        this.$refs.report.loadData()
      })
    },

    show () {
      this.isHidden = false
    },

    hide () {
      this.isHidden = true
      this.$refs.report.clear()
    },
  },
}
