import { MAX_LIMIT } from '@/specific/reports/const'
import { tScoped } from '@/helpers/i18nHelpers'
import { DEFAULT_LIMIT } from '@/vue_components/store/modules/filters_base'
import {
  TaxCertificateReportItemCells,
} from '@/vue_apps/ReportsModule/TaxSertificatesReport/classes/TaxCertificateReportItemCells'
import {
  TaxCertificateTotalItemCells,
} from '@/vue_apps/ReportsModule/TaxSertificatesReport/classes/TaxCertificateTotalItemCells'
import {
  ITaxCertificatesReportResponse,
} from '@/vue_apps/ReportsModule/TaxSertificatesReport/interfaces/ITaxCertificatesReportResponse'
import { DateHelpers } from '@/helpers/DateHelpers'

const ts = tScoped('taxCertificatesReport.tableHeaders')

export const taxCertificatesReportAdapter = {
  toServer (data) {
    const period = DateHelpers.toBasePeriod(data.period, Utils.longDateFormat).join(' - ')
    const xmlFileName = !data.xmlFileName?.id
      ? undefined
      : data.xmlFileName?.id === 'true'

    return {
      clinicIds: data.clinic?.id && [data.clinic.id],
      documentKind: data.documentKind?.id && [+data.documentKind.id],
      userIds: data.user?.id && [data.user.id],
      xmlFileName,

      report: { period },
      limit: data.limit,
      offset: data.offset,
      sorting: data.__sorting,
    }
  },

  toClient (response: ITaxCertificatesReportResponse) {
    return {
      totalItems: response.total.amount,
      totalPages: Math.ceil(response.total.amount / DEFAULT_LIMIT),
      total: new TaxCertificateTotalItemCells(response.total),
      data: response.data.map((row) =>
        new TaxCertificateReportItemCells(row.certificate)
      ),
    }
  },

  toExport (data) {
    return {
      ...taxCertificatesReportAdapter.toServer(data),
      offset: 0,
      limit: MAX_LIMIT,
      headerRow: {
        certificateId: ts('numberCell'),
        certificateNumber: ts('referenceNumber'),
        correctionNumber: ts('correctionNumberTooltip'),
        payerFullName: ts('payerFullName'),
        payerInn: ts('payerInn'),
        payerBirthDate: ts('payerBirthDateTooltip'),
        payerDocumentKind: ts('payerDocumentTypeCodeTooltip'),
        payerSeriesNumber: ts('payerDocumentSeriesNumber'),
        payerIssueDate: ts('payerDocumentIssueDate'),
        mark: ts('sign'),
        clientFullName: ts('patientFullName'),
        clientInn: ts('patientInn'),
        clientBirthdate: ts('patientBirthdateTooltip'),
        clientDocumentKind: ts('patientDocumentTypeCodeTooltip'),
        clientSeriesNumber: ts('patientDocumentSeriesNumber'),
        clientIssueDate: ts('patientDocumentIssueDate'),
        paymentSumOne: ts('amountByServiceCode1'),
        paymentSumTwo: ts('amountByServiceCode2'),
        authenticatorFullName: ts('issuerOfCertificate'),
        certificateDate: ts('dateTooltip'),
        certificateXmlFileName: ts('status'),
        certificateAmount: ts('amount'),
      },
      totalRow: {
        total: t('total'),
      },
    }
  },
}
