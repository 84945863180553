<template>
  <div id="analysis_order_form">
    <div
      id="analysis_order_laboratory_form"
      v-loading="loading"
    >
      <div class="col-md-9">
        <div id="analysis_order_area">
          <div id="analysis_order_info">
            <table
              id="client_info_table"
              class="show_info_table show_info_table_fix"
            >
              <!-- Селектор лабораторий -->
              <tr>
                <td class="show_info_td">
                  {{ T.analysis_laboratory }}
                </td>
                <td class="show_info_td">
                  <el-select
                    ref="laboratorySelect"
                    v-model="selectedLaboratoryIndex"
                    :disabled="isEditMode"
                    :placeholder="T.laboratories.select_laboratory"
                    filterable
                    @change="handleLaboratoryChange"
                  >
                    <el-option
                      v-for="(laboratory, index) in laboratories"
                      :key="index"
                      :label="laboratory.title"
                      :value="index"
                    />
                  </el-select>
                </td>
              </tr>

              <!-- Информация о пациенте -->
              <tr>
                <td class="show_info_td">
                  {{ T.patient }}
                </td>
                <td class="show_info_td">
                  {{ clientFullName }}
                  <span
                    v-if="client.id"
                    class="hidden-print"
                  >
                    /
                    <a
                      :href="`/clients/${client.id}`"
                      target="_blank"
                    >
                      <span class="fad fa-link" />
                      {{ T.infocard }}
                    </a>
                  </span>
                  <span
                    v-if="client.medicalRecordId"
                    class="hidden-print"
                  >
                    /
                    <a
                      :href="`/medical_records/${client.medicalRecordId }`"
                      target="_blank"
                    >
                      <span class="fad fa-link" />
                      {{ T.medcard }}
                    </a>
                  </span>
                </td>
              </tr>

              <!--Телефон пациента-->
              <tr>
                <td class="show_info_td">
                  {{ T.client_phone }}
                </td>
                <td class="show_info_td">
                  <span
                    class="phone_number"
                    :data-client-id="client.id"
                  >
                    {{ clientPhone }}
                  </span>
                </td>
              </tr>

              <!-- Чекбоксы для уведомлений -->
              <tr>
                <td class="show_info_td">
                  {{ T.laboratories.ready_notification }}
                </td>
                <td class="show_info_td">
                  <div
                    v-for="(value, key) in notificationTypes"
                    :key="key"
                    class="checkbox_container"
                  >
                    <m-checkbox
                      v-model="notificationTypes[key]"
                      :label="t(`laboratories.notification_types.${toSnakeCase(key)}`)"
                    />
                  </div>
                </td>
              </tr>

              <!-- Комментарий к заказу для Инвитро -->
              <tr v-if="isInvitro">
                <td class="show_info_td">
                  {{ T.laboratories.comment }}
                </td>
                <td class="show_info_td">
                  <el-input
                    v-model="comment"
                    autosize
                    :placeholder="T.comment"
                  />
                </td>
              </tr>

              <!-- Поля для анализов с ковидом -->
              <tr v-if="covidOrder">
                <td class="show_info_td">
                  {{ T.laboratories.location }}
                  <abbr
                    class="required tooltip-bottom"
                    :title="T.laboratories.required"
                  > * </abbr>
                </td>
                <td class="show_info_td">
                  <el-input
                    v-model="location"
                    autosize
                    :placeholder="T.laboratories.location"
                  />
                </td>
              </tr>
            </table>
          </div>

          <div
            v-if="isKdl"
            class="kdl-entries-clarification"
          >
            {{ t('laboratories.kdlEntriesClarification') }}
          </div>

          <!-- Выбор/поиск услуги -->
          <entry-type-selector
            :search-method="searchEntries"
            :search-disabled="searchDisabled"
            @entry-type-selected="addSelectedEntry"
          />

          <!-- Список выбранных услуг -->
          <entries-container
            :is-kdl="isKdl"
            :entries="entries"
            :is-form="true"
            :error-entries-numbers="errorEntriesNumbers"
            @registerValidator="onRegisterValidator"
          />

          <!-- Кнопоньки -->
          <span
            class="btn btn-primary btn-with-icon"
            @click="prepareDataForSubmit"
          >
            <span class="btn-with-icon_icon fad fa-save" />
            <span class="btn-with-icon_text">{{ t('save') }}</span>
          </span>

          <a
            id="analysis_order_new_back"
            class="btn btn-primary btn-xs hidden-print btn-back js_redirect_back"
            :href="isEditMode ? '/#analysis_order_edit' : '/#analysis_order'"
          >
            {{ T.back }}
          </a>
        </div>
      </div>
      <div class="col-md-3">
        <entry-type-tree
          :key="treeKey"
          v-loading="treeLoading"
          :load-node="buildTree"
          :empty-text="treeEmptyText"
          :panel-title="T.laboratories.price"
          @entry-type-selected="addSelectedEntry"
        >
          <template
            v-if="orderId"
            #mode-select
          >
            <el-radio-group
              v-model="treeMode"
              size="mini"
              :disabled="treeLoading"
            >
              <el-radio-button label="price">
                {{ T.laboratories.from_price }}
              </el-radio-button>

              <el-radio-button label="order">
                {{ T.laboratories.from_order }}
              </el-radio-button>
            </el-radio-group>
          </template>
        </entry-type-tree>
      </div>
    </div>

    <select-joined-entries-modal
      :modal-visible="joinedEntriesModalVisible"
      :selected-entry="selectedEntryForJoinedEntriesModal"
      @close-modal="hideJoinedEntriesModal"
      @add-selected-entry="addSelectedEntry"
    />
  </div>
</template>
<script>
import EntryTypeSelector from './components/entry_type_selector'
import EntriesContainer from './components/entries_container'
import EntryTypeTree from './components/entry_type_tree'
import SelectJoinedEntriesModal from './components/select_joined_entries_modal.vue'

import {
  ANALYSIS_ORDER_PUBSUB_PREFIX,
  ANALYSIS_ORDER_STATES,
  EVENTS,
  LABORATORY_SYSTEM_NAMES,
  TREE_MODES,
} from './constants.js'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'
import { cloneDeep, parseInt } from 'lodash'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { showOrderErrorNotification } from '@/vue_components/analysis_orders/const/const'
import { getEntryTaxSchemeOrDefault } from '@/vue_components/analysis_orders/logic'

export default {
  name: 'AnalysisOrderForm',
  components: {
    MCheckbox,
    EntryTypeSelector,
    EntriesContainer,
    EntryTypeTree,
    SelectJoinedEntriesModal,
  },

  mixins: [ValidationParentMixin],

  props: {
    analysisOrderId: {
      default: null,
      type: String,
    },
    clientId: {
      default: null,
      type: String,
    },
    orderId: {
      default: null,
      type: String,
    },
    wsHandler: {
      default: null,
      type: Object,
    },
  },

  data () {
    return {
      notificationTypes: [],
      laboratories: [],
      comment: '',
      location: '',
      searchCallback: undefined,
      requestsInProgress: [],
      selectedLaboratoryIndex: null,
      entries: [],
      treeEmptyText: '',
      treeLoading: false,
      client: {},
      availabilityInfo: {},
      selectedEntryForJoinedEntriesModal: {},
      treeMode: TREE_MODES.PRICE,
      joinedEntriesModalVisible: false,
      uniqueComponentId: Math.random().toString().match(/\d+/g).pop(),
      price: {
        [TREE_MODES.PRICE]: {},
        [TREE_MODES.ORDER]: {},
      },
      treeCallback: {
        [TREE_MODES.PRICE]: {},
        [TREE_MODES.ORDER]: {},
      },
      covidCodes: [],
      loading: false,
      errorEntriesNumbers: [],
    }
  },

  computed: {
    isInvitro () {
      return this.selectedLaboratory.systemName === LABORATORY_SYSTEM_NAMES.INVITRO
    },

    isKdl () {
      return this.selectedLaboratory.systemName === LABORATORY_SYSTEM_NAMES.KDL ||
          this.selectedLaboratory.systemName === LABORATORY_SYSTEM_NAMES.KDL_CHECKUPS
    },

    covidOrder () {
      return this.isInvitro && this.entries.some((entry) => this.covidCodes.includes(entry.number))
    },

    isEditMode () {
      return Boolean(this.analysisOrderId)
    },

    clientFullName () {
      const client = this.client
      if (client.surname) {
        return [client.surname, client.name, client.secondName].join(' ')
      }

      return ''
    },

    clientPhone () {
      if (!this.client.phone) return ''

      return `+${Services.phoneMask.setMask(this.client.phone)}`
    },

    selectedLaboratory () {
      if (this.selectedLaboratoryIndex !== null) {
        return this.laboratories[this.selectedLaboratoryIndex]
      }

      return {}
    },

    treeKey () {
      return `${this.selectedLaboratory.id}_${this.treeMode}`
    },

    searchDisabled () {
      return this.selectedLaboratoryIndex === null
    },
  },

  watch: {
    requestsInProgress: {
      handler (value) {
        this.treeLoading = value.includes(EVENTS.BUILD_TREE)

        this.treeEmptyText = this.getTreeEmptyText()
      },
    },
  },

  created () {
    this.lockPage()
    this.subscribeToEvents()
  },

  updated () {
    this.$nextTick(Services.telephony.reset)
  },

  methods: {
    getTreeEmptyText () {
      if (this.selectedLaboratoryIndex === null) {
        return T.laboratories.no_entry_type_tree_data
      }

      if (this.price[this.treeMode][this.selectedLaboratory.systemName] === undefined) {
        return
      }

      if (this.treeLoading || this.price[this.treeMode][this.selectedLaboratory.systemName].length > 0) {
        return T.laboratories.tree_loading
      }

      if (this.treeMode === TREE_MODES.PRICE) {
        return T.laboratories.there_is_no_price
      }

      return T.laboratories.there_is_no_laboratory_entries_in_order
    },

    hideJoinedEntriesModal () {
      this.joinedEntriesModalVisible = false
    },

    showJoinedEntriesModal () {
      this.joinedEntriesModalVisible = true
    },

    lockPage () {
      this.loading = true
    },

    unlockPage () {
      this.loading = false
    },

    searchEntries (queryString, callback) {
      this.searchCallback = callback

      this.sendMessage(EVENTS.SEARCH_ENTRY_TYPES, {
        laboratory: this.selectedLaboratory,
        query: queryString,
      })
    },

    buildTree (node, callback) {
      this.treeCallback[this.treeMode][node.id] = callback

      if (node.level !== 0) {
        this.fetchTree(node.id, node.data.id)
      } else {
        this.fetchTree(node.id)
      }
    },

    fetchTree (nodeId, categoryId = undefined) {
      if (this.selectedLaboratoryIndex !== null) {
        this.sendMessage(EVENTS.BUILD_TREE, {
          laboratory: this.selectedLaboratory,
          tree_mode: this.treeMode,
          order_id: this.orderId,
          category_id: categoryId,
          node_id: nodeId,
        })
      }
    },

    handleLaboratoryChange () {
      if (!this.isEditMode) {
        this.resetForm()

        this.sendMessage(EVENTS.FETCH_NOTIFICATION_DEFAULTS, {
          laboratory: this.selectedLaboratory,
        })
      }

      this.loadCovidCodes()
      this.getEtaStatus()
    },

    resetForm () {
      this.entries = []
    },

    addSelectedEntry (entryType, force = false) {
      if (this.availabilityInfo[entryType.number] !== undefined) {
        if (this.availabilityInfo[entryType.number] === 'stopped') {
          Notificator.error(T.laboratories.errors.unavailable)

          return
        }

        Notificator.info(`${T.laboratories.delay} ${this.availabilityInfo[entryType.number].replace('h', 'ч')}`)
      }

      if (this.entries.find((entry) => entry.id === entryType.id)) {
        if (!(this.selectedLaboratory.allowSameEntries && entryType.allowMultiple) ||
            this.entries.find(
              (entry) => (entryType.entryId !== undefined) &&
                       (entry.entryId === entryType.entryId)
            )
        ) {
          Notificator.info(T.laboratories.already_added)

          return
        }
      }

      if (entryType.memberIds.length > 0 && !force) {
        this.selectedEntryForJoinedEntriesModal = entryType

        this.sendMessage(EVENTS.SEARCH_ENTRY_TYPES_BY_ID, {
          laboratory: this.selectedLaboratory,
          entry_id: entryType.memberIds,
        })

        this.showJoinedEntriesModal()
      } else {
        this.$set(entryType, 'selectedBiomaterials', [])
        this.$set(entryType, 'selectedBiomaterialSets', {})
        this.$set(entryType, 'multiselectLimit', 1)
        this.$set(entryType, 'samplingDate', new Date())

        if (entryType.biomaterials && entryType.multipleBiomaterials) {
          entryType.multiselectLimit =
              entryType.biomaterials.length > 1 ? entryType.biomaterials.length : entryType.multiselectLimit
        }

        this.entries.push(entryType)
      }
    },

    hasDuplicateBioMaterialTypes (entries) {
      const uniqueKinds = new Set()
      const duplicateKinds = new Set()

      for (const entry of entries) {
        const kindInCurrentEntry = new Set()
        const biomaterials = entry.biomaterial_requirements || []

        for (const biomaterial of biomaterials) {
          const kind = biomaterial.kind

          if (kind) {
            if (uniqueKinds.has(kind) && !kindInCurrentEntry.has(kind)) {
              duplicateKinds.add(kind)
            }

            uniqueKinds.add(kind)
            kindInCurrentEntry.add(kind)
          }
        }
      }

      const duplicates = Array.from(duplicateKinds)
      const duplicateEntries = entries.filter((entry) =>
        entry.biomaterial_requirements.some(
          (biomaterial) => duplicates.includes(biomaterial.kind)
        )
      )

      return {
        hasDuplicates: duplicates.length > 0,
        duplicateKinds: duplicates,
        duplicateEntries: duplicates.length > 0 ? duplicateEntries : null,
      }
    },

    submitForm (formData) {
      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.SAVE_SUCCESSFUL}`, (data) => {
        this.unlockPage()

        Notificator.success(T.laboratories.order_created)

        Turbolinks.visit(Routes.analysis_order_path(data.result.analysis_order_id))

        this.$pubSub.resetScope(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.SAVE_SUCCESSFUL}`)
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.SAVE_ERROR}`, (data) => {
        this.unlockPage()

        Notificator.error(data.error)

        this.$pubSub.resetScope(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.SAVE_ERROR}`)
      })

      this.sendMessage(EVENTS.SAVE_ANALYSIS_ORDER, {
        analysis_order: formData,
      })
    },

    prepareDataForSubmit () {
      this.lockPage()
      const currentDate = new Date()

      const data = {
        client_id: this.client.id,
        analysis_laboratory_id: this.selectedLaboratory.id,
        date: this.isEditMode ? this.analysisOrder.date : currentDate.toISOString(),
        order_id: parseInt(this.orderId) || null,
        state: ANALYSIS_ORDER_STATES.PREPARED,
        user_id: gon.application.current_user.id,
        comment: this.comment.trim(),
        location: this.location.trim(),
        entries: this.parseEntriesForSubmit(),
        clinic_id: gon.application.current_clinic.id,
        id: this.isEditMode ? this.analysisOrderId : undefined,
      }

      Object.keys(this.notificationTypes).forEach((notificationType) => {
        data[this.toSnakeCase(notificationType)] = this.notificationTypes[notificationType]
      })

      if (this.validateForm(data)) this.submitForm(data)
      this.unlockPage()
    },

    validateForm (data) {
      try {
        if (data.entries.length === 0) {
          throw new Error(T.laboratories.errors.entries_should_be_present)
        }

        if (this.covidOrder) {
          if (!data.location.length) {
            throw new Error(T.laboratories.location_need_to_be_filled)
          }
        }

        if (this.isKdl && this.hasDuplicateBioMaterialTypes(data.entries).hasDuplicates) {
          (this.hasDuplicateBioMaterialTypes(data.entries).duplicateEntries || [])
            .forEach((entry) => {
              const entryId = entry.entry_type_id ? entry.entry_type_id : entry.id
              this.resetValidations({
                [`biomaterial_${entryId}`]: [t('laboratories.duplicateBiomaterialKinds')],
              })
            })

          throw new Error(t('laboratories.onlyOneBiomaterialKind'))
        }

        data.entries.forEach((entry) => {
          if (entry.biomaterial_sets) {
            entry.biomaterial_sets.forEach((biomaterialSet) => {
              if (biomaterialSet.biomaterial_requirements.length === 0) {
                throw new Error(T.laboratories.errors.biomaterial_set_should_be_present)
              }
            })
          } else {
            if (entry.biomaterial_requirements.length === 0) {
              this.resetValidations({
                [`biomaterial_${entry.entry_type_id}`]: [t('laboratories.errors.enterBiomaterials')],
              })
              throw new Error(T.laboratories.errors.biomaterial_set_should_be_present)
            }
          }
        })
      } catch (e) {
        Notificator.error(e.message)
        this.$pubSub.emitAsync(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.SHOW_ERRORS}`)

        return false
      }

      return true
    },

    parseEntriesForSubmit () {
      switch (this.selectedLaboratory.systemName) {
        case LABORATORY_SYSTEM_NAMES.INVITRO:
          return this.parseEntriesForSubmitWithSets()
        default:
          return this.parseEntriesForSubmitDefault()
      }
    },

    parseEntriesForSubmitWithSets () {
      return this.entries.map((entry) => {
        const parsedEntry = {
          analysis_laboratory_id: this.selectedLaboratory.id,
          ext_laboratory_id: entry.extLaboratoryId,
          biomaterial_sets: Object.keys(entry.selectedBiomaterialSets).map((biomaterialSetKey) => {
            return {
              external_laboratory_id: biomaterialSetKey,
              biomaterial_requirements: entry.selectedBiomaterialSets[biomaterialSetKey].map((biomaterialId) => {
                return {
                  biomaterial_id: biomaterialId,
                  required: true,
                }
              }),
            }
          }),
        }

        if (entry.entryId !== undefined) {
          parsedEntry.id = entry.entryId
          parsedEntry.sampling_date = entry.samplingDate
        } else {
          parsedEntry.entry_type_id = entry.id
          parsedEntry.title = entry.title
          parsedEntry.client_id = this.client.id
          parsedEntry.price = entry.price
          parsedEntry.amount = 1
          parsedEntry.clinic_id = gon.application.current_clinic.id
          parsedEntry.user_id = gon.application.current_user.id
          parsedEntry.kind = entry.kind
          parsedEntry.date = entry.date || new Date()
          parsedEntry.cost_price = entry.costPrice
          parsedEntry.number = entry.number
          parsedEntry.sampling_date = entry.samplingDate
          parsedEntry.state = 1
          parsedEntry.final_sum = entry.price
          parsedEntry.final_price = entry.price
          parsedEntry.sum = entry.price
          parsedEntry.discount_sum = 0.0
        }

        parsedEntry.tax_scheme = getEntryTaxSchemeOrDefault(entry)
        parsedEntry.measure_unit_id = entry.measureUnitId

        return parsedEntry
      })
    },

    parseEntriesForSubmitDefault () {
      return this.entries.map((entry) => {
        const biomaterialsArray = [...entry.selectedBiomaterials]

        const parsedEntry = {
          analysis_laboratory_id: this.selectedLaboratory.id,
          ext_laboratory_id: entry.extLaboratoryId,
          biomaterial_requirements: biomaterialsArray.map((biomaterial) => {
            return {
              biomaterial_id: biomaterial?.id ? parseInt(biomaterial.id) : parseInt(biomaterial),
              kind: biomaterial.kind,
              required: true,
              packing: this.isKdl ? biomaterial.packing : undefined,
            }
          }),
        }

        if (entry.entryId !== undefined) {
          parsedEntry.id = entry.entryId
          parsedEntry.sampling_date = entry.samplingDate
        } else {
          parsedEntry.entry_type_id = entry.id
          parsedEntry.title = entry.title
          parsedEntry.client_id = this.client.id
          parsedEntry.price = entry.price
          parsedEntry.amount = 1
          parsedEntry.clinic_id = gon.application.current_clinic.id
          parsedEntry.user_id = gon.application.current_user.id
          parsedEntry.kind = entry.kind
          parsedEntry.date = entry.date || new Date()
          parsedEntry.cost_price = entry.costPrice
          parsedEntry.number = entry.number
          parsedEntry.sampling_date = entry.samplingDate
          parsedEntry.state = 1
          parsedEntry.final_sum = entry.price
          parsedEntry.final_price = entry.price
          parsedEntry.sum = entry.price
          parsedEntry.discount_sum = 0.0
          parsedEntry.measure_unit_id = entry.measureUnitId
        }

        parsedEntry.tax_scheme = getEntryTaxSchemeOrDefault(entry)

        return parsedEntry
      })
    },

    loadLaboratories () {
      this.sendMessage(EVENTS.FETCH_LABORATORIES, {
        prefetchTree: true,
      })
    },

    loadCovidCodes () {
      if (this.selectedLaboratory) {
        this.sendMessage(EVENTS.FETCH_COVID_CODES, {
          analysis_laboratory_id: this.selectedLaboratory.id,
        })
      }
    },

    loadClientData () {
      this.sendMessage(EVENTS.FETCH_CLIENT, {
        client_id: this.clientId,
      })
    },

    sendMessage (event, params = {}) {
      params.uniqueComponentId = this.uniqueComponentId

      this.addInProgressEvent(event)

      this.wsHandler.sendMessage(event, params)
    },

    loadAnalysisOrder () {
      this.sendMessage(EVENTS.FETCH_ANALYSIS_ORDER, {
        analysis_order_id: this.analysisOrderId,
      })
    },

    initialLoad () {
      if (this.isEditMode) {
        this.loadAnalysisOrder()
      } else {
        this.loadLaboratories()
        this.loadClientData()
      }
    },

    addInProgressEvent (event) {
      this.requestsInProgress.push(event)
    },

    removeInProgressEvent (event) {
      const index = this.requestsInProgress.indexOf(event)
      if (index >= 0) {
        this.requestsInProgress.splice(index, 1)
      }
    },

    subscribeToEvents () {
      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.FETCH_ANALYSIS_ORDER}`, (data) => {
        if ((parseInt(this.analysisOrderId) === parseInt(data.result.id)) &&
            (parseInt(data.uniqueComponentId) === parseInt(this.uniqueComponentId))) {
          this.removeInProgressEvent(data.event)
          this.analysisOrder = data.result
          this.comment = this.analysisOrder.comment
          this.location = this.analysisOrder.location

          this.$pubSub.emitAsync(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.FETCH_LABORATORIES}`, {
            uniqueComponentId: this.uniqueComponentId,
            result: [{
              id: this.analysisOrder.analysisLaboratoryId,
              systemName: this.analysisOrder.analysisLaboratorySystemName,
              title: this.analysisOrder.analysisLaboratoryTitle,
              allowSameEntries: this.analysisOrder.analysisLaboratoryAllowSameEntries,
            }],
          })

          this.$pubSub.emitAsync(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.FETCH_CLIENT}`, {
            uniqueComponentId: this.uniqueComponentId,
            result: this.analysisOrder.client,
          })

          this.$pubSub.emitAsync(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.FETCH_NOTIFICATION_DEFAULTS}`, {
            uniqueComponentId: this.uniqueComponentId,
            result: {
              useSmsNotification: this.analysisOrder.useSmsNotification,
              useEmailNotification: this.analysisOrder.useEmailNotification,
              useClinicSmsNotification: this.analysisOrder.useClinicSmsNotification,
            },
          })

          this.entries = this.analysisOrder.entries
          this.entries.forEach((entry) => {
            entry.samplingDate = new Date(entry.samplingDate * 1000)

            this.$set(entry, 'selectedBiomaterialSets', {})
            this.$set(entry, 'multiselectLimit', 1)
            this.$set(entry, 'requiredBiomaterials', [])

            if (entry.biomaterialSets) {
              entry.biomaterialSets.forEach((set) => {
                entry.selectedBiomaterialSets[set.externalLaboratoryId] = set.biomaterials.map(
                  (biomaterial) => biomaterial.id
                )
              })
            }

            if (entry.biomaterials && entry.biomaterials.length) {
              this.$set(entry, 'selectedBiomaterials', entry.biomaterials.map((biomaterial) => biomaterial))

              if (entry.multipleBiomaterials) {
                entry.multiselectLimit = entry.biomaterials.length > 1 ? entry.biomaterials.length : entry.multiselectLimit
              }
            } else {
              this.$set(entry, 'selectedBiomaterials', [])
            }
            this.$set(entry, 'entryId', entry.id)
            entry.id = entry.entryTypeId
          })
        }
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.ERROR}`, (data) => {
        if (parseInt(data.uniqueComponentId) === parseInt(this.uniqueComponentId)) {
          this.removeInProgressEvent(data.event)

          this.errorEntriesNumbers = cloneDeep(data.entry_numbers || [])

          showOrderErrorNotification(data)
        }
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.FETCH_LABORATORIES}`, (data) => {
        if (parseInt(data.uniqueComponentId) === parseInt(this.uniqueComponentId)) {
          this.removeInProgressEvent(data.event)

          this.unlockPage()
          this.laboratories = data.result

          if (this.laboratories.length === 1) {
            this.selectedLaboratoryIndex = 0

            this.$refs.laboratorySelect.$listeners.change()
          }
        }
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.FETCH_CLIENT}`, (data) => {
        if (parseInt(data.uniqueComponentId) === parseInt(this.uniqueComponentId)) {
          this.removeInProgressEvent(data.event)

          this.client = data.result
        }
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.FETCH_COVID_CODES}`, (data) => {
        if (parseInt(data.uniqueComponentId) === parseInt(this.uniqueComponentId)) {
          this.removeInProgressEvent(data.event)

          this.covidCodes.push(...data.result)
        }
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.SEARCH_ENTRY_TYPES}`, (data) => {
        if (parseInt(data.uniqueComponentId) === parseInt(this.uniqueComponentId)) {
          this.removeInProgressEvent(data.event)
          if (data.result.length === 0) {
            data.result.push({
              title: T.no_mathes_found,
              message: true,
            })
          }
          this.searchCallback(data.result)
        }
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.BUILD_TREE}`, (data) => {
        if (parseInt(data.uniqueComponentId) === parseInt(this.uniqueComponentId)) {
          this.removeInProgressEvent(data.event)

          this.cachePrice(data.laboratory.systemName, data.result, data.tree_mode, data.category_id, data.node_id)
        }
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.FETCH_NOTIFICATION_DEFAULTS}`, (data) => {
        if (parseInt(data.uniqueComponentId) === parseInt(this.uniqueComponentId)) {
          this.$set(this, 'notificationTypes', data.result)
        }
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.WEBSOCKETS_CONNECTED}`, (data) => {
        this.initialLoad()
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.GET_PRICE_INFO}`, (data) => {
        if (parseInt(data.uniqueComponentId) === parseInt(this.uniqueComponentId)) {
          this.availabilityInfo = data.result
        }
      })
    },

    getEtaStatus () {
      if (this.selectedLaboratory) {
        this.sendMessage(EVENTS.GET_PRICE_INFO, {
          analysis_laboratory_id: this.selectedLaboratory.id,
          category: 'eta_status',
        })
      }
    },

    toSnakeCase (string) {
      return string.replace(/([A-Z])/g, '_$1').toLowerCase()
    },

    cachePrice (laboratoryName, price, treeMode, categoryId, nodeId) {
      if (categoryId) {
        this.renderTree(price, treeMode, nodeId)
      } else {
        this.price[treeMode][laboratoryName] = price

        if (this.selectedLaboratory.systemName === laboratoryName) {
          this.renderTree(price, treeMode, nodeId)
        }
      }
    },

    renderTree (treeItems, treeMode, nodeId) {
      const id = nodeId || 0
      if (this.treeCallback[treeMode][id]) {
        this.treeCallback[treeMode][id](treeItems)

        // we have no way of understanding when Element will actually render tree elements
        // so we have to guess
        setTimeout(Utils.updateTooltips, 300)

        this.treeCallback[treeMode][id] = undefined
      }
    },
  },
}
</script>
