import { snakeToCamel } from '@/_api/_requests/helpers'
import { IgRPCReceiverConfig } from '@/_api/_classes/GRPC/interfaces/IgRPCReceiverConfig'
import { MgRPCStrategy } from '@/_api/_requests/MListServiceApi/strategies/MgRPCStrategy'
import { IList } from '@/_declarations/IList'
import { IgRPCBatch } from '@/_api/_classes/GRPC/interfaces/igRPCBatch'
import { GRPC_PAYLOAD_TYPE } from '@/_api/_classes/GRPC/consts/consts'
import { GRPCReceiverAbstract } from '@/_api/_classes/GRPC/Receiver/GRPCReceiverAbstract'

export class GRPCBuilderList<DataItem, FiltersMap> extends GRPCReceiverAbstract<object> {
  private totals: object = {}

  private context: MgRPCStrategy<DataItem, FiltersMap>

  constructor (context: MgRPCStrategy<DataItem, FiltersMap>, config: IgRPCReceiverConfig) {
    super(config)
    this.context = context
  }

  protected processData (rawBatches: string[]) {
    const batches: IgRPCBatch['batch'] = rawBatches.reduce((acc, rawBatch) => {
      const { batch }: IgRPCBatch = snakeToCamel(JSON.parse(rawBatch))
      acc.push(...batch)

      return acc
    }, [])

    batches.forEach(({ type, ...payload }) => {
      type === GRPC_PAYLOAD_TYPE.TOTAL
        ? this.totals = payload
        : this.data.push(payload.data)
    })
  }

  protected fillData () {
    const adaptedData = this.toClient({
      ...this.totals,
      data: this.data,
    }) as IList<DataItem[], DataItem>

    this.context.fillData(adaptedData)
  }

  protected resetProps () {
    super.resetProps()
    this.data = []
    this.totals = {}
  }

  protected stopLoading () {
    super.stopLoading()
    this.context.context.stopLoading()
  }
}
