<template>
  <context-popup ref="contextPopup">
    <div class="flex gap-indent-small">
      <!-- Ед.изм. -->
      <template v-if="canChangeMeasureUnits">
        <m-select-lazy
          v-show="showMeasureUnit"
          ref="measureUnit"
          v-model="currentMeasureUnit"
          :label="t('measureUnit')"
          :fetch-method="MeasureUnitApi.list"
          :m-fixed-height="false"
          :additional-displayed-filter="searchByTitles"
          option-label="shortTitle"
          filterable
          :clearable="false"
          @changeByLazy="setValue('measureUnit', $event)"
        />
      </template>

      <!-- Врач -->
      <m-select-lazy
        v-show="showPerformer"
        ref="performer"
        v-model="currentPerformer"
        :label="t('performer')"
        :fetch-method="fetchPerformer"
        :m-fixed-height="false"
        option-label="shortName"
        filterable
        @changeByLazy="setValue('performer', $event)"
      />

      <!-- Ассистент -->
      <template v-if="useAssistants">
        <m-select-lazy
          v-show="showAssistant"
          ref="assistant"
          v-model="currentAssistant"
          :label="t('assistant')"
          :fetch-method="fetchAssistant"
          :m-fixed-height="false"
          option-label="shortName"
          filterable
          @changeByLazy="setValue('assistant', $event)"
        />
      </template>

      <!-- Реферал -->
      <m-select-lazy
        v-show="showReferral"
        ref="referral"
        v-model="currentReferral"
        :label="t('referral')"
        :fetch-method="fetchReferral()"
        :search-method="(params) => referralsPresenter.search(params)"
        :m-fixed-height="false"
        option-label="shortName"
        filterable
        @changeByLazy="setValue('referral', $event)"
      />

      <!-- Склад -->
      <m-select-lazy
        v-show="showStore"
        ref="store"
        v-model="currentStore"
        :label="t('store')"
        :fetch-method="(params) => storesPresenter.list(params)"
        :m-fixed-height="false"
        :disabled="storesDisabled"
        option-label="title"
        filterable
        :clearable="!storesDisabled"
        @changeByLazy="setValue('store', $event)"
      />

      <m-button template="close" />
    </div>
  </context-popup>
</template>

<script lang="ts">
import ContextPopup from '@/vue_present/Reuse/ContextPopup/ContextPopup.vue'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import { MUserPresenter } from '@/_api/MUser/MUserPresenter'
import { doctorPresenter } from '@/api_entities/doctors/doctorPresenter'
import { MReferralPresenter } from '@/_api/MReferral/MReferralPresenter'
import { MStorePresenter } from '@/_api/MStore/MStorePresenter'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { camelToSnake, snakeToCamel } from '@/_api/_requests/helpers'
import { upperFirst } from 'lodash'
import { fetchUser } from '@/vue_apps/Orders/helpers/fetchUser'
import {
  getComplexIndexByComplexHeadItemNode,
  setComplexMembersAttributes,
} from '@/vue_apps/Orders/helpers/setComplexMembersAttributes'
import { MeasureUnitApi } from '@/vue_apps/catalogs_root/MeasureUnits/entities/MeasureUnitApi'
import { TEntryFieldTarget } from '../OrderBoostTypes'
import { IMeasureUnit } from '@/_declarations/IMeasureUnit'
import { IHuman } from '@/_declarations/IHuman'
import { searchByTitles } from '@/helpers/searchByTitles'
import { ICatalog } from '@/_declarations/ICatalog'

export default {
  name: 'OrderBoostPopup',
  components: { MButton, MSelectLazy, ContextPopup },
  props: {
    targetContainer: { type: String, required: true },
    targetSelectorClass: { type: String, required: true },
    useAssistants: Boolean,
    storesDisabled: Boolean,
    canChangeMeasureUnits: Boolean,
  },

  data () {
    return {
      doctorPresenter,
      assistantsPresenter: new MUserPresenter(),
      referralsPresenter: new MReferralPresenter(),
      storesPresenter: new MStorePresenter(),
      MeasureUnitApi,
      wasOpened: false,

      currentPerformer: null,
      currentAssistant: null,
      currentReferral: null,
      currentStore: null,
      currentMeasureUnit: null,

      currentTarget: null,

      isComplexHead: false,

      currentTargetEntity: null as TEntryFieldTarget,
    }
  },

  computed: {
    showPerformer () {
      return this.currentTargetEntity === 'performer'
    },

    showAssistant () {
      return this.currentTargetEntity === 'assistant'
    },

    showReferral () {
      return this.currentTargetEntity === 'referral'
    },

    showStore () {
      return this.currentTargetEntity === 'store'
    },

    showMeasureUnit () {
      return this.currentTargetEntity === 'measureUnit'
    },

    currentTargetLabelAttribute () {
      if (this.showStore) { return 'title' }
      if (this.showMeasureUnit) { return 'shortTitle' }

      return 'shortName'
    },
  },

  mounted () {
    const container = document.querySelector(this.targetContainer)
    if (!container) { return }
    container.addEventListener('click', this.onClick)
  },

  beforeDestroy () {
    const container = document.querySelector(this.targetContainer)
    if (!container) { return }
    container.removeEventListener('click', this.onClick)
  },

  methods: {
    camelToSnake,
    snakeToCamel,
    searchByTitles,

    fetchData () {
      ;[
        this.$refs.performer,
        this.$refs.assistant,
        this.$refs.referral,
        this.$refs.store,
        this.$refs.measureUnit,
      ].forEach((ref) => ref?.onLoadMore(true))
    },

    /** @param {PointerEvent} event */
    onClick (event) {
      const { target } = event

      if (!this.validateTarget(target)) { return }
      if (!this.wasOpened) {
        this.wasOpened = true
        this.fetchData()
      }

      this.currentTarget = target
      this.currentTargetEntity = target.dataset.entity

      this.isComplexHead = target.parentElement.classList.contains('f-order-entry-complex')

      const id = target.querySelector('input')?.value

      this[`current${upperFirst(this.currentTargetEntity)}`] =
          this.$refs[this.currentTargetEntity].findItem(+id)

      this.$refs.contextPopup.onContextMenuHandler(event)
    },

    validateTarget ({ classList }: HTMLElement) {
      return classList.contains(this.targetSelectorClass) &&
        !classList.contains('entry-disabled-field') &&
        !classList.contains('disabled-row')
    },

    setValue (target: TEntryFieldTarget, value: IMeasureUnit | IHuman | ICatalog) {
      this.currentTarget.querySelector('input').value = value?.id || ''
      this.currentTarget.querySelector('.order-boost__label').innerText = value?.[this.currentTargetLabelAttribute] || ''
      this.currentTarget.querySelector('.has-error')?.classList?.remove('has-error')

      if (this.isComplexHead && this.showReferral) {
        this.__setReferralInComplexItems(value)
      }

      if (this.showMeasureUnit) {
        this.updateTooltip((value as IMeasureUnit).title)
      }

      this.currentTarget = null
      this.$refs.contextPopup.close()
    },

    __setReferralInComplexItems (value) {
      const currentComplexIndex = getComplexIndexByComplexHeadItemNode(this.currentTarget)

      setComplexMembersAttributes(
        currentComplexIndex,
        'referral_id',
        value.id
      )
    },

    updateTooltip (newTooltipText: string = '') {
      this.currentTarget._tippy.setContent(newTooltipText)
    },

    fetchPerformer (sortParams) {
      return fetchUser((params) => doctorPresenter.fetchAll(params, snakeToCamel), sortParams)
    },

    fetchAssistant (sortParams) {
      return fetchUser((params) => this.assistantsPresenter.list(params), sortParams)
    },

    fetchReferral () {
      return (params) => this.referralsPresenter.list({
        ...params,
        withInternal: true,
      })
    },
  },
}
</script>
