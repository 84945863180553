import { LIMIT } from '@/specific/reports/const'
import ReportRequestBuilder from '@/specific/reports/ReportRequestBuilder'
import ReportClient from '@/specific/reports/ReportClient'

export default {
  props: {
    isHidden: Boolean,
  },

  data () {
    return {
      rows: [],
      header: {},
      footer: {},
      isLoading: false,
      isDirty: false,
      recordsCount: 0,
      pageCount: 0,
      currentPage: 1,
      priority: 0,
      loadButton: $('#report_submit'),
    }
  },

  computed: {
    subtitle () {
      return this.subtitleParts ? this.subtitleParts.filter(Boolean).join('. ') + '.' : ''
    },
  },

  methods: {
    loadData (page = 1) {
      if (this.isLoading) return

      this.currentPage = page
      this.isLoading = true

      const params = this.requestBuilder
        .build()
        .get()

      this.beforeRequestSend()
      this.loadButton.attr('disabled', 'disabled')

      return this.reportClient
        .get(params)
        .then((result) => {
          this.recordsCount = result.count
          this.fillTable(result)

          return this.$nextTick()
            .then(() => this.afterTableFill(result))
        })
        .catch((error) => {
          this.onRequestError(error)
        })
        .finally(() => {
          this.loadButton.removeAttr('disabled')
          this.isLoading = false
          this.isDirty = true
          this.$nextTick()
            .then(() => this.afterReportUpdated())
        })
    },
    // REPORT HOOKS START
    /**
     * Вызывается перед отправкой запроса, выполняется синхронно.
     * Если метод вернёт промис, запрос НЕ будет его ждать.
     * Может быть использовано для установки параметров запроса через `this.requestBuilder`
     * прямо перед отправкой запроса, если такая необходимость появится.
     */
    beforeRequestSend () {},
    /**
     * Вызывается после того, как данные отчёта были установлены в `fillTable`.
     * При этом отчёт ещё находится в состоянии загрузки и футер ещё не отрендерен.
     *
     * @param {object[]} result Массивы данных, тот же массив, что передаётся в `fillTable`
     */
    afterTableFill (result) { return result },
    /**
     * Вызывается после того, как загрузка отчёта полностью завершена.
     * На момент вызова хука тело отчёта уже отрисовано из данных,
     * установленных в `fillTable`.
     */
    afterReportUpdated () {},
    // REPORT HOOKS END
    clear () {
      Object.assign(this.$data, this.$options.data.call(this))
    },

    onRequestError (error) {
      console.error(error)
      Services
        .ui
        .notificator()
        .error(t('abstract_error_message'), t('error'), 0)
    },
  },

  watch: {
    recordsCount () {
      this.pageCount = Math.ceil(this.recordsCount / LIMIT)
    },

    isLoading (loading) {
      if (!loading) {
        this.$emit('report-loaded')
      }
    },
  },

  created () {
    this.requestBuilder = new ReportRequestBuilder(this)
    this.reportClient = new ReportClient()
  },
}
