import { tScoped } from '@/helpers/i18nHelpers'
import { orNull } from '@/_declarations/orNull'

const ts = tScoped('enums.call.status')

const callStatuses = [
  {
    icon: 'fad fa-phone-plus hidden-print',
    text: ts('accepted'),
  },
  {
    icon: 'fad fa-phone-slash hidden-print',
    text: ts('lost'),
  },
]

export const getCallStatusItem = (callStatusId: orNull<number>) => {
  return callStatusId
    ? callStatuses.at(callStatusId - 1)
    : ''
}
