import htmlHelper from '../plugins/dynamic_forms/helpers/htmlHelper'
import { calculateMembersTotal } from '@/specific/entries/calculateMembersTotal'

import { createEntryProtocolApp } from '@/vue_apps/MedicalRecords/EntryProtocols'
import { GLOBAL_ENTRY_TYPES_KINDS } from '@/_global_scripts/GLOBAL_CONSTS'
import { createMedicalRecordsProtocolApp } from '@/vue_apps/MedicalRecords/MedicalRecordsProtocol'
import {
  getTransferAvailability,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/components/TreatmentPlansEntriesTransfer/store/helpers'

$document.on('rez/entries/edit rez/entries/new', function () {
  /**
   * Сброс редактора
   */
  if (tinymce.activeEditor) { tinymce.activeEditor.remove() }

  if (gon.specific.entry.kind !== GLOBAL_ENTRY_TYPES_KINDS.GOOD) {
    createEntryProtocolApp()
  }

  const EntryForm = FormFactory.build('entry')

  if (pageInfo.action === 'edit') {
    EntryForm.editItem()

    const isWithAmountChecking = getTransferAvailability().isWithAmountChecking
    $('#entry_form').on('submit', function (e) {
      if (!e.originalEvent) { return false }
      e.preventDefault()

      if (isWithAmountChecking) {
        $('.f-nested-amount').prop('disabled', false)
      }

      if (gon.specific.locked) {
        const $entryForm = $('#entry_form')
        $entryForm.find('[name*="price"]').removeAttr('name')
        $entryForm.find('[name*="sum"]').removeAttr('name')
        $entryForm.find('[name*="amount"]').removeAttr('name')
        $entryForm.find('[name*="final_sum"]').removeAttr('name')
        $entryForm.find('[name*="discount_sum"]').removeAttr('name')
        $entryForm.find('[name*="discount_value"]').removeAttr('name')
        $entryForm.find('[name*="cost_price"]').removeAttr('name')
      }

      $(this).submit()

      if (isWithAmountChecking) {
        setTimeout(() => {
          $('.f-nested-amount').prop('disabled', 'disabled')
        })
      }
    })
  } else if (pageInfo.action === 'new') {
    EntryForm.newItem()
  }

  if (gon.application.show_entries_final_sum) {
    calculateMembersTotal()
  }

  Services.pubSub.emitAsync('entryReady', new URLSearchParams(location.search))
})

$document.on('rez/entries/show', function () {
  $('.attachment-preview-image, .attachment-preview-pdf').each((i, preview) => {
    $(preview).attachmentPreview({
      type: preview.dataset.mimetype,
      hideDownloadBtn: true,
      hidePrintBtn: true,
      headerText: $(preview).find('img').attr('alt'),
    })
  })

  $('.attachment-preview-no').attachmentPreview({ type: '', hideDownloadBtn: true })

  htmlHelper.clearHtml()

  if (gon.specific.entry?.kind !== GLOBAL_ENTRY_TYPES_KINDS.GOOD) {
    createMedicalRecordsProtocolApp()
  }
})
