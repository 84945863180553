<template>
  <div class="medical-records-tabs-container__tab protocols-tab">
    <m-si-generator
      class="protocols-tab__list"
      :si-generator-schema="schema"
      :items="list.data"
      :page-count="list.totalPages"
      :current-page="list.currentPage"
      :nfr-text="t('no_protocols_yet')"
      :active-item-id="routeId"
      :search-query="list.searchQuery"
      :nfr-filtered="list.isFiltered"
      filters-class="flex-column"
      use-context-menu
      @update:currentPage="list.setPage($event)"
      @onItemClick="onItemClick($event)"
      @onItemContextMenuClick="lastContextClickedItem = $event"
      @update:searchQuery="list.search($event)"
      @onCellClick="onCellClick($event)"
      @onItemDelete="onItemDelete($event)"
      @onResetClick="resetCurrentList"
    >
      <template #filters>
        <div class="flex gap-indent-small mt-6 flex-wrap">
          <m-date-picker
            class="protocols-tab__period"
            type="daterange"
            :label="t('period')"
            :full-width="false"
            :m-fixed-height="false"
            :value="list.filters.date"
            value-format="yyyy-MM-dd"
            @change="list.setFilterValue('date', $event)"
          />

          <reusable-doctors-list
            class="protocols-tab__doctor"
            :value="list.filters.doctor"
            :m-fixed-height="false"
            @onDoctorChange="list.setFilterValue('doctor', $event)"
          />

          <reusable-mine
            class="protocols-tab__mine"
            :value="list.filters.doctor"
            @setMine="list.setFilterValue('doctor', $event)"
          />

          <reusable-specialties-list
            class="protocols-tab__specialty"
            :value="list.filters.specialty"
            :m-fixed-height="false"
            @onSpecialtyChange="list.setFilterValue('specialty', $event)"
          />

          <reusable-doctor-clinics-list
            class="protocols-tab__clinic"
            :value="list.filters.clinic"
            :m-fixed-height="false"
            @onClinicChange="list.setFilterValue('clinic', $event)"
          />

          <m-si-generator-filters-buttons
            @onResetClick="resetCurrentList"
            @onRefreshClick="fetchCurrentList"
          />
        </div>
      </template>

      <template #add>
        <add-protocol-button
          :client-id="clientId"
          :medical-record-id="medicalRecordId"
        />
      </template>

      <template #contextMenu>
        <m-context-menu-new-tab
          v-if="lastContextClickedItem"
          :url="$routes.protocols_page_path(medicalRecordId, lastContextClickedItem.id, { anchor: PROTOCOLS_ID })"
        />
      </template>

      <template #itemOptionDelete="{ item }">
        <span
          v-if="!item.canDelete"
          class="slot-stub"
        />
      </template>
    </m-si-generator>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import ReusableDoctorClinicsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorClinicsList.vue'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import AddProtocolButton from '@/vue_apps/ProtocolsApp/components/AddProtocolButton.vue'
import MSiGeneratorFiltersButtons
  from '@/vue_present/_base/Tables/MSiGenerator/components/MSiGeneratorFiltersButtons.vue'
import ReusableMine from '@/vue_present/Reuse/Inputs/ReusableMine/ReusableMine.vue'
import ReusableSpecialtiesList from '@/vue_present/Reuse/Lists/Specialties/ReusableSpecialtiesList.vue'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import MContextMenuNewTab from '@/vue_present/_base/Tables/MContextMenuNewTab.vue'
import { PROTOCOLS_ID } from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/consts'
import { ENTITIES } from '@/vue_components/store/modules/egisz/ws/egisz_ws'
import { ProtocolsListItem } from '@/vue_apps/MedicalRecords/EntryProtocols/entities/ProtocolsListItem'
import { ICatalog } from '@/_declarations/ICatalog'
import { IApiErrors } from '@/_declarations/IApiErrors'
import { PROTOCOLS_ROUTE_NAMES } from '@/vue_apps/ProtocolsApp/router/protocolsRouteNames'
import { getProtocolsSchema } from '@/vue_apps/MedicalRecords/EntryProtocols/entities/getProtocolsSchema'
import { TMSiGeneratorSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiGeneratorTypes'
import { MListServiceApi } from '@/_api/_requests/MListServiceApi/MListServiceApi'
import {
  getProtocolsFilters,
  protocolsListAdapter,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/api/protocolsConfig'
import {
  setDraftToProtocolsListItems,
} from '@/vue_apps/MedicalRecords/EntryProtocols/logic/setDraftToProtocolsListItems'
import { ProtocolDraftStorage } from '@/plugins/dynamic_forms/components/editor/ProtocolDraftStorage'

export default defineComponent({
  name: 'ProtocolsTab',

  components: {
    MContextMenuNewTab,
    MSiGenerator,
    ReusableSpecialtiesList,
    ReusableMine,
    MSiGeneratorFiltersButtons,
    AddProtocolButton,
    MDatePicker,
    ReusableDoctorClinicsList,
    ReusableDoctorsList,
  },

  props: {
    clientId: { type: Number, required: true },
    medicalRecordId: { type: Number, required: true },
  },

  data () {
    return {
      schema: getProtocolsSchema().addSearch() as TMSiGeneratorSchema,
      list: new MListServiceApi({
        src: Routes.list_api_internal_protocols_path(),
        DataItemEntity: ProtocolsListItem,
        defaultFilters: getProtocolsFilters({ clientId: this.clientId }),
        adapter: protocolsListAdapter,
        requiredFilters: ['clientId'],
      }),

      lastContextClickedItem: null as ICatalog,
      PROTOCOLS_ID,
    }
  },

  computed: {
    routeId () {
      return +this.$route.params.id
    },

    canManageProtocols () {
      return this.$security.canManageEntryTemplate
    },

    isProtocolAction () {
      return gon.page.action === 'protocols'
    },
  },

  created () {
    this.wsSubscribe()

    this.$pubSub.subscribe('ProtocolEditorView:ProtocolChanged', () => this.fetchCurrentList())
    this.$pubSub.subscribe('broadcast:ProtocolDraftStorage:Saved', (event) => { this.setProtocolsDrafts(event, true) })
    this.$pubSub.subscribe('broadcast:ProtocolDraftStorage:Dropped', (event) => { this.setProtocolsDrafts(event, false) })
  },

  beforeDestroy () {
    this.$pubSub.unsubscribe('ProtocolEditorView:ProtocolChanged', () => this.fetchCurrentList())
    this.$pubSub.unsubscribe('broadcast:ProtocolDraftStorage:Saved', (event) => { this.setProtocolsDrafts(event, true) })
    this.$pubSub.unsubscribe('broadcast:ProtocolDraftStorage:Dropped', (event) => { this.setProtocolsDrafts(event, false) })
  },

  mounted () {
    this.fetchCurrentList()
  },

  methods: {
    wsSubscribe () {
      if (!this.canManageProtocols) { return }
      if (!gon.application.egisz_module_enabled) { return }

      Services.wsSubscriptions.egisz.connect(({ data, action }) => {
        if (action !== ENTITIES.PDF_GENERATED) { return }

        const protocolItem: ProtocolsListItem = this.list.data.find(({ id }) => id === data?.id)
        if (!protocolItem) { return }

        protocolItem.setPdfGenerated(true)
      })
    },

    async fetchCurrentList () {
      await this.list.fetchAll()
      this.setProtocolsDrafts()
    },

    async resetCurrentList () {
      await this.list.resetFilters()
      this.setProtocolsDrafts()
    },

    setProtocolsDrafts ({ protocolId } = {}, value = true) {
      const protocolsIds = protocolId
        ? [protocolId]
        : ProtocolDraftStorage.getProtocolIds()

      setDraftToProtocolsListItems(this.list.data, protocolsIds, value)
    },

    onItemClick ({ id }) {
      const route = Routes.protocols_page_path(this.medicalRecordId, id, { anchor: PROTOCOLS_ID })

      if (this.isProtocolAction) {
        if (this.routeId === id) { return }
      }

      this.isProtocolAction
        ? this.$router.push(route)
        : Turbolinks.visit(route)
    },

    onCellClick ({ cell, item }: { edit: string; item: ICatalog }) {
      if (cell === 'edit') {
        const url = `${Routes.protocols_page_path(this.medicalRecordId, item.id)}/edit#${PROTOCOLS_ID}`
        Utils.openInNewTab(location.origin + url)
      }

      if (cell === 'draft') {
        Utils.openInNewTab(
          this.$routes.protocols_page_path(
            this.medicalRecordId, `${item.id}/edit`,
            { anchor: PROTOCOLS_ID, byDraft: true }
          )
        )
      }
    },

    async onItemDelete (dataItemEntityInstance: { id: number; destroy: () => Promise<IApiErrors> }) {
      const result = await dataItemEntityInstance.destroy()
      if (result?.errors) { return }
      await this.list.fetchAll()

      if (dataItemEntityInstance.id === this.routeId) {
        await this.$router.replace({ name: PROTOCOLS_ROUTE_NAMES.INDEX })
      }
    },
  },
})
</script>
