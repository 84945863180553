import { ORDER_PAID_STATUS } from '@/lib/payments/consts'

export const getStoresDisabledValue = () => gon.specific.stores?.moduleIsEnabled
  ? false
  : {
      disabled: true,
      tooltip: t('introduction.module_not_connected'),
    }

export const getCanChangeMeasureUnits = () => {
  const paidStatus = gon.specific.order.paid_status

  return gon.specific.changeMeasureUnitsInOrders &&
    (paidStatus === ORDER_PAID_STATUS.NOT_PAID ||
      paidStatus === ORDER_PAID_STATUS.FREE)
}
