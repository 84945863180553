import { createVueApp } from '@/vue_components/create_vue_app'
import OrdersQuickAdditionApp from '@/vue_apps/FinanceModule/OrdersQuickAddition/OrdersQuickAdditionApp.vue'

let vueApp = null
export const createOrdersQuickAdditionApp = (el = '#vue_order_entries_quick_addition_root') => {
  if (vueApp?.$destroy) { vueApp.$destroy() }
  vueApp = createVueApp({
    el,
    name: 'OrdersQuickAdditionApp',
    render: (h) => h(OrdersQuickAdditionApp),
  })
}
