
export const emitMassRowsUpdate = (target, value) => {
  const attributeId = target === 'performer' ? 'user_id' : `${target}_id`

  PubSub.emit('page.form.order.orderEntryList.askSetAttributes', {
    attributes: {
      [attributeId]: value?.id || null,
    },
    change: target,
    multiple: true,
    updateLabels () {
      const labels = document.querySelectorAll(`.order-boost.entry-${target}:not(.entry-disabled-field) .order-boost__label`)
      labels.forEach((label) => { label.innerText = value?.title || value?.shortName || '' })

      if (target === 'performer') {
        document
          .querySelectorAll('[data-entity="performer"] .form-group')
          .forEach((selector) => selector.classList.remove('has-error'))
      }
    },
  })
}
