export const header = {
  number: {
    text: '№',
    sortable: 'callId',
  },

  date: {
    text: t('date_translate'),
    sortable: 'callDateTime',
  },

  type: {
    text: t('telephony.type'),
    sortable: 'callType',
  },

  status: {
    text: t('status'),
    sortable: 'callStatus',
  },

  responsible: {
    text: t('responsible_users'),
    sortable: 'responsibleSurnameInitials',
  },

  client: {
    text: t('client'),
    sortable: 'clientSurname',
  },

  phone: {
    text: t('phone'),
    sortable: 'callPhone',
  },

  duration: {
    text: t('duration'),
    sortable: 'callDuration',
  },

  result: {
    text: t('result'),
    sortable: 'callResult',
  },

  refuseReason: {
    text: t('telephony.refuse_result'),
    sortable: 'refuseResultTitle',
  },

  comment: {
    text: t('comment'),
    sortable: 'callNote',
  },

  attractionSource: {
    text: t('attraction_source'),
    sortable: 'attractionSourceTitle',
  },
}
