import { GRPCReceiverAbstract } from '@/_api/_classes/GRPC/Receiver/GRPCReceiverAbstract'
import { IgRPCReceiverConfig } from '@/_api/_classes/GRPC/interfaces/IgRPCReceiverConfig'
import { orNull } from '@/_declarations/orNull'

export class GRPCBuilderCSV<
  SendStrategyData = Record<string, any>,
  SendStrategyResult = Promise<void>
> extends GRPCReceiverAbstract<string, string, string[] | void, SendStrategyData, SendStrategyResult> {
  private readonly fileName: orNull<string> = null

  constructor (config: IgRPCReceiverConfig) {
    super(config)
    this.toServer = config?.adapter?.toExport || ((data: unknown) => data)
    this.fileName = config?.fileName ?? null
  }

  send (payload: SendStrategyData): SendStrategyResult {
    return super.send(payload)
  }

  protected processData (data: string[]) {
    this.data = data
  }

  protected fillData () {
    return this.fileName
      ? Utils.downloadExportFile(this.data.join(''), `${this.fileName} ${t('export').toLocaleLowerCase()}`)
      : this.data
  }

  protected resetProps () {
    super.resetProps()
    this.data = []
  }
}
