import { createVueApp } from '@/vue_components/create_vue_app'
import MedicalRecordsPrintDocuments
  from '@/vue_apps/MedicalRecords/MedicalRecordsPrintDocuments/MedicalRecordsPrintDocuments.vue'
import { snakeToCamel } from '@/_api/_requests/helpers'

let vueApp = null
export const createMedicalRecordsDocumentsButton = (el = '#vue_documents_button_root') => {
  if (vueApp?.$destroy) { vueApp.$destroy() }
  vueApp = createVueApp({
    el,
    name: 'MedicalRecordsDocumentsButtonRoot',
    render: (h) => h(MedicalRecordsPrintDocuments, {
      props: {
        client: snakeToCamel(gon.specific.client),
      },
    }),
  })
}
