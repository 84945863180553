import {
  DEFAULT_DOCUMENT_ID,
  PROTOCOLS_ID,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/consts'

const PERMITTED_TABS = Object.freeze([
  'entries',
  DEFAULT_DOCUMENT_ID,
  PROTOCOLS_ID,
  'files',
  'treatment_plans',
  'dental_orders',
  'analysis_orders',
  'egisz',
])

export const getTabIfPermit = (tab = '') => {
  return PERMITTED_TABS.includes(tab)
    ? tab
    : PERMITTED_TABS[0]
}
