import { Api } from '@/_api/decorators/api/Api'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { IPayerSearchDTO } from '@/vue_present/Reuse/Lists/PayerSearchInput/interfaces/IPayerSearchDTO'
import { payerSearchAdapter } from '@/vue_present/Reuse/Lists/PayerSearchInput/api/payerSearchAdapter'

export class PayerSearchPresenter {
  @Api.notify(
    'fetchMessage',
    'payers', {
      success: false,
      defaultValue: {
        clients: [],
        companies: [],
      },
    }
  )
  @Api.clientAdapter(payerSearchAdapter)
  @Api.get(Routes.payer_search_orders_path)
  fetchPayers (searchString: string) {
    return {
      payload: {
        searchString,
      },
    } as ICRUDReturn as Promise<IPayerSearchDTO>
  }
}
