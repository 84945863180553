import { getCallTypeItem } from '@/vue_components/reports/CallsReport/consts/callTypes'
import { getCallStatusItem } from '@/vue_components/reports/CallsReport/consts/callStatuses'
import { getCallResultItem } from '@/vue_components/reports/CallsReport/consts/callResults'
import { orNull } from '@/_declarations/orNull'
import { DEFAULT_LIMIT } from '@/vue_components/store/modules/filters_base'
import { ReportParamFactory } from '@/specific/reports/shared'
import { GRPC_FORMAT_TYPE } from '@/_api/_classes/GRPC/consts/consts'
import { DEFAULT_OFFSET, MAX_LIMIT } from '@/specific/reports/const'
import { defineDateFormat } from '@/specific/reports/export'
import { TCallReportClient } from '@/vue_components/reports/CallsReport/interfaces/TCallReportClient'
import { ICatalog } from '@/_declarations/ICatalog'

export const callsAdapter = {
  toClient (data) {
    return {
      data: data.data.map(({ call }) => ({
        ...call,
        type: getCallTypeItem(call.type),
        status: getCallStatusItem(call.status),
        result: getCallResultItem(call.result),
        refuseResult: getRefuseResult(call.refuseResult),
        client: getClient(call.client),
      })),

      totalItems: data.count,
      totalPages: Math.ceil(data.count / DEFAULT_LIMIT),
      totals: { count: data.count },
    }
  },

  toServer (data, format = GRPC_FORMAT_TYPE.JSON) {
    return {
      report: {
        period: data.period,
        format,
      },

      clinicIds: ReportParamFactory.buildArrayParam(data.clinic),
      responsibleIds: ReportParamFactory.buildArrayParam(data.user),
      callTypes: ReportParamFactory.buildArrayParam(data.callType),
      callStatuses: ReportParamFactory.buildArrayParam(data.callStatus),
      callResults: ReportParamFactory.buildArrayParam(data.callResult),
      refuseResultIds: ReportParamFactory.buildArrayParam(data.refuseReason),
      attractionSourceIds: ReportParamFactory.buildArrayParam(data.attractionSources),

      sorting: data.sorting,
      limit: data.limit,
      offset: data.offset,
    }
  },

  toExport (data) {
    return {
      ...callsAdapter.toServer(data, GRPC_FORMAT_TYPE.CSV),

      locale: {
        headerRow: {
          callId: '№',
          callDateTime: t('date_translate'),
          callType: t('telephony.type'),
          callStatus: t('status'),
          responsibleSurnameInitials: t('responsible_users'),
          clientFullName: t('client'),
          callPhone: t('phone'),
          callDuration: t('duration'),
          callResult: t('result'),
          refuseResultTitle: t('telephony.refuse_result'),
          callNote: t('comment'),
          attractionSourceTitle: t('attraction_source'),
        },

        callType: {
          incoming: t('enums.call.call_type.incoming'),
          outcoming: t('enums.call.call_type.outcoming'),
        },

        callStatus: {
          accepted: t('enums.call.status.accepted'),
          lost: t('enums.call.status.lost'),
        },

        callResult: {
          inProgress: t('enums.call.result.in_progress'),
          appointmentAdded: t('enums.call.result.appointment_added'),
          refused: t('enums.call.result.refused'),
          finished: t('enums.call.result.finished'),
          appointmentConfirmed: t('enums.call.result.appointment_confirmed'),
          appointmentCanceled: t('enums.call.result.appointment_canceled'),
          onHold: t('enums.call.result.on_hold'),
        },

        dateTimeFormat: `${defineDateFormat()}, 15:04`,
        csvComma: gon.application.csv_character,
        floatingComma: gon.localization.currency.decimal_character,
        currencyCodeString: gon.localization.currency.code_string,
      },

      offset: DEFAULT_OFFSET,
      limit: MAX_LIMIT,
    }
  },
}

const getClient = (client: orNull<TCallReportClient>) => {
  if (!client) { return client }

  return {
    ...client,
    link: Routes.client_path(client.id),
  }
}

const getRefuseResult = (refuseResult: orNull<ICatalog<number>>) => refuseResult
  ? refuseResult.title
  : ''
