import { IHuman } from '@/_declarations/IHuman'
import { TDatePeriod } from '@/_declarations/TDatePeriod'
import { ICatalog } from '@/_declarations/ICatalog'
import { inArrayOrUndefined } from '@/_medods_standart_library/msl'
import { omit } from 'lodash'
import { IAdapter } from '@/_api/decorators/api/interfaces/IAdapter'

interface IProtocolsFilters {
  clientId: number
  doctor: IHuman | null
  date: TDatePeriod | null
  clinic: ICatalog | null
  specialty: ICatalog | null
}

export const getProtocolsFilters = ({ clientId }): IProtocolsFilters => ({
  clientId,
  doctor: null,
  date: null,
  clinic: null,
  specialty: null,
})

export const protocolsListAdapter: IAdapter = {
  toServer (data: IProtocolsFilters & { limit: number; offset: number }) {
    return {
      ...omit(data, ['doctor', 'clinic']),
      clinicIds: inArrayOrUndefined(data.clinic?.id),
      doctorIds: inArrayOrUndefined(data.doctor?.id),
      specialtyIds: inArrayOrUndefined(data.specialty?.id),
    }
  },
} as const
