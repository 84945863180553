import { createVueApp } from '@/vue_components/create_vue_app'
import MedicalRecordsProtocolApp from '@/vue_apps/MedicalRecords/MedicalRecordsProtocol/MedicalRecordsProtocolApp.vue'

let vueApp = null
export const createMedicalRecordsProtocolApp = (el = '#vue_medical_records_protocol_root') => {
  if (!document.querySelector(el)) { return }
  if (vueApp?.$destroy) { vueApp.$destroy() }

  const entryId = gon.page.controller === 'entries'
    ? +gon.page.params.id
    : +gon.page.params.entry_id

  vueApp = createVueApp({
    el,
    name: 'MedicalRecordsShowAppRoot',
    render: (h) => h(MedicalRecordsProtocolApp, {
      props: {
        entryId,
        medicalRecord: gon.specific.medical_record_id,
      },
    }),
  })
}
