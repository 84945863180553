// extraChargeType
// extraChargeValue
// clinicIdentifier
// invitroApiToken

export const FIELDS = Object.freeze({
  // База
  TITLE: 'title',

  // Инвитра
  ENTRIES_EXTRA_CHARGE_TYPE: 'entriesExtraChargeType',
  ENTRIES_EXTRA_CHARGE_VALUE: 'entriesExtraChargeValue',
  CLINIC_IDENTIFIER: 'clinicIdentifier',
  CLINIC_ID: 'clinicId',
  ALLOW_SAME_ENTRIES: 'allowSameEntries',
  API_TOKEN: 'apiToken',
  WORKPLACE_ID: 'workplaceId',

  // Хеликс
  USERNAME: 'username',
  PASSWORD: 'password',
  CLIENT_ID: 'clientId',
  CLIENT_SECRET: 'clientSecret',
  BARCODE_TEMPLATE: 'barcodeTemplate',
  LABELLER_INTEGRATION: 'labellerIntegration',
  LABELLER_ID: 'labellerId',
  LABELLER_PASSWORD: 'labellerPassword',

  REQUEST_COST_PRICE: 'requestCostPrice',

  // KDL
  MIN_LABEL_NUMBER: 'minLabelNumber',
  MAX_LABEL_NUMBER: 'maxLabelNumber',
})

export const SPECIAL_CLINICS = Object.freeze({
  HELIX: 'helix',
  INVITRO: 'invitro',
  KDL: 'kdl',
  KDL_CHECKUPS: 'kdl_checkups',
})

export const createBaseLaboratory = () => ({
  [FIELDS.TITLE]: '',
})

const generateAuthAnalysisLaboratories = (clinics = [], data) =>
  clinics.reduce((acc, clinic) => {
    acc[clinic.id] = { ...data, clinicId: clinic.id }

    return acc
  }, {})

export const createInvitroLaboratory = (clinics) => {
  const authAnalysisLaboratories = generateAuthAnalysisLaboratories(clinics, {
    [FIELDS.API_TOKEN]: '',
    [FIELDS.REQUEST_COST_PRICE]: false,
    [FIELDS.WORKPLACE_ID]: null,
  })

  return {
    [FIELDS.TITLE]: '',
    [FIELDS.ENTRIES_EXTRA_CHARGE_TYPE]: '',
    [FIELDS.ENTRIES_EXTRA_CHARGE_VALUE]: '',
    [FIELDS.CLINIC_ID]: '',
    [FIELDS.API_TOKEN]: '',

    authAnalysisLaboratories,
  }
}

export const createHelixLaboratory = (clinics) => {
  const authAnalysisLaboratories = generateAuthAnalysisLaboratories(clinics, {
    [FIELDS.CLINIC_ID]: '',
    [FIELDS.CLINIC_IDENTIFIER]: '',
    [FIELDS.USERNAME]: '',
    [FIELDS.PASSWORD]: '',
    [FIELDS.CLIENT_ID]: '',
    [FIELDS.CLIENT_SECRET]: '',
    [FIELDS.BARCODE_TEMPLATE]: '',
    [FIELDS.LABELLER_INTEGRATION]: '',
    [FIELDS.LABELLER_ID]: '',
    [FIELDS.LABELLER_PASSWORD]: '',
    [FIELDS.REQUEST_COST_PRICE]: false,
  })

  return {
    [FIELDS.TITLE]: '',
    [FIELDS.ENTRIES_EXTRA_CHARGE_TYPE]: '',
    [FIELDS.ENTRIES_EXTRA_CHARGE_VALUE]: '',
    [FIELDS.ALLOW_SAME_ENTRIES]: '',

    authAnalysisLaboratories,
  }
}

export const createKdlLaboratory = (clinics) => {
  const authAnalysisLaboratories = generateAuthAnalysisLaboratories(clinics, {
    [FIELDS.CLINIC_ID]: null,
    [FIELDS.USERNAME]: null,
    [FIELDS.PASSWORD]: '',
    [FIELDS.MIN_LABEL_NUMBER]: null,
    [FIELDS.MAX_LABEL_NUMBER]: null,
    [FIELDS.REQUEST_COST_PRICE]: false,
  })

  return {
    [FIELDS.TITLE]: '',
    authAnalysisLaboratories,
  }
}

export const createLaboratory = (systemName = '', clinics) => {
  if (systemName === SPECIAL_CLINICS.INVITRO) {
    return createInvitroLaboratory(clinics)
  }

  if (systemName === SPECIAL_CLINICS.HELIX) {
    return createHelixLaboratory(clinics)
  }

  if (systemName === SPECIAL_CLINICS.KDL || systemName === SPECIAL_CLINICS.KDL_CHECKUPS) {
    return createKdlLaboratory(clinics)
  }

  return createBaseLaboratory()
}
