import entries from './entries'
import orders from './orders'
import payments from './payments'
import expenses from './expenses'
import cancellations from './cancellations'
import salary from './salary'
import insuranceCompanies from './insurance_companies'
import referrals from './referrals'

export default {
  entries,
  orders,
  payments,
  expenses,
  cancellations,
  salary,
  insuranceCompanies,
  referrals,
}
