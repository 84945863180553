import { tScoped } from '@/helpers/i18nHelpers'
import {
  ACQUIRING_OPERATION_TYPE_CODE,
} from '@/vue_apps/ReportsModule/AcquiringReport/consts/acquiringOperationTypeCode'
import {
  ACQUIRING_OPERATION_TYPE,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringOperationType'

const ts = tScoped('finance.operationTypes')

export const OPERATION_TYPE_FILTER_ITEMS = [
  {
    id: ACQUIRING_OPERATION_TYPE_CODE.PAY,
    title: ts(ACQUIRING_OPERATION_TYPE.PAY),
  },
  {
    id: ACQUIRING_OPERATION_TYPE_CODE.REFUND,
    title: ts(ACQUIRING_OPERATION_TYPE.REFUND),
  },
  {
    id: ACQUIRING_OPERATION_TYPE_CODE.CANCEL,
    title: ts(ACQUIRING_OPERATION_TYPE.CANCEL),
  },
]
