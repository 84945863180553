import { tScoped } from '@/helpers/i18nHelpers'
import { orNull } from '@/_declarations/orNull'

const ts = tScoped('enums.call.call_type')

const callTypes = [
  {
    icon: 'fad fa-phone-alt hidden-print',
    text: ts('incoming'),
  },
  {
    icon: 'fad fa-headset hidden-print',
    text: ts('outcoming'),
  },
]

export const getCallTypeItem = (callTypeId: orNull<number>) => {
  return callTypeId
    ? callTypes.at(callTypeId - 1)
    : ''
}
