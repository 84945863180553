import { ItemBase } from '@/_api/_classes/ItemBase'
import { NSISwitchableDictionaryModel } from '@/vue_apps/app_configuration/NSI/entities/NSISwitchableDictionaryModel'
import { NSIDictionariesInfo } from '@/vue_apps/app_configuration/NSI/entities/NSIDictionariesInfo'

export class NSIConfigurationModel extends ItemBase {
  nsiUserKey: string = ''

  switchableDictionariesInfo: NSISwitchableDictionaryModel[] = []

  dictionariesInfo: NSIDictionariesInfo = new NSIDictionariesInfo()
}
