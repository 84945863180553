<template>
  <m-si-generator
    class="medical-records-tabs-container__tab entries-tab"
    :items="list.data"
    :si-generator-schema="schema"
    :page-count="list.totalPages"
    :current-page="list.currentPage"
    :active-item-id="entryId"
    :total="list.total"
    use-context-menu
    @update:currentPage="list.fetchPage($event)"
    @onCellClick="onCellClick"
    @onItemEdit="onEditClick"
    @onItemDelete="onItemDelete"
    @onItemClick="onItemClick"
    @onItemContextMenuClick="lastContextClickedItem = $event"
  >
    <template #search>
      <entry-types-search-add
        class="flex-grow-1 mb-indent-small"
        :for-current-user="entryTypesForCurrenUser"
        for-current-clinic
        @onEntryTypeAdd="onEntryTypeAdd"
        @onEntryPackAdd="onEntryPackAdd"
      />

      <treatment-plans-entries-transfer
        v-if="canShowTreatmentPlansEntriesTransfer"
        :client-id="clientId"
        :tooth-ids="teethIds"
        @onSubmitComplete="list.fetchAll()"
      />

      <entry-types-transfer
        class="mr-5"
        :client-id="clientId"
        :tooth-ids="teethIds"
        @onSubmitComplete="list.fetchAll()"
      />
    </template>

    <template #filters>
      <entries-tab-filters
        :list="list"
        :is-dent-branch="isDentBranch"
      />
    </template>

    <template #contextMenu>
      <m-context-menu-new-tab
        v-if="lastContextClickedItem"
        :url="`${$routes.medical_record_path(medicalRecordId)}/${lastContextClickedItem.id}`"
      />
    </template>
  </m-si-generator>
</template>

<script>
import { MEntryPresenter } from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/api/MEntryPresenter'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import EntriesTabFilters from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/EntriesTabFilters.vue'
import { EntriesList } from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/api/EntriesList'
import { GLOBAL_DEBOUNCE_DEFAULT } from '@/_global_scripts/GLOBAL_CONSTS'
import { DEFAULT_CURRENT_PAGE } from '@/vue_components/store/modules/filters_base'
import EntryTypesSearchAdd from '@/vue_present/Reuse/EntryTypes/EntryTypesSearchAdd/EntryTypesSearchAdd.vue'
import TreatmentPlansEntriesTransfer
  from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/components/TreatmentPlansEntriesTransfer/TreatmentPlansEntriesTransfer.vue'
import EntryTypesTransfer
  from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/components/EntryTypesTransfer/EntryTypesTransfer.vue'
import {
  getTransferAvailability,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/components/TreatmentPlansEntriesTransfer/store/helpers'
import { getListSchema } from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/const/getListSchema'
import { EGISZ_CHANNEL } from '@/vue_components/store/modules/egisz/ws/egisz_ws'
import MContextMenuNewTab from '@/vue_present/_base/Tables/MContextMenuNewTab.vue'
import { orUndefined } from '@/_medods_standart_library/msl'
import { checkCanAddWithTeeth } from '@/vue_present/Reuse/EntryTypes/shared/checkCanAddWithTeeth'

export default {
  name: 'EntriesTab',

  components: {
    MContextMenuNewTab,
    EntryTypesTransfer,
    TreatmentPlansEntriesTransfer,
    EntryTypesSearchAdd,
    EntriesTabFilters,
    MSiGenerator,
  },

  props: {
    clientId: { type: Number, required: true },
    medicalRecordId: { type: Number, required: true },
    entryId: { type: Number, default: null },
    isMedBranch: Boolean,
    isDentBranch: Boolean,
  },

  data () {
    return {
      list: new EntriesList({ clientId: this.clientId }),
      schema: getListSchema(this.isDentBranch),
      lastContextClickedItem: null,
    }
  },

  computed: {
    canShowTreatmentPlansEntriesTransfer () {
      return !getTransferAvailability().isForbidden
    },

    entryTypesForCurrenUser () {
      return gon.application.filter_entry_types_by_current_user
    },

    useTeethWhenCreatingEntry () {
      return gon.specific.useTeethWhenCreatingEntry
    },

    teethIds () {
      return (this.useTeethWhenCreatingEntry && this.list.filtersData.teethIds) || undefined
    },
  },

  created () {
    if (gon.application.show_entries_final_sum) {
      this.schema.tableSchema.addFooter()
    }

    const page = +(new URL(location).searchParams.get('page')) || DEFAULT_CURRENT_PAGE
    setTimeout(() => {
      this.list.fetchPage(page)
    }, GLOBAL_DEBOUNCE_DEFAULT)

    this.subscribeToWS()
  },

  beforeDestroy () {
    this.$pubSub.reset('updateEntriesList_onWsEntryActions')
  },

  methods: {
    /**
     * @param {{ id: number, title: string }} entryType
     * @returns {Promise<void>}
     */
    async onEntryTypeAdd (entryType) {
      const presenter = new MEntryPresenter()

      /** @type {boolean} */
      const checkDuplicatesResponse = await presenter.checkDuplicates(entryType.id, this.clientId)
      if (checkDuplicatesResponse.errors) { return }

      if (checkDuplicatesResponse) {
        const { cancel } = await MConfirm.warning(t('entry_duplicated'))
        if (cancel) { return }
      }

      const entry = await presenter.create({
        entryTypeId: entryType.id,
        clientId: this.clientId,
        toothIds: orUndefined(checkCanAddWithTeeth(entryType)) && this.teethIds,
      })

      if (entry.errors) { return }

      this.list.fetchPage(1).then()
    },

    /**
     * @param {string} cell
     * @param {EntriesListItem} item
     */
    onCellClick ({ cell, item }) {
      if (cell === 'stateIcon') { this.list.changeState(item) }
    },

    /** @param {EntriesListItem} item */
    onEditClick (item) {
      location.href = this.$routes.edit_entry_path(item.id)
    },

    /** @param {EntriesListItem} item */
    async onItemDelete (item) {
      await this.list.delete(item.id)
      await this.list.fetchAll()
    },

    onItemClick (item) {
      // пример: /medical_records/110086/128969?page=2
      Turbolinks.visit(`${this.$routes.medical_record_path(this.medicalRecordId)}/${item.id}?page=${this.list.currentPage}`)
    },

    subscribeToWS () {
      this.$store.dispatch('egisz/egiszWs/vxEgiszWSConnect', EGISZ_CHANNEL)
      this.$pubSub.subscribe('updateEntriesList_onWsEntryActions', () => {
        this.list.fetchAll()
      })
    },

    /**
     * @param {EntryPackEntryType[]} entryTypes
     * @returns {Promise<*>}
     */
    async onEntryPackAdd (entryTypes = []) {
      const response = await new MEntryPresenter().createByEntriesParams({
        clientId: this.clientId,
        entriesParams: entryTypes.map((entryType) => ({
          entryTypeId: entryType.id,
          amount: entryType.amount,
          toothIds: orUndefined(entryType.canAddWithTeeth()) && this.teethIds,
        })),
      })

      if (response.errors) {
        return this.__onEntryPackAddError(entryTypes, response.failedEntriesErrors)
      }

      this.list.fetchAll().then()
    },

    __onEntryPackAddError (entryTypes = [], failedEntriesErrors = []) {
      if (!this.entryTypesForCurrenUser) { return }

      if (failedEntriesErrors.length === entryTypes.length) {
        return Utils.reportError('__onEntryPackAddError', t('failedEntriesErrorsByUser'))()
      }

      return Utils.reportError('__onEntryPackAddError', t('failedSomeEntriesErrorsByUser'))()
    },
  },
}
</script>
