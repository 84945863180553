<template>
  <el-tabs
    :value="activeTab"
    stretch
    type="card"
    class="medical-records-tabs-container"
    @tab-click="onTabClick"
  >
    <el-tab-pane
      label="История"
      name="entries"
      lazy
    >
      <entries-tab
        :client-id="clientId"
        :medical-record-id="medicalRecordId"
        :entry-id="entryId"
        :is-dent-branch="isDentBranch"
        :is-med-branch="isMedBranch"
      />
    </el-tab-pane>

    <el-tab-pane
      v-if="canViewEgisz"
      label="ЕГИСЗ"
      name="egisz"
      lazy
    >
      <egisz-tab
        :medical-record-id="medicalRecordId"
        :client-id="clientId"
      />
    </el-tab-pane>

    <el-tab-pane
      name="documents-protocols"
      :label="t('utilsReport.entities.protocols')"
      lazy
      :disabled="!canManageProtocols"
    >
      <template #label>
        <span v-tooltip="!canManageProtocols && t('insufficient_access_rights')">
          {{ t('utilsReport.entities.protocols') }}
        </span>
      </template>
      <protocols-tab
        v-if="canManageProtocols"
        :client-id="clientId"
        :medical-record-id="medicalRecordId"
      />
    </el-tab-pane>

    <el-tab-pane
      name="documents-default"
      :label="t('documents')"
      lazy
    >
      <documents-tab
        :client-id="clientId"
        :medical-record-id="medicalRecordId"
      />
    </el-tab-pane>

    <el-tab-pane
      :label="t('files')"
      name="files"
      lazy
    >
      <template #label>
        <span>{{ t('files') }}</span>
        <m-tag
          v-show="filesNumber"
          disable-transitions
          size="mini"
          type="warning"
          effect="dark"
        >
          {{ filesNumber }}
        </m-tag>
      </template>

      <attachments-tab
        :client-id="clientId"
        @updateFilesCount="fetchCount"
      />
    </el-tab-pane>

    <el-tab-pane
      v-if="canViewTreatmentPlan"
      label="Планы лечения"
      name="treatment_plans"
      lazy
    >
      <treatment-plans-list-emk-app
        :client-id="clientId"
        :is-dent-branch="isDentBranch"
        :is-med-branch="isMedBranch"
      />
    </el-tab-pane>

    <el-tab-pane
      label="Заказы в лабораторию"
      name="analysis_orders"
      lazy
    >
      <analysis-orders-tab
        v-if="analysisOrdersAccess.view && analysisOrdersAccess.enabled"
        :client-id="clientId"
      />
      <introduction
        v-else
        :introduction-text="t('introduction.laboratories')"
        icon="fad fa-flask"
        :disabled="!analysisOrdersAccess.enabled"
        :hide-button="analysisOrdersAccess.enabled"
        :can-view="analysisOrdersAccess.view"
      />
    </el-tab-pane>

    <el-tab-pane
      label="Заказ-наряды"
      name="dental_orders"
      lazy
    >
      <dental-orders-tab
        v-if="dentalOrdersAccess.view && dentalOrdersAccess.enabled"
        :client-id="clientId"
      />
      <introduction
        v-else
        :introduction-text="t('introduction.dental_labs')"
        icon="fad fa-tooth"
        :disabled="!dentalOrdersAccess.enabled"
        :hide-button="dentalOrdersAccess.enabled"
        :can-view="dentalOrdersAccess.view"
      />
    </el-tab-pane>
  </el-tabs>
</template>

<script lang="ts">
import TreatmentPlansListEmkApp from '@/vue_apps/TreatmentPlans/TreatmentPlansList/TreatmentPlansListEmkApp.vue'
import EntriesTab from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/views/EntriesTab.vue'
import AttachmentsTab from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/views/AttachmentsTab.vue'
import {
  MClientAttachmentsPresenter,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AttachmentsTab/api/MClientAttachmentsPresenter'
import { GLOBAL_DOUBLE_DEBOUNCE_DEFAULT } from '@/_global_scripts/GLOBAL_CONSTS'
import MTag from '@/vue_present/_base/MTag/MTag.vue'
import DentalOrdersTab from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/views/DentalOrdersTab.vue'
import AnalysisOrdersTab from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/views/AnalysisOrdersTab.vue'
import Introduction from '@/vue_components/introduction/introduction.vue'
import EgiszTab from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/views/EgiszTab.vue'
import {
  AttachmentTabHyperlinkApi,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AttachmentsTab/classes/AttachmentTabHyperlinkApi'
import DocumentsTab from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/views/DocumentsTab.vue'
import { MLocalStorage } from '@/_api/_storage/MLocalStorage'
import ProtocolsTab from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/views/ProtocolsTab.vue'

export default {
  name: 'MedicalRecordsTabsApp',

  components: {
    ProtocolsTab,
    DocumentsTab,
    EgiszTab,
    Introduction,
    AnalysisOrdersTab,
    DentalOrdersTab,
    MTag,
    AttachmentsTab,
    EntriesTab,
    TreatmentPlansListEmkApp,
  },

  props: {
    defaultTab: { type: String, default: 'entries' },
    clientId: { type: Number, required: true },
    medicalRecordId: { type: Number, required: true },
    entryId: { type: Number, default: null },
    isMedBranch: Boolean,
    isDentBranch: Boolean,
  },

  data () {
    return {
      activeTab: this.defaultTab,
      filesNumber: 0,
      documentTabCache: new MLocalStorage('documentTabCache'),
    }
  },

  computed: {
    canViewEgisz () {
      return gon.application.egisz_module_enabled &&
          this.$security.canViewEgisz
    },

    canViewTreatmentPlan () {
      return this.$security.canViewTreatmentPlan
    },

    canManageProtocols () {
      return this.$security.canManageEntryTemplate
    },

    dentalOrdersAccess () {
      return {
        enabled: gon.application.dentalLaboratoriesEnabled,
        view: this.$security.canViewDentalOrder,
        manage: this.$security.canManageDentalOrder,
      }
    },

    analysisOrdersAccess () {
      return {
        enabled: gon.application.laboratoriesEnabled,
        view: this.$security.canViewAnalysisOrder,
        manage: this.$security.canManageAnalysisOrder,
      }
    },
  },

  created () {
    this.$pubSub.subscribe('updateFilesList', () => this.fetchFilesCount())
    this.fetchCount()
  },

  methods: {
    fetchCount () {
      setTimeout(async () => {
        this.filesNumber = await this.fetchFilesCount() + await this.fetchHyperLinksCount()
      }, GLOBAL_DOUBLE_DEBOUNCE_DEFAULT)
    },

    async fetchFilesCount () {
      const data = await new MClientAttachmentsPresenter()
        .count(this.clientId)

      return typeof data === 'string' ? +data : 0
    },

    async fetchHyperLinksCount () {
      const data = await new AttachmentTabHyperlinkApi()
        .fetchCount(this.clientId)

      return 'count' in data ? data.count : 0
    },

    onTabClick (tab) {
      if (this.activeTab === tab.name) { return }
      this.activeTab = tab.name
      window.location.hash = `#${tab.name}`
    },
  },
}
</script>
