<template>
  <si v-if="!isHidden">
    <div class="report-subtitle cut">
      {{ subtitle }}
    </div>
    <si-table :spinner="isLoading">
      <template #header>
        <si-field>
          <si-column
            :name="header.title.sortable"
            :active="sortableColumn"
            @sort-change="onSortChange"
          >
            {{ header.title.text }}
          </si-column>
          <si-column
            :name="header.measureUnitShortTitle.sortable"
            :active="sortableColumn"
            class="measureUnitShortTitle"
            @sort-change="onSortChange"
          >
            {{ header.measureUnitShortTitle.text }}
          </si-column>
          <si-column
            :name="header.amount.sortable"
            :active="sortableColumn"
            class="amount"
            @sort-change="onSortChange"
          >
            {{ header.amount.text }}
          </si-column>
          <si-column
            :name="header.sum.sortable"
            :active="sortableColumn"
            class="sum cut"
            @sort-change="onSortChange"
          >
            {{ header.sum.text }}
          </si-column>
          <si-column
            :name="header.cost.sortable"
            :active="sortableColumn"
            class="cost cut"
            @sort-change="onSortChange"
          >
            {{ header.cost.text }}
          </si-column>
        </si-field>
      </template>
      <template
        v-if="rows.length"
        #body
      >
        <si-field
          v-for="(row, rowIndex) in rows"
          :key="rowIndex"
        >
          <div>
            {{ row.entryTypeTitle }}
          </div>
          <div class="measureUnitShortTitle">
            {{ row.entryMeasureUnitShortTitle }}
          </div>
          <div class="amount">
            {{ row.entryTotalAmount }}
          </div>
          <div class="sum cut">
            {{ $filters.price(row.entryTotalFinalSum) }}
          </div>
          <div class="cost cut">
            {{ $filters.price(row.entryTotalCostSum) }}
          </div>
        </si-field>
      </template>
      <template #footer>
        <si-field
          v-if="isDirty"
          class="total"
        >
          <div>
            {{ footer.total }}
          </div>
          <div class="measureUnitShortTitle" />
          <div
            :data-tooltip="footer.amount"
            class="amount"
          >
            {{ footer.amount }}
          </div>
          <div
            :data-tooltip="$filters.currency(footer.finalSum)"
            class="sum cut"
          >
            {{ $filters.currency(footer.finalSum) }}
          </div>
          <div
            :data-tooltip="$filters.currency(footer.costSum)"
            class="cost cut"
          >
            {{ $filters.currency(footer.costSum) }}
          </div>
        </si-field>
      </template>
    </si-table>
    <si-footer v-if="rows.length">
      <pagination
        :page-count="pageCount"
        layout="prev, pager, next"
        class="hidden-print"
        @current-change="loadData"
      />
    </si-footer>
  </si>
</template>

<script>
import Pagination from '@/vue_components/common/pagination.vue'
import Si from '@/vue_components/sort_items/si.vue'
import SiFooter from '@/vue_components/sort_items/si_footer.vue'
import SiTable from '@/vue_components/sort_items/si_table/si_table.vue'
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'
import SiColumn from '@/vue_components/sort_items/si_table/si_column.vue'
import Report from '@/vue_components/mixins/report.js'
import SortableReport from '@/vue_components/mixins/sortableReport.js'
import ReportFiltersFactory from '@/specific/reports/ReportFiltersFactory'

export default {
  name: 'Cancellations',

  components: {
    Pagination,
    Si,
    SiTable,
    SiField,
    SiFooter,
    SiColumn,
  },

  mixins: [Report, SortableReport],

  props: {
    clinic: { type: Object, required: true },
    period: { type: String, default: '' },
    store: { type: Object, required: true },
    entryConsumable: { type: Object, required: true },
  },

  data () {
    return {
      header: {
        title: {
          text: t('title'),
          sortable: 'entryTypeTitle',
        },
        amount: {
          text: t('amount'),
          sortable: 'entryTotalAmount',
        },
        sum: {
          text: t('sum'),
          sortable: 'entryTotalFinalSum',
        },
        cost: {
          text: t('cost_price'),
          sortable: 'entryTotalCostSum',
        },
        measureUnitShortTitle: {
          text: t('measureUnitShort'),
          sortable: 'entryMeasureUnitShortTitle',
        },
      },
      footer: {
        total: t('total'),
      },
    }
  },

  computed: {
    subtitleParts () {
      return [
        this.period,
        this.clinic.name,
        this.store.name,
        this.entryConsumable.name,
      ]
    },
  },

  created () {
    this.reportClient.init({
      url: Routes.report_cancellations_path(),
    })
    this.requestBuilder.addStep(() => ReportFiltersFactory.defineFor('cancellations', this))
  },

  methods: {
    fillTable (result) {
      this.rows = result.data.map(({ material }) => material)
      this.footer = {
        total: this.footer.total,
        ...result.total,
      }
    },
  },
}
</script>
