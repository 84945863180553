<template>
  <reusable-documents-tree-modal
    use-fixed-document-types
    show-notice
    @documentItemClick="onDocumentCreate"
  >
    <template #default="{ openModal }">
      <m-button
        type="dark"
        button-style="fill"
        :text="t('print_documents')"
        icon="print"
        size="mini"
        @click="openModal()"
      />
    </template>
  </reusable-documents-tree-modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import ReusableDocumentsTreeModal from '@/vue_present/Reuse/DocumentTree/ReusableDocumentsTreeModal.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import {
  IReusableDocumentTreeDocumentItem,
} from '@/vue_present/Reuse/DocumentTree/interfaces/IReusableDocumentTreeDocumentItem'
import {
  REUSABLE_DOCUMENTS_TREE_DOCUMENT_TYPES,
} from '@/vue_present/Reuse/DocumentTree/store/reusableDocumentsTreeDocumentTypes'
import { MDocumentPresenter } from '@/_api/MDocumentPresenter/MDocumentPresenter'
import { DOCUMENT_OWNER_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentOwnerTypes'
import { DOCUMENT_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentTypes'
import { IClient } from '@/_declarations/IClient'

export default defineComponent({
  name: 'MedicalRecordsPrintDocuments',

  components: { MButton, ReusableDocumentsTreeModal },

  props: {
    client: { type: Object as PropType<IClient>, required: true },
  },

  methods: {
    onDocumentCreate (documentItem: IReusableDocumentTreeDocumentItem) {
      documentItem.documentType === REUSABLE_DOCUMENTS_TREE_DOCUMENT_TYPES.DOCUMENT
        ? this.whenDocumentType(documentItem.id)
        : this.whenFixedDocument(documentItem)
    },

    async whenDocumentType (id) {
      const result = await new MDocumentPresenter().createDocument({
        clientId: this.client.id,
        documentTypeId: id,
        documentsOwnersAttributes: [{
          ownerType: DOCUMENT_OWNER_TYPES.CLIENT,
          ownerId: this.client.id,
          documentType: DOCUMENT_TYPES.OLD_DOCUMENT,
        }],
      })

      if (result?.errors) { return }

      Turbolinks.visit(this.$routes.document_path(result.id))
    },

    async whenFixedDocument (documentItem: IReusableDocumentTreeDocumentItem) {
      if (documentItem.id === 'certificate_payments') {
        return Turbolinks.visit(`/fixed-document/tax-certificate-2024/new?clientId=${this.client.id}`)
      }

      if (documentItem.id === 'medical_card_form_088_y') {
        return Turbolinks.visit(Routes.semd_documents_path('semd121/new', { clientId: this.client.id }))
      }

      const params = new URLSearchParams()
      params.set('fixed_doc_type', 'true')
      params.set('client_id', String(this.client.id))
      params.set('path', documentItem.id)
      params.set('title', documentItem.title)

      Turbolinks.visit(`${this.$routes.new_document_path()}?${params.toString()}`)
    },
  },
})
</script>
