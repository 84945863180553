import { NSISwitchableDictionaryModel } from '@/vue_apps/app_configuration/NSI/entities/NSISwitchableDictionaryModel'

export const adapters = {
  toServer ({ nsiUserKey, switchableDictionariesInfo }) {
    const nsiDictionaries = (switchableDictionariesInfo as NSISwitchableDictionaryModel[])
      .map((dict) => ({ id: dict.id, enabled: dict.enabled }))
      .reduce((acc, dict) => {
        const id = dict.id.startsWith('nsi') ? dict.id : `nsi_${dict.id}`

        acc[id] = dict.enabled

        return acc
      }, {})

    return {

      appConfiguration: {
        nsiUserKey,
        ...nsiDictionaries,
      },
    }
  },
}
